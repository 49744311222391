import React from "react";
import Button from '@mui/material/Button';

const CMButton = (props) => {

    return (
        <Button style={props.style} {...props}>{props.label}</Button>
    );
};


export default CMButton;
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
// Mui
import { Toolbar, Divider, List, ListItem, ListItemButton, ListItemText, Collapse } from '@mui/material';
// Constants
import drawerItems from "../../constants/DrawerItems";
import { UserContext } from '../../context/UserContext';
import { BoltRounded, ExpandLess, ExpandMore } from '@mui/icons-material';
import jwtDecode from "jwt-decode";




const CMListNav = () => {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const [openMenus, setOpenMenus] = useState({});

  // Verificar si no existe el usuario o no tiene datos
  if (!user || !user.modulos) {
    // Redireccionar a la página de inicio de sesión
    history.push('/login');
    history.go();
  }
  if (!user || !user.token) {
    // Redireccionar a la página de inicio de sesión
    history.push('/login');
    history.go();
  }

  useEffect(() => {
    // Función para validar el token
    function validateToken() {
      if (user.token) {
        const decodedToken = jwtDecode(user.token);
        const { exp } = decodedToken;
        if (Date.now() >= exp * 1000) {
          console.log("El token ha expirado. Volver a iniciar sesión.");
          // Aquí puedes redirigir al usuario a la página de inicio de sesión o realizar otras acciones necesarias
          history.push('/login');
          history.go();
        } else {
          console.log("El token es válido. El usuario está autenticado.");
          // El token es válido y el usuario está autenticado
        }
      }
    }

    // Validar el token al montar el componente
    validateToken();

    // Ejecutar la validación cada 60 segundos (60,000 milisegundos)
    const intervalId = setInterval(validateToken, 60000);

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
  }, [user.token]); // Agregar user.token como una dependencia para que el efecto se ejecute cada vez que user.token cambie

  const handleSubItemClick = (subItem) => {
    history.push("/" + subItem.uri);
    history.go(0);
  };

  return (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {user.modulos.map((item, index) => (!item.roles || (user.id_rol && item.roles.includes(user.id_rol))) && (
          <div key={index}>
            {item.submodulos ? (
              <div>
                <ListItemButton onClick={() => setOpenMenus(prevState => ({ ...prevState, [index]: !prevState[index] }))}>
                  <ListItemText primary={item.nombre_modulo} primaryTypographyProps={{ color: 'primary', fontWeight: 'bold' }} />
                  {openMenus[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openMenus[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.submodulos.map((subItem, subIndex) => {
                      const showSubItem = !subItem.roles || (user.id_rol && subItem.roles.includes(user.id_rol));
                      return showSubItem ? (
                        <ListItemButton key={subIndex} sx={{ pl: 4 }} onClick={() => handleSubItemClick(subItem)}>
                          <ListItemText primary={subItem.nombre_submodulo} primaryTypographyProps={{ color: 'black' }} />
                        </ListItemButton>
                      ) : null;
                    })}
                  </List>
                </Collapse>
              </div>
            ) : (
              <ListItemButton onClick={() => { history.push(item.path); history.go(0); }}>
                <ListItemText primary={item.label} primaryTypographyProps={{ color: item.bold ? 'primary' : undefined, fontWeight: item.bold ? 'bold' : undefined }} />
              </ListItemButton>
            )}
          </div>
        ))}
      </List>
      <List style={{  bottom: "0" }}>
        <ListItem>
          <ListItemText primary={"© 2024 Maria Almenara"} />
        </ListItem>
      </List>
    </div>
  );
};

export default CMListNav;
import React from 'react'
import moment from 'moment';


import { Grid ,Typography} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
  } from '@mui/x-data-grid';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as XLSX from 'xlsx';
//Components
import CMLabel from '../../components/atoms/CMLabel';
import CMDatePicker from '../../components/molecules/CMDatePicker';
import CMButton from '../../components/atoms/CMButton';
import CMIconButton from '../../components/atoms/CMIconButton';

//Constants
import {MomentLocaleEs} from '../../constants/MomentLocaleEs';
//Services
import * as NotaEntregaService from '../../services/NotaEntregaService';

import { FormControl, InputLabel, MenuItem, Select, Button } from '@mui/material';
import CMTextField from '../../components/atoms/CMTextField';

const HistorialNotaEntregaController = () => {
    const [rowsdata, setRowsdata] = useState([]);
    const [rowsexcel, setRowsexcel] = useState([]);
    const [fechaInicio, setFechaInicio] = useState(moment().locale('es', MomentLocaleEs).subtract(10, 'days'));
    const [fechaFin, setFechaFin] = useState(moment().locale('es', MomentLocaleEs));
    const user = JSON.parse(window.localStorage.getItem("user"));
    const history = useHistory();
    const [rowsestado, setRowsestado] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedState, setSelectedState] = useState(0);  
    const [btndisabled, setBtndisabled] = useState(true);
    const [filtro,setFiltro] = useState('');
    const handleFiltro = (event) => {
      setFiltro(event.target.value);
    }
    const handleChange = (event) => {
      setSelectedState(event.target.value);
    };
    useEffect(() => {
        const dataList={
          "id_usuario": user.id_usuario,
          "from_date": fechaInicio,
          "to_date": fechaFin,
          "id_oc_estado":0,
          "filters":''
        }
        NotaEntregaService.getListHistorialNotaEntregaController(dataList,setLoading,setRowsdata,user.token);
        NotaEntregaService.getListHistorialNotaEntregaControllerExcel(dataList,setBtndisabled,setRowsexcel,user.token);
        NotaEntregaService.getNEListEstado(setRowsestado,user.token);

    }, []);
    const updateOrdenes= () =>{
        const dataList={
            "id_usuario": user.id_usuario,
            "from_date": fechaInicio,
            "to_date": fechaFin,
            "id_oc_estado":selectedState,
            "filters":filtro

        }
        setLoading(true);
        setBtndisabled(true);
        NotaEntregaService.getListHistorialNotaEntregaController(dataList,setLoading,setRowsdata,user.token);
        NotaEntregaService.getListHistorialNotaEntregaControllerExcel(dataList,setBtndisabled,setRowsexcel,user.token);
    }

    const formatCurrency = (value, moneda) => {
      let currency = 'PEN';
    
      if (moneda === 'S/') {
          currency = 'PEN';
        }else  if (moneda === 'US$') {
        currency = 'USD';
      }
    
      return value.toLocaleString('es-PE', {
        style: 'currency',
        currency: currency,
      });
    };

    const exportToExcel = () => {
      const headers = [
        'ID OC',
        'ID NE',
        'NE SAP',
        'Guia de Remisión',
        'RUC',
        'Proveedor',
        'Nombre Solicitante',
        'Tipo',
        'Observaciones',
        'Motivo Solicitud',
        'Moneda',
        'Impuesto',
        'Sub Total',
        'importe total',
        'Estado',
        'Nombre Estado',
        'Destino',
        'Condición Pago',
        'Aprobador de Area',
        'Aprobador de Finanzas',
        'Fecha Creación',
        'Fecha Documento',
        'Fecha Contable',
        'Fecha Entrega',
        'Link Nota Entrega',
        'Link Orden',
        'Link Factura PDF',
        'Link Factura XML',
        'Link Factura CDR',
        'Link Factura GUIA'
      ];
  
      const data = [
        headers,
        ...rowsexcel.map(item => [
            item.id_oc_cabecera,
            item.id_ne_cabecera,
            item.numero_documento,
            item.tipo_documento === null ? '' : `${item.tipo_documento} - ${item.serie_documento} - ${item.correlativo_documento}`,
            item.ruc_proveedor,
            item.nombre_proveedor,
            item.nombre_solicitante,
            item.tipo,
            item.observaciones,
            item.motivo_solicitud,
            item.moneda,
            item.impuesto,
            item.total_antes_descuento,
            item.total_pago_vencido,
            item.estado,
            item.ultimo_nombre_oc_estado,
            item.destino,
            item.condic_pago,
            item.nombre_aprobador_gerencia,
            item.nombre_aprobador_finanzas,
            formatDate(item.created_at),
            formatDate(item.fecha_documento),
            formatDate(item.fecha_contable),
            formatDate(item.fecha_entrega),
            item.nota_entrega_pdf,
            item.orden_pdf,
            item.factura_pdf,
            item.factura_xml,
            item.factura_cdr,
            item.facturaGuia
        ])
    ];
 
   
      // const exportToExcel = () => {
      //   const headers = [
      //     'ID',
      //     'ID OC',
      //     'ID NE',
      //     'RUC',
      //     'Proveedor',
      //     'Nombre Solicitante',
      //     'Tipo',
      //     'Observaciones',
      //     'Estado',
      //     'Nombre Estado',
      //     'Destino',
      //     'Condición Pago',
      //     'Aprobador de Area',
      //     'Aprobador de Finanzas',
      //     'Fecha Creación',
      //     'Fecha Documento',
      //     'Fecha Contable',
      //     'Fecha Entrega',
      //     'Moneda',
      //     'Impuesto',
      //     'Sub Total',
      //     'importe total',
      //     'ID OC Detalle',
      //     'Link Nota Entrega',
      //     'Fecha Entrega Detalle',
      //     'Descripción',
      //     'Código Artículo',
      //     'Cantidad Pedida',
      //     'Cantidad Entregada',
      //     'Cantidad Recibida',
      //     'Precio Unitario',
      //     'Porcentaje Descuento',
      //     'Total Artículo',
      //     'Unidad Medida'
      //   ];
    
      //   const data = [
      //     headers,
      //     ...rowsexcel.flatMap(item =>
      //       item.oc_detalles.map(detalle => [
      //         item.id,
      //         item.id_oc_cabecera,
      //         item.id_ne_cabecera,
      //         item.ruc_proveedor,
      //         item.nombre_proveedor,
      //         item.nombre_solicitante,
      //         item.tipo,
      //         item.observaciones,
      //         item.estado,
      //         item.ultimo_nombre_oc_estado,
      //         item.destino,
      //         item.condic_pago,
      //         item.nombre_aprobador_gerencia,
      //         item.nombre_aprobador_finanzas,
      //         formatDate(item.fecha_registro),
      //         formatDate(item.fecha_documento),
      //         formatDate(item.fecha_contable),
      //         formatDate(item.fecha_entrega),
      //         item.moneda,
      //         item.impuesto,
      //         item.total_antes_descuento,
      //         item.total_pago_vencido,
      //         detalle.id_oc_detalle,
      //         item.nota_entrega_pdf,
      //         formatDate(detalle.fecha_entrega),
      //         detalle.descripcion,
      //         detalle.codigo_articulo,
      //         detalle.cantidad_pedida,
      //         detalle.cantidad_entregada,
      //         detalle.cantidad_recibida,
      //         detalle.precio_unitario,
      //         detalle.porcentaje_descuento,
      //         detalle.total_articulo,
      //         detalle.unidad_medida
      //       ])
      //     )
      //   ];
    
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'NotasEntrega');
    
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, 'NotasEntregaController.xlsx');
      };
      const formatDate = (dateString, moneda) => {
        const date = new Date(dateString);
      
        let symbol = '';
      
        if (moneda === 'S/') {
          symbol = 'S/';
        } else if (moneda === 'US$') {
          symbol = 'US$';
        }
      
        return symbol + date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
      };
    
      const saveAsExcelFile = (buffer, fileName) => {
        const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(data, fileName);
        } else {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
        }
      };
    const columns = [
      {
        field: "ver", headerName: "Ver", width:50, justifyContent: "center",
        renderCell: (cellValues) => {
          return (
            <CMIconButton onClick={() => {history.push(`/historialcontrollerne/${cellValues.row.id_ne_cabecera}/${cellValues.row.numero_documento_orden}`); history.go(0);}}>
              <VisibilityIcon/>
            </CMIconButton>
          )
        }
      },
      { field: 'id_oc_cabecera', headerName: 'ID OC', width: 80 },
      { field: 'id_ne_cabecera', headerName: 'ID NE', width: 80 },
      { field: 'numero_documento', headerName: 'NE SAP', width: 180 },
      {
        field: 'concatenatedColumn',
        headerName: 'Guía de Remisión',
        width: 300,
        valueGetter: (params) => {
          if (params.row.tipo_documento === null) {
            return '';
          } else {
            return `${params.row.tipo_documento} - ${params.row.serie_documento} - ${params.row.correlativo_documento}`;
          }
        }
      },
      { field: 'ruc_proveedor', headerName: 'RUC', width: 120 },
        { field: 'nombre_proveedor', headerName: 'Nombre Proveedor', width: 300 },
        { field: 'nombre_solicitante', headerName: 'Nombre Solicitante', type: 'text', width: 150, editable: false },
        { field: 'tipo', headerName: 'Tipo', type: 'text', width: 150 },
        { field: 'observaciones', headerName: 'Observaciones', type: 'text', width: 700, editable: false },
        { field: 'motivo_solicitud', headerName: 'Motivo Solicitud', type: 'text', width: 400, editable: false },
        { field: 'moneda', headerName: 'Moneda', width: 100 },
        { field: 'impuesto', headerName: 'Impuesto', type: 'number', width: 160, editable: false,  valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.impuesto,params.row.moneda) },
        { field: 'total_antes_descuento', headerName: 'Sub Total', type: 'number', width: 160, editable: false,  valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.total_antes_descuento,params.row.moneda) },
        { field: 'total_pago_vencido', headerName: 'importe total', type: 'number', width: 160, editable: false,  valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.total_pago_vencido,params.row.moneda)  },
        { field: 'estado', headerName: 'Estado', type: 'text', width: 120, editable: false },
        { field: 'ultimo_nombre_oc_estado', headerName: 'Nombre Estado', type: 'text', width: 290, editable: false },
        { field: 'destino', headerName: 'Destino', type: 'text', width: 350, editable: false },
        { field: 'condic_pago', headerName: 'Condición Pago', type: 'text', width: 150, editable: false },
        { field: 'nombre_aprobador_gerencia', headerName: 'Aprobador Area', type: 'text', width: 150, editable: false },
        { field: 'nombre_aprobador_finanzas', headerName: 'Aprobador de Finanzas', type: 'text', width: 150, editable: false },

        { field: 'fecha_registro', headerName: 'Fecha Creación', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          }, },
        { field: 'fecha_documento', headerName: 'Fecha Documento', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          }, },
        { field: 'fecha_contable', headerName: 'Fecha Contable', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          },},
        { field: 'fecha_entrega', headerName: 'Fecha Entrega', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          },},
         {
         field: 'orden_pdf',
         headerName: 'Descargar OC',
         width: 150,
        renderCell: (params) => {
          const pdfUrl = params.value;
           return (
             <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
              <Icon>
                  <PictureAsPdf />
               </Icon>
               </a>
              );
            },
          },
          {
            field: 'nota_entrega_pdf',
            headerName: 'Descargar NE',
            width: 150,
           renderCell: (params) => {
             const pdfUrl = params.value;
              return (
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                 <Icon>
                     <PictureAsPdf />
                  </Icon>
                  </a>
                 );
               },
             },
           {
            field: 'factura_pdf',
            headerName: 'Descargar PDF',
            width: 150,
            renderCell: (params) => {
              const pdfUrl = params.value;
              return (
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                 <Icon>
                    <PictureAsPdf />
                </Icon>
                </a>
              );
            },
          },
          {
            field: 'factura_xml',
            headerName: 'Descargar XML',
            width: 150,
            renderCell: (params) => {
              const pdfUrl = params.value;
              return (
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                 <Icon>
                    <PictureAsPdf />
                </Icon>
                </a>
              );
            },
          },
          {
            field: 'factura_cdr',
            headerName: 'Descargar CDR',
            width: 150,
            renderCell: (params) => {
              const pdfUrl = params.value;
              return (
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                 <Icon>
                    <PictureAsPdf />
                </Icon>
                </a>
              );
            },
          },
          {
            field: 'facturaGuia',
            headerName: 'Descargar GUIA',
            width: 150,
            renderCell: (params) => {
              const pdfUrl = params.value;
              return (
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                 <Icon>
                    <PictureAsPdf />
                </Icon>
                </a>
              );
            },
          },
          {
            field: 'credito_pdf',
              headerName: 'N Crédito PDF',
             width: 150,
              renderCell: (params) => {
                const pdfUrl = params.value;
                return (
                  <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                   <Icon>
                      <PictureAsPdf />
                  </Icon>
                  </a>
                );
              },
            },
            {
              field: 'credito_xml',
                headerName: 'N Crédito XML',
               width: 150,
                renderCell: (params) => {
                  const pdfUrl = params.value;
                  return (
                    <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                     <Icon>
                        <PictureAsPdf />
                    </Icon>
                    </a>
                  );
                },
              },
              {
                field: 'debito_pdf',
                  headerName: 'N Débito PDF',
                 width: 150,
                  renderCell: (params) => {
                    const pdfUrl = params.value;
                    return (
                      <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                       <Icon>
                          <PictureAsPdf />
                      </Icon>
                      </a>
                    );
                  },
                },
                {
                  field: 'debito_xml',
                    headerName: 'N Débito XML',
                   width: 150,
                    renderCell: (params) => {
                      const pdfUrl = params.value;
                      return (
                        <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                         <Icon>
                            <PictureAsPdf />
                        </Icon>
                        </a>
                      );
                    },
                  },
      ];
 return (
    <Grid width={'100%'} m="auto">
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2} sx={{py:1}}>
        <Grid container item xs={12} sm={12} >
                <Grid item sm={12} md={6}>
                    <CMLabel sx={{ margin: 2 }} fontSize='24px' color="black"><b>Historia Nota de Entrega</b></CMLabel>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} justifyContent="center">
                <Grid item sm={12}>
                <hr></hr><br></br>
                    <Grid container item xs={12} sm={12} alignItems="center">
                        <Grid item xs={12} sm={2} md={1}>
                            <CMLabel fontSize='14px' color='black'>Fecha inicio creación: </CMLabel>
                        </Grid>
                        <Grid item xs={12} sm={3.5} md={1.3} >
                            <CMDatePicker 
                                label="Desde"
                                value={fechaInicio}
                                onChange={(newDate) => setFechaInicio(newDate)}
                                readOnly={false}
                                fullWidth
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={0.5} md={0.2}>
                        </Grid>
                        <Grid item xs={12} sm={1.5} md={0.8}>
                            <CMLabel fontSize='14px' color='black'  sx={{marginLeft:'10px'}}>Fecha fin creación: </CMLabel>
                        </Grid>
                        <Grid item xs={12} sm={3.5} md={1.3}>
                            <CMDatePicker 
                                label="Hasta"
                                value={fechaFin}
                                onChange={(newDate) => setFechaFin(newDate)}
                                readOnly={false}
                                fullWidth
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1} md={0.2}>

                        </Grid>
                        <Grid item xs={12}  sm={2} md={0.5}>
                            <CMLabel fontSize='14px' color='black'>Estados: </CMLabel>
                        </Grid>
                       <Grid item xs={12}  sm={3.5} md={1.5}>
                        <Select
                          labelId="estado-select-label"
                          id="estado-select"
                          value={selectedState}
                          label="Selecciona un Estado"
                          onChange={handleChange}
                          fullWidth
                        >
                          <MenuItem value="" disabled>
                            Selecciona un estado
                          </MenuItem>
                          {rowsestado.map((estado) => (
                            <MenuItem key={estado.id_oc_estado} value={estado.id_oc_estado}>
                              {estado.nombre_oc_estado}
                            </MenuItem>
                          ))}
                        </Select>
                       </Grid>
                       <Grid container item sx={12} sm={0.5} md={0.2}>
                        </Grid>
                        <Grid container item sx={12} sm={1.5} md={0.5}>
                          <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Filtro</CMLabel>
                      </Grid>
                      <Grid container item sx={12} sm={3.5} md={2.1}>
                        <CMTextField
                            name="filtro"
                            label='fittro'
                            value={filtro}
                            onChange={(e)=>handleFiltro(e)}
                            fullWidth
                          />
                      </Grid>
                        <Grid item xs={12} sm={2} md={1.2} mx={{marginTop:'10px'}}>
                            <CMButton
                                label="Consultar"
                                variant="contained"
                                sx={{marginLeft:'10px'}}
                                onClick={updateOrdenes}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} md={1.2} mx={{marginTop:'10px'}}>
                        <CMButton
                                label="Exportar"
                                variant="contained"
                                sx={{marginLeft:'10px'}}
                                disabled={btndisabled}
                                onClick={exportToExcel}
                            />
                        </Grid>
                     </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
  <Grid style={{ height: 500, width: '100%', overflowX: 'auto' }} sx={{ py: 0 }}>
    <DataGrid
      rows={rowsdata}
      columns={columns}
      pageSize={15}
      loading={loading}  // Propiedad que indica si está cargando o no

      rowsPerPageOptions={[15]}
      sx={{
        height: "100%",
        fontSize: 12,
        "& .MuiDataGrid-window": {
          overflowX: 'scroll',
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        },
      }}
      pagination
      rowHeight={50}
    />
  </Grid>
</Grid>
    </Grid>
    </Grid>
 )
}

export default HistorialNotaEntregaController
//Mui
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//Components
import CMTextField from '../atoms/CMTextField';

const CMDatePicker = (props) => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          views={props.views}
          renderInput={(props) => <CMTextField fullWidth {...props} {...(props.error && {error:true, helperText: props.error})}/>}
          label= {props.label}
          value = {props.value}
          onChange = {props.onChange}
          minDate = {props.minDate}
          maxDate={props.maxDate}
          readOnly = {props.readOnly}
          locale={props.locale}
          inputFormat = {props.inputFormat ? props.inputFormat : "DD/MM/YYYY"}
          disabled = {props.disabled}

        />
      </LocalizationProvider>
    )
}

export default CMDatePicker;
import axios from "axios";
import {urlAzure} from "../config";

export async function validateLogin(data, setValue, setUser) {
    var data = JSON.stringify({
        usuario: data.usuario,
        contrasena: data.contrasena
    });
    var config = {
        method: 'post',
        url: `${urlAzure}api/Authentication/validacion`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
    const responseLogin = await axios(config)
    setValue(responseLogin.data);
    setUser(responseLogin.data);
    window.localStorage.setItem("isAuthenticated", true);
}
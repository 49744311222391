// React
import React, { useContext } from 'react';
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
// MUI
import { Toolbar, AppBar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// Components
import CMIconButton from '../atoms/CMIconButton';
import { UserContext } from '../../context/UserContext';

const drawerWidth = 240;

const CMNavbar = ({handleDrawerToggle}) => {
  const location = useLocation();
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);

  return location.pathname==="/login" ? null : (
    <AppBar sx={{ 
        background: '#FF0101',
        position: "fixed",
        width: { xl: `calc(100% - ${drawerWidth}px)` },
        ml: { xl: `${drawerWidth}px` },
      }}>
      <Toolbar>
        <CMIconButton edge="start" color="inherit" aria-label="menu" onClick={() => handleDrawerToggle()} sx={{ mr: 2, display: { xl: 'none' } }}>
          <MenuIcon />
        </CMIconButton>
        <Typography variant="h6" noWrap sx={{flexGrow: 1}}>
        {/* Bienvenido, {user.name_warehouse} - {user.type_warehouse} */}
        Bienvenido, {user.usuario ? user.usuario : ''} 
        </Typography>
        <Link
          to="/login"
          sx={{ textDecoration: "none" }}
          color="inherit"
          onClick={()=> {setUser({}); window.localStorage.setItem("isAuthenticated", false); history.push('/login'); history.go(0);}}
        >
          <CMIconButton edge="start" aria-label="exit">
            <ExitToAppIcon style={{color:'white'}}/>
          </CMIconButton>
        </Link>
        
      </Toolbar>
    </AppBar>
  );
}

export default CMNavbar;
// React
import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
// MUI
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// Components
import { Logo } from '../../styles/login/Login.style';
import CMTextField from '../../components/atoms/CMTextField';
import CMButton from '../../components/atoms/CMButton';
import CMNotification from "../../components/molecules/CMNotification";
// Images
import loginBackground from '../../assets/tienda_san_miguel.jpg';
import logoMA from '../../assets/logoMA3.png';
// Services
import * as loginService from '../../services/LoginService';
import { UserContext } from '../../context/UserContext';

const theme = createTheme();
const initialValues = {
  id: 0,
  usuario: '',
  contrasena: '',
  rol: ''
}

export default function Login() {
  const [usuario, setUsuario] = useState(initialValues);
  const [notify, setNotify] = useState({isOpen: false, message: '', type: ''});
  const { user, setUser } = useContext(UserContext);
  const [load, setLoad] = useState(false);
  const history = useHistory();

  const handleInputChange = e => {
    const {name, value} = e.target;
    setUsuario({
      ...usuario,
      [name]: value
    })
  }

  const handleSubmit = async (event) => {
    setLoad(true);
    event.preventDefault();
    try{
      await loginService.validateLogin(usuario, setUsuario, setUser).then((resp) => {
        history.push("/inicio");
        history.go(0);
      });
    } 
    catch (err){
      window.localStorage.clear();
      setNotify({
        isOpen: true,
        message: 'Usuario o contraseña incorrectos',
        type: 'error'
      })
    }
    setLoad(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} sx={{
            backgroundImage: `url(${loginBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box sx={{ my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
            <Logo alt="Logo-MA" src={logoMA}/>
            <br/>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <CMTextField
                margin="normal"
                required
                fullWidth
                name="usuario"
                label="Usuario"
                value={usuario.usuario}
                onChange={handleInputChange}
                color={"error"}
                autoFocus
              />
              <CMTextField
                margin="normal"
                required
                fullWidth
                name="contrasena"
                label="Contraseña"
                value={usuario.contrasena}
                onChange={handleInputChange}
                color={"error"}
                type="password"
              />
              <CMButton
                fullWidth
                label="Ingresar"
                type="submit"
                variant="contained"
                sx={{ mt: 5, mb: 5 }}
                style={{ background: "red",color:"white" }}
                disabled={load}
              />
            </Box>
            <CMNotification 
              notify={notify}
              setNotify={setNotify}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
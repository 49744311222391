import './App.css';
import { ThemeProvider as ThemeMaterial} from '@mui/material/styles';
import styled, { ThemeProvider as ThemeStyled} from 'styled-components';
import { UserProvider } from './context/UserContext';
import theme from './styles/theme';
import Router from './routers/Router';

function App() {
  
  const ScrollBarContent=styled.div`
    @media (min-width: 500px) {
      max-height: 100vh;
      ::-webkit-scrollbar {
        width: 9px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: #aaa;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #666;
      }

      overflow: auto;
    }
  `

  return (
    <ScrollBarContent >
      <ThemeMaterial theme = {theme}>
        <ThemeStyled theme = {theme}>
          <UserProvider>
            <Router />
          </UserProvider>
        </ThemeStyled>
      </ThemeMaterial>
    </ScrollBarContent>
  );
}

export default App;

import React, { useState,useEffect,useCallback} from "react";
import moment from 'moment';


import { Grid ,Typography} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
  } from '@mui/x-data-grid';
import { Icon } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { useParams, useHistory } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//Components
import CMLabel from '../../components/atoms/CMLabel';
import CMDatePicker from '../../components/molecules/CMDatePicker';
import CMButton from '../../components/atoms/CMButton';

//Constants
import {MomentLocaleEs} from '../../constants/MomentLocaleEs';
//Services
import * as OrdenesService from '../../services/OrdenesService';
import * as ProveedorService from '../../services/ProveedorService';

import Checkbox from '@mui/material/Checkbox';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CMTextField from "../../components/atoms/CMTextField";
import CMIconButton from '../../components/atoms/CMIconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const MySwal = withReactContent(Swal)


const OrdenServicioRecibidaVer = () => {
  const history = useHistory();
    const [rowsdata, setRowsdata] = useState([]);
    const [rowsgrid, setRowsgrid] = useState([]);
    const [comment,setComment] = useState('');
    const user = JSON.parse(window.localStorage.getItem("user"));
    const useFakeMutation = () => {
      return React.useCallback(
        (user) =>
          new Promise((resolve, reject) =>
            setTimeout(() => {
              if (user.name?.trim() === '') {
                reject(new Error("Error al guardar el usuario: el nombre no puede estar vacÃ­o"));
              } else {
                resolve({ ...user, name: user.name?.toUpperCase() });
              }
            }, 200),
          ),
        [],
      );
    };
    const mutateRow = useFakeMutation();
    const [snackbar, setSnackbar] = useState(null);
    const [dataresponse,setDataresponse] = useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const handleProcessRowUpdateError = React.useCallback((error) => {
      setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const { ordenid } = useParams();
    useEffect(() => {
        OrdenesService.getOCID(setRowsdata,ordenid,user.token);
    }, []);
    let idCounter = 0; // Inicializamos el contador

    useEffect(() => {
        if (rowsdata.oc_detalles) {
            const rowsWithId = rowsdata.oc_detalles.map(row => ({
                ...row,
                id: idCounter++
            }));
            setRowsgrid(rowsWithId);
        }
    }, [rowsdata]);
    

    const formatCurrency = (value, moneda) => {
      let currency = 'PEN';
    
      if (moneda === 'S/') {
          currency = 'PEN';
        }else  if (moneda === 'US$') {
        currency = 'USD';
      }
    
      return value.toLocaleString('es-PE', {
        style: 'currency',
        currency: currency,
      });
    };

 
   
   
    const columns = [
      { field: 'codigo_articulo', headerName: 'Codigo Árticulo', width: 120, align: 'center',headerAlign: 'center' },
      { field: 'nombre_articulo', headerName: 'Árticulo', width: 480,headerAlign: 'center' },
      { field: 'unidad_medida', headerName: 'Unidad Medida', width: 180, align: 'center',headerAlign: 'center' },
      { field: 'cantidad_pedida', headerName: 'Cantidad Pedida', width: 150, align: 'center',headerAlign: 'center' },
          { 
          field: 'fecha_entrega', 
          headerName: 'Fecha Acuerdo', 
          type:'date',
          width: 140, 
          align: 'center',
          headerAlign: 'center',
          valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY') 
        },
        {
          field: 'tipo_igv',
          headerName: 'Tipo IGV',
          width: 180,
          align: 'center',
          headerAlign: 'center',
          valueGetter: (params) => {
            const valorSinIGV = params.row.include_igv==='EXONERADO' ?
             'EXONERADO' :
             params.row.include_igv==='IGV' ? 'IGV 18%': 'IGV 10%';
            return valorSinIGV;
          },
        },
        {
          field: 'valor_sin_igv',
          headerName: 'Precio sin IGV',
          width: 180,
          align: 'center',
          headerAlign: 'center',
          valueFormatter: (params) => formatCurrency(params.value, rowsdata.moneda),
          valueGetter: (params) => {
            const totalServicio = params.row.precio_unitario;
            const valorSinIGV = params.row.include_igv==='EXONERADO' ? totalServicio :
            params.row.include_igv==='IGV' ? totalServicio / (1.18) :
            totalServicio / (1.10);
            return valorSinIGV;
          },
        },
        { field: 'precio_unitario', headerName: 'Precio Unitario', width: 150, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda) },
        {
          field: 'igv',
          headerName: 'IGV',
          width: 180,
          align: 'center',
          headerAlign: 'center',
          valueFormatter: (params) => formatCurrency(params.value, rowsdata.moneda),
          valueGetter: (params) => {
            const totalServicio = params.row.total_articulo;
            const valorSinIGV = params.row.include_igv==='EXONERADO' ? 0 : 
            params.row.include_igv==='IGV' ? totalServicio - totalServicio / (1.18):
            totalServicio - totalServicio / (1.10);
            return valorSinIGV;
          },
        },
        { field: 'total_articulo', headerName: 'Total Árticulo', width: 150, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda) },

        { field: 'codigo_ceco', headerName: 'Codigo Ceco', width: 120, align: 'center',headerAlign: 'center'},
        { field: 'codigo_seccion', headerName: 'Codigo Sección', width: 120, align: 'center',headerAlign: 'center'},
        { field: 'codigo_departamente', headerName: 'Codigo departamento', width: 170, align: 'center',headerAlign: 'center'},
        { field: 'codigo_almacen_sap', headerName: 'Codigo Almacen', width: 170, align: 'center',headerAlign: 'center'},
        { field: 'codigo_ceco_concat', headerName: 'Ceco', width: 480, align: 'center',headerAlign: 'center'},

    ];
    const columns2 = [
      { field: 'descripcion', headerName: 'Descripción', width: 650, align: 'center',headerAlign: 'center'},
      { field: 'fecha_entrega', headerName: 'Fecha Entrega', width: 180, align: 'center',headerAlign: 'center',
      valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000'
    },
    { field: 'codigo_ceco', headerName: 'Codigo Ceco', width: 120, align: 'center',headerAlign: 'center'},
    { field: 'codigo_seccion', headerName: 'Codigo Sección', width: 120, align: 'center',headerAlign: 'center'},
    { field: 'codigo_departamente', headerName: 'Codigo departamento', width: 170, align: 'center',headerAlign: 'center'},
    { field: 'codigo_ceco_concat', headerName: 'Ceco', width: 480, align: 'center',headerAlign: 'center'}, 
    { field: 'nombre_cuenta_mayor', headerName: 'Nombre Cuenta Mayor', width: 360, align: 'center',headerAlign: 'center'},
      { field: 'numero_cuenta_mayor', headerName: 'Numero Cuenta Mayor', width: 180, align: 'center',headerAlign: 'center'},
      {
        field: 'tipo_igv',
        headerName: 'Tipo IGV',
        width: 180,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (params) => {
          const valorSinIGV = params.row.include_igv==='EXONERADO' ?
           'EXONERADO' :
           params.row.include_igv==='IGV' ? 'IGV 18%': 'IGV 10%';
          return valorSinIGV;
        },
      },
      {
        field: 'valor_sin_igv',
        headerName: 'Precio sin IGV',
        width: 180,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: (params) => formatCurrency(params.value, rowsdata.moneda),
        valueGetter: (params) => {
          const totalServicio = params.row.total_servicio;
          const valorSinIGV = params.row.include_igv==='EXONERADO' ? totalServicio :
          params.row.include_igv==='IGV' ? totalServicio / (1.18) : totalServicio / (1.10);
          return valorSinIGV;
        },
      },
      {
        field: 'igv',
        headerName: 'IGV',
        width: 180,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: (params) => formatCurrency(params.value, rowsdata.moneda),
        valueGetter: (params) => {
          const totalServicio = params.row.total_servicio;
          const valorSinIGV = params.row.include_igv==='EXONERADO' ?  0 :
          params.row.include_igv==='IGV' ? totalServicio - totalServicio / (1.18) : 
          totalServicio - totalServicio / (1.10);
          return valorSinIGV;
        },
      },
      { field: 'total_servicio', headerName: 'Precio', width: 180, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda) },


    ]
      const processRowUpdate  = useCallback(
        async (newRow) => {
          // Make the HTTP request to save in the backend
          const response = await mutateRow(newRow);
          setDataresponse(response);
          
          setSnackbar({ children: 'Guardo exitosamente', severity: 'success' });
          return response;
  
        },
        [mutateRow],
        
      ); 
      useEffect(() => {
        if(dataresponse) {
            const {id_oc_detalle,codigo_articulo,unidad_medida,cantidad_pedida,cantidad_entregada,cantidad_recibida,fecha_entrega,fecha_recepcion,fecha_termino,precio_unitario,total_articulo,servicio_entregado} = dataresponse;
            if(rowsdata.tipo==='Articulo' && !cantidad_entregada){
              alert('Debe ingresar la cantidad recibida');        
            }
            if(!fecha_termino){
              alert('Debe una fecha de entrega'); 
            }
            
            const oldDevices = [...rowsgrid];
            const rowDeviceIndex = oldDevices.findIndex((dev) => dev.id_oc_detalle === id_oc_detalle);
            oldDevices[rowDeviceIndex] = {
              ...oldDevices[rowDeviceIndex],
              id_oc_detalle,codigo_articulo,unidad_medida,cantidad_pedida,cantidad_entregada,cantidad_recibida,fecha_entrega,fecha_recepcion,fecha_termino,precio_unitario,total_articulo,servicio_entregado
            };
            setRowsgrid(oldDevices);
        }
      }, [dataresponse])

      const hanbleEntregada = () => {
        if (rowsdata.tipo === 'Articulo' && !rowsgrid.some(row => row.cantidad_entregada)) {
          return;
        }
        if (!rowsgrid.some(row => row.fecha_termino)) {
          return;
        }
        
        Swal.fire({
          title: 'Registrar cantidad y fecha Entregada',
          text: "¿Esta conforme con lo registrado?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'
          }).then((result) => {
          if (result.isConfirmed) {
            const datosEntrega = {
              id_oc_cabecera: rowsdata.id_oc_cabecera,
              id_proveedor: rowsdata.id_proveedor,
              comentario: comment,
              tipo: rowsdata.tipo,
              oc_detalles: rowsgrid.map((datos) => datos && rowsdata.tipo === "Articulo" ? {
                id_oc_detalle: datos.id_oc_detalle,
                fecha_entrega: datos.fecha_entrega,
                fecha_termino: datos.fecha_termino,
                fecha_recepcion: null,
                cantidad_pedida: datos.cantidad_pedida,
                cantidad_entregada: datos.cantidad_entregada,
                cantidad_recibida: null
              } : {
              id_oc_detalle: datos.id_oc_detalle,
              fecha_entrega: datos.fecha_entrega,
              fecha_termino: datos.fecha_termino,
              fecha_recepcion: null,
              servicio_entregado: datos.servicio_entregado,
              servicio_conforme: null
            }
              )
            };
              ProveedorService.crearConformidad(datosEntrega,setSnackbar, user.token)
          }
        })
      };
      const handleComentarioChange = e => {
        const {name, value} = e.target;
        setComment(value)
      }
      const handleClickRegresar = () => {
        history.push(`/historialordenesrecibidas`);
        history.go(0);
      }
      useEffect(() => {
        if(rowsdata.id_proveedor && user){
             if(user.id_usuario != rowsdata.id_proveedor){
              history.push('/inicio');
              history.go();
            }
        }
      }, [rowsdata])
 return (
    <Grid width={'100%'} m="auto">
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2} sx={{py:1}}>
        <Grid container item xs={12} sm={12} >
                <Grid item sm={6}>
                    <CMLabel sx={{ margin: 2 }} fontSize='28px' color="black"><b>Numero de Orden de {rowsdata.tipo==='Articulo' ? 'Compra' : 'Servicio'} #{rowsdata.numero_documento_orden}</b></CMLabel>
                </Grid>
                <Grid item sm={6} container justifyContent="flex-end" alignItems="center" >
                  <Grid container xs={2} justifyContent="flex-end" alignItems="center" >
                        <Grid item justifyContent="flex-end" alignItems="center" >
                          <CMIconButton disableRipple={true} size="large" sx={{color: "primary.main", display: "block"}} onClick={handleClickRegresar}>
                            <ArrowBackIcon/>
                            <Typography sx={{lineHeight: 0}}>Regresar</Typography>
                          </CMIconButton>
                        </Grid>
                  </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Proveedor</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Nombre :</b> {rowsdata.nombre_proveedor}</CMLabel>
                    </Grid>
                </Grid>
                <Grid item sm={1}>
                   
                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Solicitante</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Nombre :</b> {rowsdata.nombre_solicitante}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Destino :</b> {rowsdata.destino}</CMLabel>
                    </Grid>
                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Detalle Orden de {rowsdata.tipo==='Articulo' ? 'Compra' : 'Servicio'}</b></CMLabel>
                    <hr></hr>

                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Estado :</b> {rowsdata.estado}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>OC Estado :</b> {rowsdata.ultimo_nombre_oc_estado}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Observación :</b> {rowsdata.observaciones}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Condición de Pago :</b> {rowsdata.condic_pago}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Fecha Contable :</b> {moment(rowsdata.fecha_contable).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Fecha Entrega :</b> {moment(rowsdata.fecha_entrega).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Fecha Registro :</b> {moment(rowsdata.fecha_registro).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>OC Actualización :</b> {moment(rowsdata.ultima_fecha_modificacion).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                </Grid>
                <Grid item sm={1}>
                   
                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Importe</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={6} container justifyContent="space-between" alignItems="center" >
                      <Grid item sm={12} container justifyContent="space-between">
                          <Grid>
                          <CMLabel fontSize='16px' color='black'><b>Subtotal :</b></CMLabel>
                          </Grid>
                          <Grid>
                          <CMLabel fontSize='16px' color='black'> {rowsdata.total_antes_descuento ? formatCurrency(rowsdata.total_antes_descuento,rowsdata.moneda) : ''}</CMLabel>
                          </Grid>
                      </Grid>
                      <Grid item sm={12}  container justifyContent="space-between">
                          <Grid>
                          <CMLabel fontSize='16px' color='black'><b>Impuesto :</b></CMLabel>
                          </Grid>
                          <Grid>
                          <CMLabel fontSize='16px' color='black'>  { rowsdata.impuesto ? formatCurrency(rowsdata.impuesto,rowsdata.moneda) : formatCurrency(0,rowsdata.moneda)}</CMLabel>
                          </Grid>
                      </Grid>
                      <Grid item sm={12}  container justifyContent="space-between">
                        <Grid>
                        <CMLabel fontSize='16px' color='black'><b>Total :</b></CMLabel>
                        </Grid>
                        <Grid>
                        <CMLabel fontSize='16px' color='black'> {rowsdata.total_pago_vencido ? formatCurrency(rowsdata.total_pago_vencido,rowsdata.moneda) : ''}</CMLabel>
                        </Grid>
                      </Grid>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
  <Grid style={{ height: 400, width: '100%', overflowX: 'auto' }} sx={{ py: 0 }}>
  {rowsdata.tipo==='Articulo' ?
    <DataGrid
      rows={rowsgrid}
      columns={columns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      sx={{
        height: "100%",
        "& .MuiDataGrid-window": {
          overflowX: 'scroll',
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        },
      }}
      pagination
      rowHeight={50}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
      experimentalFeatures={{ newEditingApi: true }}
    />
     :  <DataGrid
     rows={rowsgrid}
     columns={columns2}
     pageSize={15}
     rowsPerPageOptions={[15]}
     sx={{
       height: "100%",
       "& .MuiDataGrid-window": {
         overflowX: 'scroll',
         "&::-webkit-scrollbar": {
           width: "0.4em",
         },
         "&::-webkit-scrollbar-track": {
           background: "#f1f1f1",
         },
         "&::-webkit-scrollbar-thumb": {
           backgroundColor: "#888",
         },
         "&::-webkit-scrollbar-thumb:hover": {
           background: "#555",
         },
       },
     }}
     pagination
     rowHeight={50}
     processRowUpdate={processRowUpdate}
     onProcessRowUpdateError={handleProcessRowUpdateError}
     experimentalFeatures={{ newEditingApi: true }}
   />}
     {!!snackbar && (
                    <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
  </Grid>
  <Grid item xs={12} sm={12} container>
    <Grid item xs={12} sm={6} container alignItems="center">
        <Grid item xs={6} sm={3}>
					<CMLabel fontSize='16px'color='black'>Comentario: </CMLabel>
				</Grid>
				<Grid item xs={6} sm={6}>
					<CMTextField
						name="comment"
						label='Comentario'
						value={comment}
						onChange={handleComentarioChange}
						fullWidth
					/>
				</Grid>
    </Grid>
    <Grid item xs={12} sm={6} container justifyContent="flex-end">
    <CMButton
      label="Registrar"
      variant="contained"
      sx={{ marginTop: '10px' }}
      disabled
      onClick={hanbleEntregada}
    />
    </Grid>
    
  </Grid>
</Grid>
    </Grid>
    </Grid>
 )
}

export default OrdenServicioRecibidaVer
import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/x-data-grid';
import { esES as coreEsES} from '@mui/material/locale';

const theme = createTheme (
  {
    typography: {
      // fontFamily: "'Montserrat', sans-serif;",
      button: {
        textTransform: "none",
        color:"#FF0101"
      },
    //   h4: {
    //     fontSize: 36,
    //   },
    //   h5: {
    //     fontSize: 24,
    //   },
       body1: {
         fontSize: 14,
       },
    //   body2: {
    //     fontSize: 16,
    //   },
    //   button: {
    //     textTransform: "none",
    //     fontSize: 16,
    //   },
    },   
    palette: {
      primary: {
        main: "#FF0101",
        dark: "#4c7da4",
      },
      white: "#FFFFFF",
      black: "#000000",
      bloqueo: "#9e9e9e",
      dispoCelda: "#9be7ff",
      chatHeader: "#EDEDED",
      chatSearch: "#F6F6F6",
      chatHover: "#FAFAFA",
      chatActive: "#F5F5F5",
      citaFinalizada: "#666666",
      azulTutor: "#0A7BC2",
      moradoPsico: "#9674CF",
      azul: "#004EA8",
      lightGreen: "#00876830"
    },
    shape: {
      borderRadius: 5,
    },
    weight: {
      regulr: 400,
      medium: 500,
      semibold: 600,
      bold: 700
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 6000,
      },
    },
  },
  esES,
  coreEsES,
);

export default theme;
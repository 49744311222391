
import axios from "axios";
import {urlAzure,url} from "../config";

export async function getIdNE(id,setValue, token) {
    try {
      var datos = '';
      const config = {
        method: 'get',
        url: `${urlAzure}api/NE/list/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: datos,
      };
  
      const response = await axios(config);
      setValue(response.data);
    } catch (error) {
      // Manejar el error aquí
      console.error('Error al obtener datos de Nota de Entrega:', error);
      return null;
    }
  }



  export async function getListHistorialNotaEntregaController(datalist,setLoading, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/listControllerget`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setLoading(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  export async function getListHistorialNotaEntregaControllerExcel(datalist,setBtndisabled, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/listControllerExportget`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setBtndisabled(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }


  export async function getListHistorialNotaEntregaControllerEstado(datalist, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/listControllerestadoget`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  export async function getListHistorialNotaEntregaControllerExcelEstado(datalist, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/listControllerestadogetExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }


  export async function getNEListEstado(setValue, token) {
    var data = '';
    var config = {
      method: 'get',
      url: `${urlAzure}api/OCEstado/ListOCEstado`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
  
    try {
      const response = await axios(config);
  
      // Filtrar los id_oc_estado del 15 al 20 y el 24
      const filteredData = response.data.filter(item => {
        const id_oc_estado = item.id_oc_estado;
        return id_oc_estado >= 15 && id_oc_estado <= 20 || id_oc_estado === 24;
      });
  
      // Agregar el campo "id" a los datos filtrados
      const dataWithIds = filteredData.map((item, index) => ({ ...item, id: index + 1 }));
  
      // Agregar el nuevo elemento
      const nuevoElemento = {
        id_oc_estado: 0,
        nombre_oc_estado: "TODOS",
        orden: 0,
        id: dataWithIds.length + 1 // Asignar un nuevo id único
      };
  
      const newData = [...dataWithIds, nuevoElemento];
  
      // Ordenar por la propiedad 'orden'
      newData.sort((a, b) => a.orden - b.orden);
  
      setValue(newData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  
  
  export async function getListHistorialNotaEntregaControllerArea(datalist,setLoading, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/listControllergetArea`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setLoading(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  export async function getListHistorialNotaEntregaControllerExcelArea(datalist,setBtndisabled, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/listControllerAreaExportget`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setBtndisabled(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

import React from "react";
import IconButton from '@mui/material/IconButton';

const CMIconButton = (props) => {

    return (
        <IconButton {...props}>{props.children}</IconButton>
    );
};


export default CMIconButton;
// import React, {useContext} from "react";
// import { Route, Redirect } from "react-router-dom";
// import { UserContext } from "../context/UserContext";

// const ProtectedRoute = ({ roles, component: Component, ...rest }) => {
//   const isAuthenticated = window.localStorage.getItem("isAuthenticated");
//   const {user} = useContext(UserContext);
//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         if (isAuthenticated) {
//           switch(true){
//             case(Component && (!roles || (roles && user && user.id_rol && roles.includes(user.id_rol)))):
//               return <Component {...props} />;
//             case(!!user):
//               return (
//                 <Redirect to="/"/>
//               );
//             default:
//               return (
//                 <Redirect to="/login" />
//               );
//           }
//         }
//         else {
//           return <Redirect to="/login" />;
//         }
//       }}
//     />
//   );
// };

// export default ProtectedRoute;

import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../context/UserContext";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = window.localStorage.getItem("isAuthenticated");
  const { user } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

export default ProtectedRoute;

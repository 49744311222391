import React from 'react'
import CMTextField from '../atoms/CMTextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const CMAutocomplete = (props) => {

  return (
    <Autocomplete
      freeSolo
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}
      isOptionEqualToValue={props.isOptionEqualToValue}
      getOptionLabel={props.getOptionLabel}
      options={props.options}
      loading={props.loading}
      onChange={props.onChange}
      inputValue={props.inputValue}
      onInputChange={props.onInputChange}
      renderInput={(params) => (
        <CMTextField
          {...params}
          {...props}
          variant='filled'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: 'primary.main', mb: 2}} />
                </InputAdornment>
              </React.Fragment>
            ),
          }}
        />
      )}
    />

  )
}

export default CMAutocomplete;


import React, { useState,useEffect,useCallback} from "react";
import moment from 'moment';


import { Grid ,Typography,InputLabel, MenuItem, Select,IconButton, Modal, Box} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
  } from '@mui/x-data-grid';
import { Icon } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { useParams, useHistory } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//Components
import CMLabel from '../../components/atoms/CMLabel';
import CMDatePicker from '../../components/molecules/CMDatePicker';
import CMButton from '../../components/atoms/CMButton';

//Constants
import {MomentLocaleEs} from '../../constants/MomentLocaleEs';
//Services
import * as OrdenesService from '../../services/OrdenesService';
import * as SolicitanteService from '../../services/SolicitanteService';
import * as NotaEntregaService from '../../services/NotaEntregaService';



import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CMTextField from "../../components/atoms/CMTextField";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CMIconButton from '../../components/atoms/CMIconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const MySwal = withReactContent(Swal)


const HistorialNotaEntregaSolicitanteVer = () => {
    const history = useHistory();

    const [rowsdata, setRowsdata] = useState([]);
    const [rowsgrid, setRowsgrid] = useState([]);
    const [comment,setComment] = useState('');
    const [notaentrega,setNotaEntrega] = useState([]);
    const [selectedOption, setSelectedOption] = React.useState('');
	const [cargando, setCargando] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [nedetalles, setNedetalles] = useState([]);


    const handleChange = (event) => {
        setSelectedOption(event.target.value);
      };
    const user = JSON.parse(window.localStorage.getItem("user"));
    const useFakeMutation = () => {
      return React.useCallback(
        (user) =>
          new Promise((resolve, reject) =>
            setTimeout(() => {
              if (user.name?.trim() === '') {
                reject(new Error("Error al guardar el usuario: el nombre no puede estar vacÃ­o"));
              } else {
                resolve({ ...user, name: user.name?.toUpperCase() });
              }
            }, 200),
          ),
        [],
      );
    };
    const mutateRow = useFakeMutation();
    const [snackbar, setSnackbar] = useState(null);
    const [dataresponse,setDataresponse] = useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const handleProcessRowUpdateError = React.useCallback((error) => {
      setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const { ordenid,numerodocumento } = useParams();
    useEffect(() => {
        OrdenesService.getNEID(setRowsdata,ordenid,numerodocumento,user.token);
    }, []);



    let idCounter = 0; // Inicializamos el contador

    useEffect(() => {
        if (rowsdata.ne_detalles) {
            const rowsWithId = rowsdata.ne_detalles.map(row => ({
                ...row,
                id: idCounter++
            }));
            setRowsgrid(rowsWithId);
        }
    }, [rowsdata]);
    const getRowId = (row) => {
      // Aquí puedes generar un identificador único basado en las propiedades de la fila
      // Por ejemplo, puedes concatenar múltiples propiedades para crear el identificador
      return `${row.id_ne_cabecera}-${row.id_oc_cabecera}`;
    };
    const getRowId2 = (row) => {
      // Aquí puedes generar un identificador único basado en las propiedades de la fila
      // Por ejemplo, puedes concatenar múltiples propiedades para crear el identificador
      return `${row.id_ne_detalle}-${row.id_ne_cabecera}`;
    };
    useEffect(() => {
      if (rowsdata && Array.isArray(rowsdata.notas_entrega) && rowsdata.notas_entrega.length > 0) {
        setNotaEntrega(rowsdata.notas_entrega);
      }
    }, [rowsdata]);

    const formatCurrency = (value, moneda) => {
        let currency = 'PEN';

        if (moneda === 'S/') {
            currency = 'PEN';
          }else  if (moneda === 'US$') {
          currency = 'USD';
        }

        return value.toLocaleString('es-PE', {
          style: 'currency',
          currency: currency,
        });
      };

      const handleOpenModal = (id_ne_cabecera) => {
        // console.log(id_ne_cabecera)
        NotaEntregaService.getIdNE(id_ne_cabecera,setSelectedRow,user.token)
      };
      useEffect(() => {
        if(selectedRow){
        setNedetalles(selectedRow.ne_detalles)
      }
      }, [selectedRow])

      const handleCloseModal = () => {
        setSelectedRow(null);
        setNedetalles([]);
      };

      const handleClickRegresar = () => {
        history.push(`/historialnotaentregasolicitante`);
        history.go(0);
      }


      const columns = [
        { field: 'codigo_articulo', headerName: 'Codigo Árticulo', width: 120, align: 'center',headerAlign: 'center' },
        { field: 'nombre_articulo', headerName: 'Árticulo', width: 480,headerAlign: 'center' },
        { field: 'unidad_medida', headerName: 'Unidad Medida', width: 180, align: 'center',headerAlign: 'center' },
        { field: 'cantidad_pedida', headerName: 'Cantidad Pedida', width: 150, align: 'center',headerAlign: 'center' },
        { field: 'cantidad_entregada', headerName: 'Cantidad Entregada', type:'number', width: 150, align: 'center',headerAlign: 'center'},
        { field: 'cantidad_recibida', headerName: 'Cantidad Recibida', width: 150, align: 'center',headerAlign: 'center' },
        {
            field: 'fecha_entrega',
            headerName: 'Fecha Acuerdo',
            type:'date',
            width: 140,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY')
          },
          {
            field: 'fecha_recepcion',
            headerName: 'Fecha Entrega',
            type: 'date',
            width: 140,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000',
          },
          {
            field: 'fecha_termino',
            headerName: 'Fecha Recibida',
            type: 'date',
            width: 140,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000'
          },
          { field: 'precio_unitario', headerName: 'Precio Unitario', width: 150, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda) },
          { field: 'total_articulo', headerName: 'Total Árticulo', width: 150, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda)  },

      ];
      const columns2 = [
        { field: 'descripcion', headerName: 'Descripción', width: 650, align: 'center',headerAlign: 'center'},
        { field: 'fecha_entrega', headerName: 'Fecha Entrega', width: 180, align: 'center',headerAlign: 'center',
        valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000'
      },
        { field: 'nombre_cuenta_mayor', headerName: 'Nombre Cuenta Mayor', width: 300, align: 'center',headerAlign: 'center'},
        { field: 'numero_cuenta_mayor', headerName: 'Numero Cuenta Mayor', width: 180, align: 'center',headerAlign: 'center'},
        { field: 'total_servicio', headerName: 'Total Servicio', width: 180, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda) },

      ]
      const columns3 = [
        {
          field: "ver", headerName: "Ver", width:50, justifyContent: "center",
          renderCell: (cellValues) => {
            return (
                <IconButton onClick={() => handleOpenModal(cellValues.row.id_ne_cabecera)}>
                <VisibilityIcon />
              </IconButton>
            )
          }
        },
        { field: 'destino', headerName: 'Destino', width: 650, align: 'center',headerAlign: 'center'},
        { field: 'nombre_proveedor', headerName: 'Nombre Proveedor', width: 300, align: 'center',headerAlign: 'center'},
        { field: 'nombre_solicitante', headerName: 'Nombre Solicitante', width: 300, align: 'center',headerAlign: 'center'},
        { field: 'ultimo_nombre_oc_estado', headerName: 'Nombre OC Estado', width: 300, align: 'center',headerAlign: 'center'},

      ]

      const columns4 = [
        { field: 'grupo_articulo', headerName: 'Grupo', width: 200, align: 'center',headerAlign: 'center'},
        { field: 'codigo_articulo', headerName: 'Codigo', width: 200, align: 'center',headerAlign: 'center'},
        { field: 'nombre_articulo', headerName: 'Nombre Articulo', width: 350, align: 'center',headerAlign: 'center'},
        { field: 'cantidad_recibida', headerName: 'Cantidad Recibida', width: 150, align: 'center',headerAlign: 'center'},
        { field: 'precio_unitario', headerName: 'Precio', width: 150, align: 'center',headerAlign: 'center',valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.precio_unitario,rowsdata.moneda) },
        { field: 'total_articulo', headerName: 'Total', width: 150, align: 'center',headerAlign: 'center',valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.total_articulo,rowsdata.moneda) },
      ]
      const columns5 = [
        { field: 'descripcion', headerName: 'Descripción', width: 250, align: 'center',headerAlign: 'center'},
        { field: 'servicio_entregado', headerName: 'Servicio Entregado', width: 60, align: 'center',headerAlign: 'center'},
        { field: 'servicio_conforme', headerName: 'Servicio Conforme', width: 60, align: 'center',headerAlign: 'center'},
        { field: 'total_servicio', headerName: 'Total Servicio', width: 150, align: 'center',headerAlign: 'center',valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.total_servicio,rowsdata.moneda) }
      ]
      const processRowUpdate  = useCallback(
        async (newRow) => {
          // Make the HTTP request to save in the backend
          const response = await mutateRow(newRow);
          setDataresponse(response);

          setSnackbar({ children: 'Guardo exitosamente', severity: 'success' });
          return response;

        },
        [mutateRow],

      );
      useEffect(() => {
        if(dataresponse) {
            const {id_oc_detalle,codigo_articulo,unidad_medida,cantidad_pedida,cantidad_entregada,cantidad_recibida,fecha_entrega,fecha_recepcion,fecha_termino,precio_unitario,total_articulo} = dataresponse;
            if(!cantidad_recibida){
              alert('Debe ingresar la cantidad recibida');
            }
            if(!fecha_recepcion){
              alert('Debe una fecha de entrega');
            }

            const oldDevices = [...rowsgrid];
            const rowDeviceIndex = oldDevices.findIndex((dev) => dev.id_oc_detalle === id_oc_detalle);
            oldDevices[rowDeviceIndex] = {
              ...oldDevices[rowDeviceIndex],
              id_oc_detalle,codigo_articulo,unidad_medida,cantidad_pedida,cantidad_entregada,cantidad_recibida,fecha_entrega,fecha_recepcion,fecha_termino,precio_unitario,total_articulo
            };
            setRowsgrid(oldDevices);
        }
      }, [dataresponse])

      const hanbleEntregada = () => {
        if(!selectedOption){
            Swal.fire(
                ''+rowsdata.tipo==='Articulo' ? 'Debe seleccionar un estado de la Orden de Compra' : 'Debe seleccionar un estado de la Orden de Servicio',
                '',
                'error'
              )
              return
        }
        Swal.fire({
          title: '¿Estado conforme en '+selectedOption+'?',
          text: "",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'
          }).then((result) => {
          if (result.isConfirmed) {
            setCargando(true);
            setIsDisabled(true);
              SolicitanteService.CrearNotaEntrega(rowsdata,setCargando,setIsDisabled, user.token).then((res) => {
                Swal.fire({
                    title: 'Se registró correctamente!',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'OK',
                  }).then((result) => {
                    if (result.isConfirmed) {
                        history.push(`/crearnotaentrega`);
                        history.go(0);
                    }
                  });
            });
          }
        })
      };
      const handleComentarioChange = e => {
        const {name, value} = e.target;
        setComment(value)
      }
 return (
    <Grid width={'100%'} m="auto">
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2} sx={{py:1}}>
        <Grid container item xs={12} sm={12} >
                <Grid item sm={6}>
                    <CMLabel sx={{ margin: 2 }} fontSize='28px' color="black"><b>Nota de Entrega #{rowsdata.numero_documento} </b></CMLabel>
                </Grid>
                <Grid item sm={6} container justifyContent="flex-end" alignItems="center" >
                  <Grid container xs={2} justifyContent="flex-end" alignItems="center" >
                        <Grid item justifyContent="flex-end" alignItems="center" >
                          <CMIconButton disableRipple={true} size="large" sx={{color: "primary.main", display: "block"}} onClick={handleClickRegresar}>
                            <ArrowBackIcon/>
                            <Typography sx={{lineHeight: 0}}>Regresar</Typography>
                          </CMIconButton>
                        </Grid>
                  </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Proveedor</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Nombre :</b> {rowsdata.nombre_proveedor}</CMLabel>
                    </Grid>
                </Grid>
                <Grid item sm={1}>

                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Solicitante</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Nombre :</b> {rowsdata.nombre_solicitante}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Destino :</b> {rowsdata.destino}</CMLabel>
                    </Grid>
                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Detalle Orden de {rowsdata.tipo==='Articulo' ? 'Compra' : 'Servicio'}</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Nro {rowsdata.tipo==='Articulo' ? 'OC' : 'OS'} :</b> {numerodocumento}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Guia :</b> {rowsdata.tipo_documento}-{rowsdata.serie_documento}-{rowsdata.correlativo_documento}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Estado :</b> {rowsdata.estado}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>OC Estado :</b> {rowsdata.ultimo_nombre_oc_estado}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Motivo :</b> {rowsdata.motivo_solicitud}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Observación :</b> {rowsdata.observaciones}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Condición de Pago :</b> {rowsdata.condic_pago}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Fecha Contable :</b> {moment(rowsdata.fecha_contable).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Fecha Entrega :</b> {moment(rowsdata.fecha_entrega).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Fecha Registro :</b> {moment(rowsdata.fecha_registro).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>OC Actualización :</b> {moment(rowsdata.ultima_fecha_modificacion).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                </Grid>
                <Grid item sm={1}>

                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Importe</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={6} container justifyContent="space-between" alignItems="center" >
                      <Grid item sm={12} container justifyContent="space-between">
                          <Grid>
                          <CMLabel fontSize='16px' color='black'><b>Subtotal :</b></CMLabel>
                          </Grid>
                          <Grid>
                          <CMLabel fontSize='16px' color='black'> {rowsdata.total_antes_descuento ? formatCurrency(rowsdata.total_antes_descuento,rowsdata.moneda) : ''}</CMLabel>
                          </Grid>
                      </Grid>
                      <Grid item sm={12}  container justifyContent="space-between">
                          <Grid>
                          <CMLabel fontSize='16px' color='black'><b>Impuesto :</b></CMLabel>
                          </Grid>
                          <Grid>
                          <CMLabel fontSize='16px' color='black'>  { rowsdata.impuesto ? formatCurrency(rowsdata.impuesto,rowsdata.moneda) : formatCurrency(0,rowsdata.moneda)}</CMLabel>
                          </Grid>
                      </Grid>
                      <Grid item sm={12}  container justifyContent="space-between">
                        <Grid>
                        <CMLabel fontSize='16px' color='black'><b>Total :</b></CMLabel>
                        </Grid>
                        <Grid>
                        <CMLabel fontSize='16px' color='black'> {rowsdata.total_pago_vencido ? formatCurrency(rowsdata.total_pago_vencido,rowsdata.moneda) : ''}</CMLabel>
                        </Grid>
                      </Grid>
                    </Grid>

                </Grid>

        </Grid>
        <Grid container item xs={12} sm={12}>
  <Grid>
  <CMLabel sx={{ margin: 2 }} fontSize='24px' color="black"></CMLabel>
  </Grid>
  <Grid style={{ height: 400, width: '100%', overflowX: 'auto' }} sx={{ py: 0 }}>
  {rowsdata.tipo==='Articulo' ?
    <DataGrid
      rows={rowsgrid}
      columns={columns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      sx={{
        height: "100%",
        "& .MuiDataGrid-window": {
          overflowX: 'scroll',
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        },
      }}
      pagination
      rowHeight={50}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
      experimentalFeatures={{ newEditingApi: true }}
    />
     :  <DataGrid
     rows={rowsgrid}
     columns={columns2}
     pageSize={15}
     rowsPerPageOptions={[15]}
     sx={{
       height: "100%",
       "& .MuiDataGrid-window": {
         overflowX: 'scroll',
         "&::-webkit-scrollbar": {
           width: "0.4em",
         },
         "&::-webkit-scrollbar-track": {
           background: "#f1f1f1",
         },
         "&::-webkit-scrollbar-thumb": {
           backgroundColor: "#888",
         },
         "&::-webkit-scrollbar-thumb:hover": {
           background: "#555",
         },
       },
     }}
     pagination
     rowHeight={50}
     processRowUpdate={processRowUpdate}
     onProcessRowUpdateError={handleProcessRowUpdateError}
     experimentalFeatures={{ newEditingApi: true }}
     loading={cargando}

   />}
     {!!snackbar && (
                    <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}


  </Grid>
  <Grid item sm={12}>
  {/* <CMLabel sx={{ margin: 4 }} fontSize='24px' color="black"><b>Notas de Entrega</b></CMLabel> */}
  {/* <Grid style={{ height: 400, width: '100%', overflowX: 'auto' }} sx={{ py: 0 }}> */}
  <Grid style={{ height: 0, width: '100%', overflowX: 'auto' }} sx={{ py: 0 }}>
  {/* <DataGrid
      rows={notaentrega}
      columns={columns3}
      getRowId={getRowId}
      pageSize={15}
      rowsPerPageOptions={[15]}
      sx={{
        height: "100%",
        "& .MuiDataGrid-window": {
          overflowX: 'scroll',
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        },
      }}
      pagination
      rowHeight={50}
    /> */}
    <Modal open={selectedRow !== null} onClose={handleCloseModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          {/* Contenido del popup */}
          {/* {selectedRow && (
            <Grid>
              <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Nota de Entrega:</b> {selectedRow.tipo==='Articulo'? 'Compra' : 'Servicio'}</CMLabel>
              <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>N° Nota de Entrega:</b> {selectedRow.id_ne_cabecera}</CMLabel>
              <CMLabel sx={{ margin: 2 }} fontSize='18px' color="black"><b>Condición de pago:</b> {selectedRow.condic_pago}</CMLabel>
              <CMLabel sx={{ margin: 2 }} fontSize='18px' color="black"><b>Fecha creación:</b> {moment(selectedRow.created_at).format('DD/MM/YYYY') }</CMLabel>
              <CMLabel sx={{ margin: 2 }} fontSize='18px' color="black"><b>Nombre Estado:</b> {selectedRow.ultimo_nombre_oc_estado }</CMLabel>
              <Grid style={{ height: 400, width: '100%', overflowX: 'auto' }} sx={{ py: 0 }}>
              {selectedRow.tipo==='Articulo' ?
              <DataGrid
                rows={nedetalles}
                columns={columns4}
                getRowId={getRowId2}
                pageSize={15}
                rowsPerPageOptions={[15]}
                sx={{
                  height: "100%",
                  "& .MuiDataGrid-window": {
                    overflowX: 'scroll',
                    "&::-webkit-scrollbar": {
                      width: "0.4em",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#555",
                    },
                  },
                }}
                pagination
                rowHeight={50}
                /> :
                <DataGrid
                rows={nedetalles}
                columns={columns5}
                getRowId={getRowId2}
                pageSize={15}
                rowsPerPageOptions={[15]}
                sx={{
                  height: "100%",
                  "& .MuiDataGrid-window": {
                    overflowX: 'scroll',
                    "&::-webkit-scrollbar": {
                      width: "0.4em",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#555",
                    },
                  },
                }}
                pagination
                rowHeight={50}
                />}
              </Grid>
              {/* Resto de los detalles del popup */}
            {/* </Grid> */}
          {/* )}  */}
        </Box>
      </Modal>
  </Grid>

  </Grid>
  <Grid item xs={12} sm={12} container>
    {/* <Grid item xs={12} sm={8} container alignItems="center">
        <Grid item xs={6} sm={1.5}>
					<CMLabel fontSize='16px'color='black'>Comentario: </CMLabel>
				</Grid>
				<Grid item xs={6} sm={6}>
					<CMTextField
						name="comment"
						label='Comentario'
						value={comment}
						onChange={handleComentarioChange}
						fullWidth
					/>
				</Grid>
    </Grid> */}
    <Grid item xs={12} sm={4} container justifyContent="flex-end" alignItems="center">
      <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
            {/* <CMButton
            label="Registrar"
            variant="contained"
            sx={{ marginTop: '10px' }}
            onClick={hanbleEntregada}
            disabled={isDisabled}
            /> */}
      </Grid>

    </Grid>

  </Grid>
</Grid>
    </Grid>
    </Grid>
 )
}

export default HistorialNotaEntregaSolicitanteVer
import React, { useState, useEffect, useCallback } from "react";
import moment from 'moment';


import { Grid, Typography, MenuItem, Select, Autocomplete, TextField, InputLabel } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

import {
  DataGrid,
  GridEditCellProps
} from '@mui/x-data-grid';
import CMAutocomplete from '../../components/molecules/CMAutocomplete';

import { Icon } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { useParams, useHistory } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//Components
import CMLabel from '../../components/atoms/CMLabel';
import CMDatePicker from '../../components/molecules/CMDatePicker';
import CMButton from '../../components/atoms/CMButton';
import CMIconButton from '../../components/atoms/CMIconButton';
import DeleteIcon from '@mui/icons-material/Delete';

//Constants
import { MomentLocaleEs } from '../../constants/MomentLocaleEs';
//Services
import * as OrdenesService from '../../services/OrdenesService';
import * as ProveedorService from '../../services/ProveedorService';


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CMTextField from "../../components/atoms/CMTextField";
import Checkbox from '@mui/material/Checkbox';

import './CrearOrdenes.css'
const MySwal = withReactContent(Swal)


const CrearOrdenesCompra = () => {
  const [rowsdata, setRowsdata] = useState([]);
  const [rowsgrid, setRowsgrid] = useState([]);
  const [comment, setComment] = useState('');
  //Nuevo
  const [proveedor, setProveedor] = useState([]);
  const [ruc, setRuc] = useState([]);
  const [idprovedor, setIdprovedor] = useState([]);
  const [nombreProveedor, setNombreProvedor] = useState([]);
  const [codigoProveedorsap, setCodigoProveedorsap] = useState([]);
  const [contacto, setContacto] = useState([]);
  const [selectPersonaContacto, setSelectPersonaContacto] = useState(null);
  const [fechahoy,setFechahoy] =useState(moment().locale('es', MomentLocaleEs));
  const [fechacontable, setFechacontable] = useState(moment().locale('es', MomentLocaleEs));
  const [fechaentrega, setFechaentrega] = useState(moment().locale('es', MomentLocaleEs));
  const [fecharegistro, setFecharegistro] = useState([]);
  const [motivotraslado, setMotivotraslado] = useState('');
  const [selectProveedor, setSelectProveedor] = useState(null);
  const [tipocambio, setTipocambio] = useState('S/');
  const [idcontacto,setIdcontacto] = useState([]);
  const [condiciondepago, setCondicionDepago] = useState([]);
  const [destino, setDestino] = useState('');
  const [observaciones, setObservaciones] = useState('');
  const [selectedConditionPago, setSelectedConditionPago] = useState(null); // Estado para almacenar la condición seleccionada
  const [selectedDestino, setSelectedDestino] = useState(null); // Estado para almacenar la condición seleccionada
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cecos, setCecos] = useState([]);
  const [almacenes, setAlmacenes] = useState([]);
  const [editedCecos, setEditedCecos] = useState([]);
  const [editedAlmacenes,setEditedAlmacenes] = useState([]);
	const [cargando, setCargando] = React.useState(false);
  const [serieDocumento, setSerieDocumento] = useState('');
  const [correlativoDocumento, setCorrelativoDocumento] = useState('');
  const [optionDestino, setOptionDestino] =useState([]);
  const [tipoDocumento, setTipoDocumento] = useState('00')
  const [records, setRecords] = useState([]);
  const [products, setProducts] = useState(null);
  const [valueProduct, setValueProduct] = useState('');
  const [selectionProduct, setSelectionProduct] = useState(null);
  const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [loadingGrid, setLoadingGrid] =  useState(false);
  const loading = open && options.length === 0;
  const [notify, setNotify] = useState({isOpen: false, message: '', type: ''});
  const [nextId, setNextId] = useState(1); // Variable para generar claves únicas
  const user = JSON.parse(window.localStorage.getItem("user"));
  const [registrarbtn, setRegistrarbtn] = useState(true);
  const [correo,setCorreo] = useState('');

  useEffect(() => {
    OrdenesService.getOCID(setRowsdata, ordenid, user.token);
    OrdenesService.getListProveedores(setProveedor, user.token);
    OrdenesService.getListPersonaContacto(setContacto, user.token);
   OrdenesService.getListDestino(setDestino, user.token);
    OrdenesService.getListProducts(setProducts, user.token);
    OrdenesService.getListCecos(setCecos, user.token);
    OrdenesService.getListAlmacen(setAlmacenes, user.token);

  }, []);
  useEffect(() => {
    let active = true;

    if (!loading)
      return undefined;

    (async () => {
      if (active)
        setOptions([...products]);
    })();

    return () => { active = false; };
  }, [loading]);

  useEffect(() => {
    if (!open)
      setOptions([]);
  }, [open])


  const message = (text, type) => {
    setNotify({
      isOpen: true,
      message: text,
      type: type
    })
  }

  // const handleAddProduct = () => {
  //   if (selectedProduct && !isProductAlreadyAdded(selectedProduct)) {
  //     const newProduct = {
  //       ...selectedProduct,
  //       id: nextId, // Asignar una clave única al producto
  //       precio_unitario: 0, // Valor inicial para el campo editable "Precio Unitario"
  //       cantidad_pedida: 0, // Valor inicial para el campo editable "Cantidad pedida"
  //       total_articulo:0, // Valor inicial para el campo editable "Total
  //       fecha_entrega: null, // Valor inicial para el campo editable "Fecha Entrega"
  //       codigo_ceco: null,
  //       codigo_departamento: null,
  //       codigo_seccion:null,
  //       codigo_almacen_sap:null
  //     };
  //     setSelectedProducts([...selectedProducts, newProduct]);
  //     setSelectedProduct(null);
  //     setNextId(nextId + 1); // Incrementar el valor de la próxima clave única
  //   }
  // };
 
  const isProductAlreadyAdded = (product) => {
    return selectedProducts.some((p) => p.codigo_producto_sap === product.codigo_producto_sap);
  };

  //Fin nuevo

  const useFakeMutation = () => {
    return React.useCallback(
      (user) =>
        new Promise((resolve, reject) =>
          setTimeout(() => {
            if (user.name?.trim() === '') {
              reject(new Error("Error al guardar el usuario: el nombre no puede estar vacÃ­o"));
            } else {
              resolve({ ...user, name: user.name?.toUpperCase() });
            }
          }, 200),
        ),
      [],
    );
  };
  const mutateRow = useFakeMutation();
  const [snackbar, setSnackbar] = useState(null);
  const [dataresponse, setDataresponse] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const { ordenid } = useParams();

  let idCounter = 0; // Inicializamos el contador

  // useEffect(() => {
  //   if (rowsdata.oc_detalles) {
  //     const rowsWithId = rowsdata.oc_detalles.map(row => ({
  //       ...row,
  //       id: idCounter++
  //     }));
  //     setRowsgrid(rowsWithId);
  //   }
  // }, [rowsdata]);


  const formatCurrency = (value) => {
    return value.toLocaleString('es-PE', {
      style: 'currency',
      currency: 'PEN',
    });
  };



  const formatNumberWithDot = (number) => {
    return number.toString().replace(',', '.');
  };

  const parseNumberWithDot = (value) => {
    return value.replace('.', ',');
  };

  
  const handleCellValueChange = async (params, newValue,codigo_ceco) => {
    const { id } = params;
    const editedCecoIndex = editedCecos.findIndex((ceco) => ceco.id === id);
  
    if (editedCecoIndex !== -1) {
      // Si el ceco ya ha sido editado previamente, actualiza su valor
      const updatedCecos = [...editedCecos];
      updatedCecos[editedCecoIndex] = { id, nombre_ceco: newValue, codigo_ceco};
      setEditedCecos(updatedCecos);
    } else {
      // Si es un ceco nuevo, añádelo al estado de editedCecos
      setEditedCecos((prevCecos) => [...prevCecos, { id, nombre_ceco: newValue, codigo_ceco }]);
    }
  
    try {
      // Obtener los valores de codigo_departamente, codigo_seccion y codigo_ceco del API getCecosId
      const response = await OrdenesService.getCecosId(codigo_ceco, user.token);
      if (response) {
        const { codigo_departamente, codigo_seccion } = response;
  
        // Actualizar los campos relacionados en la fila actual
        const updatedRecords = records.map((record) => {
          if (record.id === id) {
            return { ...record, nombre_ceco: newValue, codigo_departamente, codigo_seccion, codigo_ceco };
          }
          return record;
        });
        setRecords(updatedRecords);
      } else {
        console.error('La respuesta del API getCecosId no es válida:', response);
      }
    } catch (error) {
      // Manejar el error aquí
      console.error('Error al obtener los valores de codigo_departamente y codigo_seccion:', error);
    }
  };
  const handleCellValueChangeAlmacen = (params, newValue,codigo_almacen_sap) => {
    const { id } = params;
    const editedAlmacenIndex = editedAlmacenes.findIndex((almacen) => almacen.id === id);
  
    if (editedAlmacenIndex !== -1) {
      // Si el almacen ya ha sido editado previamente, actualiza su valor
      const updatedAlmacenes = [...editedAlmacenes];
      updatedAlmacenes[editedAlmacenIndex] = { id, nombre_almacen_sap: newValue, codigo_almacen_sap };
      setEditedAlmacenes(updatedAlmacenes);
    } else {
      // Si es un almacen nuevo, añádelo al estado de editedAlmacenes
      setEditedAlmacenes((prevAlmacenes) => [...prevAlmacenes, { id, nombre_almacen_sap: newValue,codigo_almacen_sap }]);
    }
  
    // Actualizar los campos relacionados en la fila actual
    const updatedRecords = records.map((record) => {
      if (record.id === id) {
        return { ...record, nombre_almacen_sap: newValue,codigo_almacen_sap };
      }
      return record;
    });
    setRecords(updatedRecords);
  };
  const handleProductChange = (value) => {
    if(options.includes(value)){
      setSelectionProduct(value);
    }
    else{
			setSelectionProduct(null);
		}
  };
  const agregarArticulo = () => {
		if(selectionProduct !== null){
			let newProduct = selectionProduct;
			newProduct.quantity_sent = 1;
			let inArray = records.filter((record) => record.id === newProduct.id);
			if(inArray.length === 0){
				setRecords([...records, newProduct]);
        setSelectionProduct(null);
				setValueProduct('')
			}
			else{
        setSnackbar({ children: 'Artículo ya se encuentra añadido', severity: 'error' });
			}
		}		else{
      setSnackbar({ children: 'Artículo ya se encuentra añadido', severity: 'error' });
    }
	}
  const handleCheckboxChange = (params, checked) => {
    // Actualizar el valor de incluyeIgv en los datos de esa fila
    const updatedRow = {...params.row, incluyeIgv: checked};
    
    setRecords(rows => 
      rows.map(row => 
        row.id === params.id ? updatedRow : row  
      )
    );
  }
  const columns = [
    {
      field: "accion", headerName: "Acción", width: 80, sortable: false, filterable: false, justifyContent: "center",
      renderCell: (cellValues) => {
        return (
          <CMIconButton onClick={() => {setRecords(records.filter(record => record.id !== cellValues.id))}}>
            <DeleteIcon/>
          </CMIconButton>
        )
      }
    },
    {
      field: 'incluyeIgv',
      headerName: 'Incluye IGV?', 
      renderCell: (params) => {
        return (
          <Checkbox
            checked={params.value} 
            onChange={(e) => handleCheckboxChange(params, e.target.checked)}  
          />
        )
      }
    },
    { field: 'codigo_producto_sap', headerName: 'Código', width: 150 },
    { field: 'nombre_producto_sap', headerName: 'Nombre del producto', width: 260 },
    { field: 'nombre_unidad_medida_logistica',headerName: 'Unidad de Medida', width: 150},
    {
      field: 'Valor_unitario',
      headerName: 'Valor unitario',
      width: 120,
      type: 'number',
      editable: true,
      cellClassName: 'gray-column', // Clase CSS personalizada para aplicar el color gris
      valueFormatter: (params) => params.value ? formatNumberWithDot(params.value) : '',
    },
    {
      field: 'cantidad_pedida',
      headerName: 'Cantidad pedida',
      width: 120,
      type: 'number',
      cellClassName: 'gray-column', // Clase CSS personalizada para aplicar el color gris
      editable: true,
    },
    {
      field: 'precio_unitario',
      headerName: 'Precio Unitario',
      width: 120,
      type: 'number',
      valueGetter: (params) => {
        const valorUnitario = params.row.Valor_unitario;
        const incluyeIgv = params.row.incluyeIgv; 
        const igv = incluyeIgv ? 1.18 : 1;
        return valorUnitario  ? (valorUnitario * igv).toFixed(2) : '';
      }
    },
    {
      field: 'igv',
      headerName: 'IGV',
      width: 120,
      type: 'number',
      valueGetter: (params) => {
        const valorUnitario = params.row.Valor_unitario;
        const cantidad_pedida = params.row.cantidad_pedida;
        const incluyeIgv = params.row.incluyeIgv; 
        const igv = incluyeIgv ? 0.18 : 0;
        return valorUnitario && cantidad_pedida ? (valorUnitario*cantidad_pedida * igv).toFixed(2) : '';
      }
    },
    {
      field: 'total_articulo',
      headerName: 'Total',
      width: 120,
      type: 'number',
      valueGetter: (params) => {
        const valorUnitario = params.row.Valor_unitario;
        const cantidad_pedida = params.row.cantidad_pedida;
        const incluyeIgv = params.row.incluyeIgv; 
        const igv = incluyeIgv ? 1.18 : 1;
        return valorUnitario && cantidad_pedida ? (valorUnitario*cantidad_pedida * igv).toFixed(2) : '';
      }
    },
    {
      field: 'fecha_entrega',
      headerName: 'Fecha Entrega',
      width: 120,
      type: 'date',
      cellClassName: 'gray-column', // Clase CSS personalizada para aplicar el color gris
      editable: true,
      valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000'
    },
    {
      field: 'nombre_ceco',
      headerName: 'Nombre Ceco',
      width: 240,
      editable: true,
      cellClassName: 'gray-column', // Clase CSS personalizada para aplicar el color gris
      renderCell: (params) => {
        const ceco = cecos.find((ceco) => ceco.id === params.value);
        const selectedValue = editedCecos.find((ceco) => ceco.id === params.id)?.nombre_ceco;
        const value = selectedValue || (ceco ? ceco.nombre_ceco : '');
        return value;
      },
      renderEditCell: (params) => {
        const editedCeco = editedCecos.find((ceco) => ceco.id === params.id);
        const value = editedCeco ? editedCeco.nombre_ceco : params.value;
        const ceco = cecos.find((ceco) => ceco.id === params.value); // Obtener el ceco correspondiente
        const codigo_ceco = ceco ? ceco.codigo_ceco : ''; // Obtener el codigo_ceco del ceco correspondiente
      
        return (
          <Autocomplete
            options={cecos.map((ceco) => `${ceco.nombre_ceco} | ${ceco.codigo_ceco}`)}
            value={value || ''}
            fullWidth
            onChange={(event, newValue) => {
              const selectedCodigoCeco = newValue ? newValue.split(' | ')[1] : ''; // Obtener el codigo_ceco del valor seleccionado
              handleCellValueChange(params, newValue, selectedCodigoCeco); // Pasar selectedCodigoCeco como tercer parámetro
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    // Realizar la acción de búsqueda al presionar Enter
                    handleCellValueChange(params, event.target.value, codigo_ceco);
                  }
                }}
              />
            )}
            onClick={(event) => {
              // Realizar la acción de búsqueda al hacer clic
              handleCellValueChange(params, event.target.textContent, codigo_ceco);
            }}
          />
        );
      },
    },
    {
      field: 'codigo_departamente',
      headerName: 'Codigo Departamento',
      width: 160,
      valueFormatter: (params) => params.value
    },
    {
      field: 'codigo_seccion',
      headerName: 'Codigo sección',
      width: 160,
      valueFormatter: (params) => params.value
    },
    {
      field: 'nombre_almacen_sap',
      headerName: 'Almacen',
      width: 200,
      editable: true,
      cellClassName: 'gray-column', // Clase CSS personalizada para aplicar el color gris
      renderCell: (params) => {
        const almacen = almacenes.find((almacen) => almacen.id === params.value);
        const selectedValue = editedAlmacenes.find((almacen) => almacen.id === params.id)?.nombre_almacen_sap;
        const value = selectedValue || (almacen ? almacen.nombre_almacen_sap : '');
        return value;
      },
      renderEditCell: (params) => {
        const editedAlmacen = editedAlmacenes.find((almacen) => almacen.id === params.id);
        const value = editedAlmacen ? editedAlmacen.nombre_almacen_sap : params.value;
        const almacen = almacenes.find((almacen) => almacen.id === params.value); // Obtener el ceco correspondiente

        return (
          <Autocomplete
            options={almacenes.map((almacen) =>  `${almacen.nombre_almacen_sap} -- ${almacen.codigo_almacen_sap}`)}
            value={value || ''}
            fullWidth
            onChange={(event, newValue) => {
              const codigo_almacen_sap = newValue ? newValue.split(' -- ')[1] : ''; // Obtener el codigo_ceco del valor seleccionado
              handleCellValueChangeAlmacen(params, newValue,codigo_almacen_sap);
            }}
            renderInput={(params) => <TextField {...params} autoFocus />}
          />
        );
      },
    }



  ];

  const processRowUpdate = useCallback(
    async (newRow) => {
      if (!newRow || typeof newRow !== 'object') {
        setSnackbar({ children: 'Error: newRow no es válido', severity: 'error' });
        return;
      }
  
      const {
        id,
        precio_unitario,
        cantidad_pedida,
        fecha_entrega,
        codigo_ceco,
        nombre_ceco,
        codigo_departamente,
        codigo_seccion,
        nombre_almacen_sap,
        codigo_almacen_sap,
        Valor_unitario,
        igv,
        incluyeIgv
      } = newRow;
  
  
      const updatedRecords = records.map((record) => {
        if (record.id === id) {
          return {
            ...record,
            precio_unitario,
            cantidad_pedida,
            fecha_entrega,
            codigo_ceco,
            nombre_ceco,
            codigo_departamente,
            codigo_seccion,
            nombre_almacen_sap,
            codigo_almacen_sap,
            Valor_unitario,
            igv,
            incluyeIgv
          };
        }
        return record;
      });
  
      setRecords(updatedRecords);
  
      setSnackbar({ children: 'Guardado exitosamente', severity: 'success' });
  
      const updatedRow = { ...newRow };
      const response = await mutateRow(updatedRow);
      setDataresponse(response);
      setRegistrarbtn(false);
      return response;
    },
    [records, mutateRow]
  );

  useEffect(() => {
    if (dataresponse) {
      const {id,codigo_producto_sap,nombre_producto_sap,nombre_unidad_medida_logistica,codigo_unidad_medida_logistica,Valor_unitario,igv,precio_unitario,cantidad_pedida,total_articulo,fecha_entrega,codigo_ceco,codigo_departamente,codigo_seccion,codigo_almacen_sap,nombre_almacen_sap,nombre_ceco,incluyeIgv } = dataresponse;

       const oldDevices = [...records];
       const rowDeviceIndex = oldDevices.findIndex((dev) => dev.id === id);
       oldDevices[rowDeviceIndex] = {
        ...oldDevices[rowDeviceIndex],
        id,codigo_producto_sap,nombre_producto_sap,nombre_unidad_medida_logistica,codigo_unidad_medida_logistica,Valor_unitario,igv,precio_unitario,cantidad_pedida,total_articulo,fecha_entrega,codigo_ceco,codigo_departamente,codigo_seccion,codigo_almacen_sap,nombre_almacen_sap,nombre_ceco,incluyeIgv
  };
     setRecords(oldDevices);
    }
  }, [dataresponse])

  const calculaTotales = () => {
    let total_pago_vencido = 0;
  
    const totales = records.map((item) => {
      const total_antes_descuento = item.cantidad_pedida * item.Valor_unitario*1.18;
      total_pago_vencido += total_antes_descuento; // Acumular la suma
  
      return total_pago_vencido;
    });
  
    return {
      totales,
      total_pago_vencido
    };
  };
  const hanbleRegistrar = () => {
    const fechaentregaDate = new Date(fechaentrega);
    const fechacontableCabeceraDate = new Date(fechacontable);

    if (fechaentregaDate === fechacontableCabeceraDate || fechaentregaDate+1 > fechacontableCabeceraDate) {
      setSnackbar({ children: 'La fecha de entrega debe ser mayor o igual que la fecha contable Cabecera', severity: 'error' });
      return;
    }
    if(!selectProveedor){
      setSnackbar({ children: 'Falta seleccionar el proveedor', severity: 'error' });
      return;
    }
    if(!selectPersonaContacto){
      setSnackbar({ children: 'Debe seleccionar un proveedor de contacto', severity: 'error' });
      return;
    }
    if(!motivotraslado){
      setSnackbar({ children: 'Debe indicar el motivo del traslado', severity: 'error' });
      return;
    }
    if(!observaciones){
      setSnackbar({ children: 'Debe indicar el resumen de la orden', severity: 'error' });
      return;
    }
    if(!selectedDestino){
      setSnackbar({ children: 'Debe seleccionar el destino', severity: 'error' });
      return;
    }
    // if(!serieDocumento){
    //   setSnackbar({ children: 'Debe indicar la serie de documento', severity: 'error' });
    //   return;
    // }
    // if(!correlativoDocumento){
    //   setSnackbar({ children: 'Debe indicar el correlativo de documento', severity: 'error' });
    //   return;
    // }
    if(records.length === 0){
      setSnackbar({ children: 'No hay articulos registrados', severity: 'error' });
      return;
    }
    for (const record of records) {
      // Verificar cada campo del objeto
      if (
        !record.id ||
        !record.Valor_unitario ||
        !record.cantidad_pedida ||
        !record.fecha_entrega ||
        !record.codigo_ceco ||
        !record.codigo_departamente ||
        !record.codigo_seccion ||
        !record.codigo_almacen_sap
      ) {
        // Al menos uno de los campos está vacío
        setSnackbar({ children: 'Todos los campos son obligatorios en los articulos', severity: 'error' });
        return;
      }
      if(record.fecha_entrega === fechacontable || record.fecha_entrega+1 > fechacontable){
        setSnackbar({ children: 'La fecha de entrega en los servicios debe ser mayor o igual a la fecha contable en el detalle de servicios', severity: 'error' });
        return;
      }
    }

    Swal.fire({
      title: 'REGISTRO DE SOLICITUD ORDEN DE COMPRA',
      text: "¿ESTA CONFORME CON LO REGISTRADO?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        setCargando(true);
         const datosOrden = {
          id_oc_cabecera: 0,
          id_solicitante: user.id_usuario,
          nombre_solicitante: user.usuario,
          id_proveedor: idprovedor,
          nombre_proveedor: nombreProveedor,
          correo_proveedor: "jgal@mariaalmenara.pe",
          ruc_proveedor: ruc,
          id_proveedor_sap: codigoProveedorsap,
          id_contacto_proveedor: idcontacto,
          codigo_empleado_venta: selectPersonaContacto.codigo_empleado_venta,
          contacto_proveedor: selectPersonaContacto.nombre_empleado_venta,
          fecha_contable: moment(fechacontable).locale('es', MomentLocaleEs),
          fecha_entrega: moment(fechaentrega).locale('es', MomentLocaleEs),
          fecha_documento: moment(fechacontable).locale('es', MomentLocaleEs),
          fecha_registro: moment().locale('es', MomentLocaleEs),
          condic_pago: condiciondepago,
          destino: selectedDestino.nombre_direccion_destino,
          impuesto: Number(resultadoCalculo),
          total_antes_descuento: Number(sumaTotal),
          total_pago_vencido:  Number(totalCalculado),
          observaciones: observaciones,
          tipo: "Articulo",
          motivo_solicitud: motivotraslado,
          moneda: tipocambio,
          tipo_cambio: 1,
          comentario:comment,
          // tipo_documento:tipoDocumento,
          // serie_documento:serieDocumento,
          // correlativo_documento:correlativoDocumento,
          oc_detalles:records.map(datos => datos &&{
            id_oc_detalle: 0,
            id_oc_cabecera: 0,
            fecha_entrega: datos.fecha_entrega,
            descripcion: null,
            numero_cuenta_mayor: null,
            nombre_cuenta_mayor: null,
            total_servicio: 0,
            codigo_cuenta_contable: null,
            codigo_articulo: datos.codigo_producto_sap,
            codigo_unidad_medida_articulo: datos.codigo_unidad_medida_logistica,
            unidad_medida_articulo: datos.nombre_unidad_medida_logistica,
            cantidad_pedida: datos.cantidad_pedida,
            precio_unitario: datos.incluyeIgv?(datos.Valor_unitario*1.18).toFixed(2) : (datos.Valor_unitario).toFixed(2),
            porcentaje_descuento: 0,
            total_articulo: datos.incluyeIgv ? ((datos.Valor_unitario*1.18) * datos.cantidad_pedida).toFixed(2): ((datos.Valor_unitario) * datos.cantidad_pedida).toFixed(2),
            codigo_ceco: datos.codigo_ceco,
            codigo_departamente: datos.codigo_departamente,
            codigo_seccion: datos.codigo_seccion,
            codigo_almacen_sap: datos.codigo_almacen_sap,
            created_at: moment().locale('es', MomentLocaleEs),
            updated_at: moment().locale('es', MomentLocaleEs),
            include_igv:datos.incluyeIgv ? 'IGV' : 'EXONERADO',
            statusline:"0"
          })
         }
         setRegistrarbtn(true);
          OrdenesService.insertarSolPe(datosOrden,setCargando,setSnackbar,user.token).then((res) => { 
           setRecords([]);
           setSelectProveedor(null);
           setRuc('');
           setSelectPersonaContacto(null);
           setCondicionDepago('')
           setMotivotraslado('');
           setObservaciones('');
           setSelectedDestino(null);
           setComment('');
          });
       
      }
    })
  };

  //Nuevo
  const handleComentarioChange = e => {
    const { name, value } = e.target;
    setComment(value)
  }
  const handleObservaciones = e => {
    const { name, value } = e.target;
    setObservaciones(value)
  }

  const handleSerieDocumento = e => {
    const { name, value } = e.target;
    setSerieDocumento(value)
  }
  const handleCorrelativoDocumento = e => {
    const { name, value } = e.target;
    setCorrelativoDocumento(value)
  }

  const handleMotivoTraslado = e => {
    const { name, value } = e.target;
    setMotivotraslado(value)
  }
  const handleContable = (newValue) => {
    setFechacontable(newValue);
  }
  const handleEntrega = (newValue) => {
    setFechaentrega(newValue);
  }
  const handleRegistro = (newValue) => {
    setFecharegistro(newValue);
  }
  const handleChangeTipodeCambio = (event) => {
    setTipocambio(event.target.value);
  };
  const handleAutocompleteChange = (event, value) => {
    setSelectProveedor(value);
    setRuc(value.numero_documento);
    setIdprovedor(value.id_usuario);
    setNombreProvedor(value.razon_social);
    setCodigoProveedorsap(value.id_proveedor_sap);
    setCondicionDepago(value.nombre_condicion_credito);
    setCorreo( value.correo_recepcion!=='' ? value.correo_recepcion : value.correo_logistica !='' ?  value.correo_logistica : value.correo_comercial ? value.correo_comercial  : 'NO HAY CORREO EN SAP');
  };
  const filterOptions = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option.razon_social.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.numero_documento.includes(inputValue)
    );
  };
  const handleAutocompletePersonaContacto = (event, value) => {
    setSelectPersonaContacto(value);
    setIdcontacto(value.codigo_empleado_venta);
  };
  const filterOptionsPersonaContacto = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option.nombre_empleado_venta.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleSelectConditionPago = (event, value) => {
    setSelectedConditionPago(value);
  };
  const filterOptionsDestino = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option.nombre_direccion_destino.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const handleAutocompleteDestino = (event, value) => {
    setSelectedDestino(value);
  };
  const sumaTotal = records.reduce(
    (acumulado, datos) =>
      acumulado + (datos.Valor_unitario ? parseFloat(datos.Valor_unitario):0) * (datos.cantidad_pedida ? parseFloat(datos.cantidad_pedida):0),
    0
  );
  let igvTotal = 0;
  records.forEach(row => {
    if (row.incluyeIgv) {
      igvTotal += row.Valor_unitario *row.cantidad_pedida* 0.18; 
    }
  });
  
  const totalConIgv = sumaTotal+ igvTotal;
  
  const totalCalculado = typeof totalConIgv === 'number'
    ? totalConIgv.toFixed(2)
    : 0;
    const resultadoCalculo = igvTotal.toFixed(2); 


  return (
    <Grid width={'100%'} m="auto">
      <Grid container justifyContent="flex-start" alignItems="center" spacing={2} sx={{ py: 1 }}>
        <Grid container item xs={12} sm={12} >
          <Grid item sm={6} >
            <CMLabel sx={{ margin: 1 }} fontSize='22px' color="black"><b>CREAR SOLICITUD DE ORDEN DE COMPRA</b></CMLabel>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} alignItems="center">
          <Grid item sm={12}>
            <Grid item xs={12} sm={12}>
              <CMLabel sx={{ margin: 1 }} fontSize='20px' color="black"><b>Datos del proveedor</b></CMLabel>
            </Grid>
            <Grid container item xs={12} sm={12} alignItems="center">
              <Grid xs={12} sm={1.5}>
                <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Proveedor</CMLabel>
              </Grid>
              <Grid xs={12} sm={2}>
                <Autocomplete
                  value={selectProveedor}
                  onChange={handleAutocompleteChange}
                  options={proveedor}
                  getOptionLabel={(option) => option.razon_social}
                  filterOptions={filterOptions}
                  renderInput={(params) => <TextField {...params} label="Buscar" />}
                />
              </Grid>
              <Grid xs={12} sm={0.5}>
              </Grid>
              <Grid xs={12} sm={1.5}>
                <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">RUC</CMLabel>
              </Grid>
              <Grid xs={12} sm={2}>
                <CMTextField
                  name="ruc"
                  label='ruc'
                  value={ruc}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid xs={12} sm={0.5}>
              </Grid>
              <Grid xs={12} sm={1.5}>
                <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Persona Contacto</CMLabel>
              </Grid>
              <Grid xs={12} sm={2}>
                <Autocomplete
                  value={selectPersonaContacto}
                  onChange={handleAutocompletePersonaContacto}
                  options={contacto}
                  getOptionLabel={(option) => option.nombre_empleado_venta}
                  filterOptions={filterOptionsPersonaContacto}
                  renderInput={(params) => <TextField {...params} label="Buscar persona contacto" />}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} sx={{paddingTop:2}} alignItems="center">
            <Grid item sx={12} sm={1.5}>
              <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Condición de pago</CMLabel>
            </Grid>
            <Grid item sx={12} sm={2}>
              <CMTextField
                  name="Condición de pago"
                  label='Condición de pago'
                  value={condiciondepago}
                  disabled
                  fullWidth
                />
            </Grid>
              <Grid xs={12} sm={0.5}>
              </Grid> 
            <Grid item sx={12} sm={1.5}>
              <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Correo</CMLabel>
            </Grid>
            <Grid item sx={12} sm={2}>
              <CMTextField
                  name="Correo"
                  label='Correo'
                  value={correo}
                  disabled
                  fullWidth
                />
            </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid item sm={12}>
              <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Datos de la orden</b></CMLabel>
            </Grid>
            <Grid container item sm={12} alignItems="center">
              <Grid item sx={12} sm={1.5}>
                <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Fecha contable</CMLabel>
              </Grid>
              <Grid item sx={12} sm={2}>
                <CMDatePicker
                  label="Fecha contable"
                  value={fechacontable}
                  inputFormat="DD/MM/YYYY"
                  onChange={handleContable}
                  maxDate={fechahoy}
                />
              </Grid>
              <Grid item sx={12} sm={0.5}>
                
              </Grid>
              <Grid item sx={12} sm={1.5}>
                <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Fecha entrega</CMLabel>
              </Grid>
              <Grid item sx={12} sm={2}>
                <CMDatePicker
                  label="Fecha entrega"
                  value={fechaentrega}
                  inputFormat="DD/MM/YYYY"
                  onChange={handleEntrega}
                  maxDate={fechahoy}
                />
              </Grid>
              <Grid item sx={12} sm={0.5}>

              </Grid>
              <Grid item sx={12} sm={1.5}>
              <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Tipo de compra</CMLabel>
            </Grid>
            <Grid item sx={12} sm={2}>
              <CMTextField
                name="Tipo de compra"
                label='Tipo de compra'
                value="Compra Local"
                disabled
                fullWidth
              />
            </Grid>
            
            </Grid>
          </Grid>
          <Grid container item sx={{ paddingTop: 3, paddingBottom: 3 }} alignItems="center">
            <Grid item sx={12} sm={1.5}>
              <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Tipo de moneda</CMLabel>
            </Grid>
            <Grid item sx={12} sm={2}>
              <Select
                labelId="currency-label"
                id="currency"
                value={tipocambio}
                onChange={handleChangeTipodeCambio}
                fullWidth
              >
                <MenuItem value="S/">S/ (Moneda Local)</MenuItem>
                <MenuItem value="US$">US$ (Moneda Extranjera)</MenuItem>
              </Select>
            </Grid>
            <Grid item sx={12} sm={0.5}>

            </Grid>
            <Grid item sx={4} sm={1.5}>
              <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Motivo</CMLabel>
            </Grid>
            <Grid item sx={0.2}>
              <Tooltip title="Motivo control del sistema">
                <InfoIcon sx={{ color: 'primary.main'}}/>
              </Tooltip>
            </Grid>
            <Grid item sx={8} sm={1.8}>
                <CMTextField
                      name="Motivo"
                      label='Motivo'
                      value={motivotraslado}
                      onChange={handleMotivoTraslado}
                      fullWidth
                      inputProps={{
                        maxLength: 300
                      }}
                    />
            </Grid>
            <Grid item sx={12} sm={0.5}>

            </Grid>

            <Grid item sx={12} sm={1.5}>
              <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Resumen</CMLabel>
            </Grid>
            <Grid item sx={12} sm={0.2}>
              <Tooltip title="Resumen de la orden SAP, en formato Resumen-Tienda">
                <InfoIcon sx={{ color: 'primary.main'}}/>
              </Tooltip>
            </Grid>
            <Grid item sx={12} sm={1.8}>
              <CMTextField
                name="Resumen"
                label='Resumen'
                value={observaciones}
                onChange={handleObservaciones}
                fullWidth
                inputProps={{
                  maxLength: 500
                }}
              />
            </Grid>
          </Grid>
          <Grid container item sx={{ paddingTop: 0, paddingBottom: 3 }} alignItems="center">
            <Grid item sx={12} sm={1.5}>
              <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Destino</CMLabel>
            </Grid>
            <Grid item sx={12} sm={2}>
               <Autocomplete
                  value={selectedDestino}
                  onChange={handleAutocompleteDestino}
                  options={destino}
                  getOptionLabel={(option) => option.nombre_direccion_destino}
                  filterOptions={filterOptionsDestino}
                  renderInput={(params) => <TextField {...params} label="Buscar destino" />}
                />
            </Grid>
            <Grid item sx={12} sm={0.5}>
            </Grid>
            
          </Grid>
          {/* <Grid container item sx={{ paddingTop: 0, paddingBottom: 3 }} alignItems="center">
            <Grid item sx={12} sm={1.5}>
              <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black">Tipo Documento</CMLabel>
            </Grid>
            <Grid item sx={12} sm={2}>
                <CMTextField
                      name="Tipo del Documento"
                      label='Tipo del Documento'
                      value={tipoDocumento}
                      disabled
                      fullWidth
                    />
            </Grid>
              <Grid item sx={12} sm={0.5}>

              </Grid>
              <Grid item sx={4} sm={1.5}>
                <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black">Serie Documento</CMLabel>
              </Grid>
              <Grid item sx={8} sm={2}>
                  <CMTextField
                        name="Serie del Documento"
                        label='Serie del Documento'
                        value={serieDocumento}
                        onChange={handleSerieDocumento}
                        fullWidth
                        inputProps={{
                          maxLength: 4
                        }}
                      />
              </Grid>
              <Grid item sx={12} sm={0.5}>

              </Grid>
              <Grid item sx={4} sm={1.5}>
                <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black">Correlativo Documento</CMLabel>
              </Grid>
              <Grid item sx={8} sm={2}>
                  <CMTextField
                        name="Correlativo del Documento"
                        label='Correlativo del Documento'
                        value={correlativoDocumento}
                        onChange={handleCorrelativoDocumento}
                        fullWidth
                        inputProps={{
                          maxLength: 8
                        }}
                      />
              </Grid>
          </Grid> */}
          <Grid container item sx={{ paddingTop: 0, paddingBottom: 3 }} alignItems="center">
            <Grid item sm={12}>
              <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Datos de los Artículos</b></CMLabel>
            </Grid>
            <Grid container sm={12} alignItems="center">
                <Grid item sx={12} sm={1.5} >
                  <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Buscar Producto</CMLabel>
                </Grid>
                <Grid item sx={12} sm={4} >
                <CMAutocomplete 
						open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => `${option.name}` === `${value.nombre_producto_sap} - ${value.codigo_producto_sap}`}
						getOptionLabel={(option) => `${option.nombre_producto_sap} - ${option.codigo_producto_sap}`}
						options={options}
            loading={loading}
						onChange={(event, value) => handleProductChange(value)}
						inputValue={valueProduct}
						onInputChange={(event, newInputValue) => {
							setValueProduct(newInputValue);
						}}
						label="Artículo"
						fullWidth
					/>
                </Grid>
                <Grid item sx={12} sm={0.2}>

                </Grid>
                <Grid item sx={12} sm={2} >
                    <CMButton
                    label="Insertar artículo"
                    variant="contained"
                    onClick={(e) => agregarArticulo()}
                  />
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
          <Grid style={{ height: 400, width: '100%', overflowX: 'auto' }} sx={{ py: 0 }}>
            <DataGrid
              rows={records ? records : []}
              columns={columns}
              sx={{
                height: "100%",
                fontSize: 12,
                "& .MuiDataGrid-window": {
                  overflowX: 'scroll',
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                },
              }}
              pagination
              rowHeight={50}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              experimentalFeatures={{ newEditingApi: true }}
              loading={cargando}

            />
            {!!snackbar && (
              <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
              >
                <Alert {...snackbar} onClose={handleCloseSnackbar} />
              </Snackbar>
            )}
          </Grid>
          <Grid item xs={12} sm={12} container>
            <Grid item xs={12} sm={6} container alignItems="center">
              <Grid item xs={6} sm={3}>
                {/* <CMLabel fontSize='16px' color='black'>Comentario: </CMLabel> */}
              </Grid>
              <Grid item xs={6} sm={6}>
                {/* <CMTextField
                  name="comment"
                  label='Comentario'
                  value={comment}
                  onChange={handleComentarioChange}
                  fullWidth
                /> */}
              </Grid>
            </Grid>
            <Grid item container>
                <Grid item container xs={12} sm={10}>

                </Grid>
                <Grid item container xs={12} sm={2} justifyContent="flex-end">
                  <Grid item xs={6} sm={6}>
                    <CMLabel sx={{ margin: 2 }} fontSize='16px' color="black">Subtotal</CMLabel>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                  
                    <CMLabel sx={{ margin: 2 }} fontSize='16px' color="black">
                    {tipocambio==='S/' ? tipocambio : '$'}{sumaTotal ? sumaTotal: 0}
                    </CMLabel>

                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={10}>

                </Grid>
                <Grid item container xs={12} sm={2} justifyContent="flex-end">
                  <Grid item xs={6} sm={6}>
                    <CMLabel sx={{ margin: 2 }} fontSize='16px' color="black">IGV</CMLabel>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                  
                    <CMLabel sx={{ margin: 2 }} fontSize='16px' color="black">
                    {tipocambio==='S/' ? tipocambio : '$'}{resultadoCalculo ? resultadoCalculo : 0}
                    </CMLabel>

                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={10}>

                </Grid>
                <Grid item container xs={12} sm={2} justifyContent="flex-end">
                  <Grid item xs={6} sm={6}>
                    <CMLabel sx={{ margin: 2 }} fontSize='16px' color="black">Total</CMLabel>
                  </Grid>
                  <Grid item xs={6} sm={6}> 
                  
                    <CMLabel sx={{ margin: 2 }} fontSize='16px' color="black">
                    {tipocambio==='S/' ? tipocambio : '$'}{totalCalculado ? totalCalculado : 0}
                    </CMLabel>

                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} container justifyContent="flex-end">
              <CMButton
                label="Registrar"
                variant="contained"
                sx={{ marginTop: '10px' }}
                onClick={hanbleRegistrar}
                disabled={registrarbtn}
              />
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CrearOrdenesCompra
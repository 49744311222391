import React, { useState,useEffect,useCallback} from "react";
import moment from 'moment';


import { Grid ,Typography,InputLabel, MenuItem, Select} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
  } from '@mui/x-data-grid';
import { Icon } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { useParams, useHistory } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//Components
import CMLabel from '../../components/atoms/CMLabel';
import CMDatePicker from '../../components/molecules/CMDatePicker';
import CMButton from '../../components/atoms/CMButton';

//Constants
import {MomentLocaleEs} from '../../constants/MomentLocaleEs';
//Services
import * as OrdenesService from '../../services/OrdenesService';
import * as SolicitanteService from '../../services/SolicitanteService';


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CMTextField from "../../components/atoms/CMTextField";
import CMIconButton from '../../components/atoms/CMIconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Checkbox from '@mui/material/Checkbox';

const MySwal = withReactContent(Swal)


const CrearNotaEntregaVer = () => {
    const history = useHistory();

    const [rowsdata, setRowsdata] = useState([]);
    const [rowsgrid, setRowsgrid] = useState([]);
    const [comment,setComment] = useState('');
    const [selectedOption, setSelectedOption] = React.useState('');
	const [cargando, setCargando] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [enviar,setEnviar] = useState('');

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
      };
    const user = JSON.parse(window.localStorage.getItem("user"));
    const useFakeMutation = () => {
      return React.useCallback(
        (user) =>
          new Promise((resolve, reject) =>
            setTimeout(() => {
              if (user.name?.trim() === '') {
                reject(new Error("Error al guardar el usuario: el nombre no puede estar vacÃ­o"));
              } else {
                resolve({ ...user, name: user.name?.toUpperCase() });
              }
            }, 200),
          ),
        [],
      );
    };
    const mutateRow = useFakeMutation();
    const [snackbar, setSnackbar] = useState(null);
    const [dataresponse,setDataresponse] = useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const handleProcessRowUpdateError = React.useCallback((error) => {
      setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const { ordenid } = useParams();
    useEffect(() => {
        OrdenesService.getOCIDCREARNE(setRowsdata,ordenid,user.token);
    }, []);
    let idCounter = 0; // Inicializamos el contador
  useEffect(() => {
      if(cargando===false && enviar==='Se registro correctamente'){
        history.push(`/crearnotaentrega/${ordenid}`);
         history.go(0);
        // OrdenesService.getOCIDCREARNE(setRowsdata,ordenid,user.token);
        
        // if(rowsdata.oc_detalles.length ===0){
        //   setIsDisabled(true);
        // }
      // Swal.fire({
      //   title: 'Se registró correctamente!',
      //   icon: 'success',
      //   showCancelButton: false,
      //   confirmButtonText: 'OK',
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //       history.push(`/crearnotaentrega`);
      //       history.go(0);
      //   }
      // });
    }
  }, [cargando,enviar]);

    useEffect(() => {
        if (rowsdata.oc_detalles) {
            const rowsWithId = rowsdata.oc_detalles.map(row => ({
                ...row,
                id: row.id || idCounter++
            }));
            setRowsgrid(rowsWithId);
           
          
        }
    }, [rowsdata]);

    useEffect(() => {
      if (rowsgrid.length>=1) {
        const updatedOCDetalles = rowsgrid.map(row => ({
          ...row,

          statusline: row.statusline2? "1" : "0",
        }));
    
        setRowsdata(prevData => ({
          ...prevData,
          oc_detalles: updatedOCDetalles,
        }));
      }
    }, [rowsgrid]);

 
    const formatCurrency = (value, moneda) => {
        let currency = 'PEN';
      
        if (moneda === 'S/') {
            currency = 'PEN';
          }else  if (moneda === 'US$') {
          currency = 'USD';
        }
      
        return value.toLocaleString('es-PE', {
          style: 'currency',
          currency: currency,
        });
      };

 
      const handleCheckboxChange = (params, checked) => {
        // Actualizar el valor de incluyeIgv en los datos de esa fila
        const updatedRow = {...params.row, statusline2: checked ? true : false};
        
        setRowsgrid(rows => 
          rows.map(row => 
            row.id === params.id ? updatedRow : row  
          )
        );
        isDisabled(false);
      }

   
      const columns = [
        {
          field: 'linea',
          headerName: 'Enviar Linea?', 
          renderCell: (params) => {
            return (
              <Checkbox
                checked={params.value} 
                onChange={(e) => handleCheckboxChange(params, e.target.checked)}  
              />
            )
          }
        },
        { field: 'codigo_articulo', headerName: 'Codigo Árticulo', width: 120, align: 'center',headerAlign: 'center' },
        { field: 'nombre_articulo', headerName: 'Árticulo', width: 480,headerAlign: 'center' },
        { field: 'unidad_medida_articulo', headerName: 'Unidad Medida', width: 180, align: 'center',headerAlign: 'center' },
        { field: 'cantidad_pedida', headerName: 'Cantidad Pedida', width: 150, align: 'center',headerAlign: 'center' },
            { 
            field: 'fecha_entrega', 
            headerName: 'Fecha Acuerdo', 
            type:'date',
            width: 140, 
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY') 
          },
          { field: 'fecha_recepcion', headerName: 'Fecha Recepción', width: 180, align: 'center',headerAlign: 'center',
          valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000'
        },
          {
            field: 'tipo_igv',
            headerName: 'Tipo IGV',
            width: 180,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => {
              const valorSinIGV = params.row.include_igv==='EXONERADO' ?
               'EXONERADO' :
               params.row.include_igv==='IGV' ? 'IGV 18%': 'IGV 10%';
              return valorSinIGV;
            },
          },
          {
            field: 'valor_sin_igv',
            headerName: 'Precio sin IGV',
            width: 180,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => formatCurrency(params.value, rowsdata.moneda),
            valueGetter: (params) => {
              const totalServicio = params.row.precio_unitario;
              const valorSinIGV = params.row.include_igv==='EXONERADO' ? totalServicio :
              params.row.include_igv==='IGV' ? totalServicio / (1.18) :
              totalServicio / (1.10);
              return valorSinIGV;
            },
          },
          { field: 'precio_unitario', headerName: 'Precio Unitario', width: 150, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda) },
          {
            field: 'igv',
            headerName: 'IGV',
            width: 180,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => formatCurrency(params.value, rowsdata.moneda),
            valueGetter: (params) => {
              const totalServicio = params.row.total_articulo;
              const valorSinIGV = params.row.include_igv==='EXONERADO' ? 0 : 
              params.row.include_igv==='IGV' ? totalServicio - totalServicio / (1.18):
              totalServicio - totalServicio / (1.10);
              return valorSinIGV;
            },
          },
          { field: 'total_articulo', headerName: 'Total Árticulo', width: 150, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda) },
  
          { field: 'codigo_ceco', headerName: 'Codigo Ceco', width: 120, align: 'center',headerAlign: 'center'},
          { field: 'codigo_seccion', headerName: 'Codigo Sección', width: 120, align: 'center',headerAlign: 'center'},
          { field: 'codigo_departamente', headerName: 'Codigo departamento', width: 170, align: 'center',headerAlign: 'center'},
          { field: 'codigo_almacen_sap', headerName: 'Codigo Almacen', width: 170, align: 'center',headerAlign: 'center'},
          { field: 'codigo_ceco_concat', headerName: 'Ceco', width: 480, align: 'center',headerAlign: 'center'},
  
      ];
      const columns2 = [
        {
          field: 'statusline2',
          headerName: 'Enviar Linea?', 
          renderCell: (params) => {
            return (
              <Checkbox
                checked={params.value} 
                onChange={(e) => handleCheckboxChange(params, e.target.checked)}  
              />
            )
          }
        },
        { field: 'descripcion', headerName: 'Descripción', width: 650, align: 'center',headerAlign: 'center'},
        { field: 'fecha_entrega', headerName: 'Fecha Entrega', width: 180, align: 'center',headerAlign: 'center',
        valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000'
      },
      { field: 'fecha_recepcion', headerName: 'Fecha Recepción', width: 180, align: 'center',headerAlign: 'center',
      valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000'
    },
      
      { field: 'codigo_ceco', headerName: 'Codigo Ceco', width: 120, align: 'center',headerAlign: 'center'},
      { field: 'codigo_seccion', headerName: 'Codigo Sección', width: 120, align: 'center',headerAlign: 'center'},
      { field: 'codigo_departamente', headerName: 'Codigo departamento', width: 170, align: 'center',headerAlign: 'center'},
      { field: 'codigo_ceco_concat', headerName: 'Ceco', width: 480, align: 'center',headerAlign: 'center'}, 
      { field: 'nombre_cuenta_mayor', headerName: 'Nombre Cuenta Mayor', width: 360, align: 'center',headerAlign: 'center'},
        { field: 'numero_cuenta_mayor', headerName: 'Numero Cuenta Mayor', width: 180, align: 'center',headerAlign: 'center'},
        {
          field: 'tipo_igv',
          headerName: 'Tipo IGV',
          width: 180,
          align: 'center',
          headerAlign: 'center',
          valueGetter: (params) => {
            const valorSinIGV = params.row.include_igv==='EXONERADO' ?
             'EXONERADO' :
             params.row.include_igv==='IGV' ? 'IGV 18%': 'IGV 10%';
            return valorSinIGV;
          },
        },
        {
          field: 'valor_sin_igv',
          headerName: 'Precio sin IGV',
          width: 180,
          align: 'center',
          headerAlign: 'center',
          valueFormatter: (params) => formatCurrency(params.value, rowsdata.moneda),
          valueGetter: (params) => {
            const totalServicio = params.row.total_servicio;
            const valorSinIGV = params.row.include_igv==='EXONERADO' ? totalServicio :
            params.row.include_igv==='IGV' ? totalServicio / (1.18) : totalServicio / (1.10);
            return valorSinIGV;
          },
        },
        {
          field: 'igv',
          headerName: 'IGV',
          width: 180,
          align: 'center',
          headerAlign: 'center',
          valueFormatter: (params) => formatCurrency(params.value, rowsdata.moneda),
          valueGetter: (params) => {
            const totalServicio = params.row.total_servicio;
            const valorSinIGV = params.row.include_igv==='EXONERADO' ?  0 :
            params.row.include_igv==='IGV' ? totalServicio - totalServicio / (1.18) : 
            totalServicio - totalServicio / (1.10);
            return valorSinIGV;
          },
        },
        { field: 'total_servicio', headerName: 'Precio', width: 180, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda) },
  

  
      ]
      const processRowUpdate = useCallback(
        async (newRow) => {
          if (!newRow || typeof newRow !== 'object') {
            setSnackbar({ children: 'Error: newRow no es válido', severity: 'error' });
            return;
          }
      
          const {
            id,
            descripcion,
            Valor_unitario,
            igv,
            precio_unitario,
            fecha_entrega,
            nombre_ceco,
            codigo_departamente,
            codigo_seccion,
            nombre_cuenta,
            codigo_cuenta_contable,nombre_cuenta_contable,
            incluyeIgv,statusline2,include_igv
          } = newRow;
      
      
          const updatedRecords = rowsdata.map((record) => {
            if (record.id === id) {
              return {
                ...record,
                descripcion,
                Valor_unitario,
                igv,
                precio_unitario,
                fecha_entrega,
                nombre_ceco,
                codigo_departamente,
                codigo_seccion,
                nombre_cuenta,
                codigo_cuenta_contable,nombre_cuenta_contable,incluyeIgv,statusline2,include_igv
              };
            }
            return record;
          });
      
          setRowsdata(updatedRecords);
      
          setSnackbar({ children: 'Guardado exitosamente', severity: 'success' });
      
          const updatedRow = { ...newRow };
          const response = await mutateRow(updatedRow);
          setDataresponse(response);
          return response;
        },
        [rowsdata, mutateRow]
      );
      // useEffect(() => {
      //   if(dataresponse) {
      //       const {id_oc_detalle,codigo_articulo,unidad_medida,cantidad_pedida,cantidad_entregada,cantidad_recibida,fecha_entrega,fecha_recepcion,fecha_termino,precio_unitario,total_articulo,include_igv,statusline} = dataresponse;
      //       if(!cantidad_recibida){
      //         alert('Debe ingresar la cantidad recibida');        
      //       }
      //       if(!fecha_recepcion){
      //         alert('Debe una fecha de entrega'); 
      //       }
            
      //       const oldDevices = [...rowsgrid];
      //       const rowDeviceIndex = oldDevices.findIndex((dev) => dev.id_oc_detalle === id_oc_detalle);
      //       oldDevices[rowDeviceIndex] = {
      //         ...oldDevices[rowDeviceIndex],
      //         id_oc_detalle,codigo_articulo,unidad_medida,cantidad_pedida,cantidad_entregada,cantidad_recibida,fecha_entrega,fecha_recepcion,fecha_termino,precio_unitario,total_articulo,include_igv, statusline 
      //       };
      //       setRowsgrid(oldDevices);
      //   }
      // }, [dataresponse])

      const hanbleEntregada = () => {
        Swal.fire({
          title: '¿Estado conforme en generar la Nota de Entrega?',
          text: "",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'
          }).then((result) => {
          if (result.isConfirmed) {
            setCargando(true);
            setIsDisabled(true);
            const ocDetallesFiltrados = rowsdata.oc_detalles.filter(detalle => detalle.statusline === "1");
            const today = moment(); // Obtener el objeto Moment de la fecha de hoy
            const ocDataFiltrado = {
              ...rowsdata,
              fecha_contable: today.format(), // Formatear la fecha de hoy con Moment
              fecha_documento: today.format(),
              fecha_entrega: today.format(),
              fecha_registro: today.format(),
              oc_detalles: ocDetallesFiltrados
            };
            // console.log(ocDataFiltrado);
               SolicitanteService.CrearNotaEntrega(ocDataFiltrado,setCargando,setIsDisabled,setSnackbar,setEnviar,ordenid, user.token);
          }
        })
      };
      const handleComentarioChange = e => {
        const {name, value} = e.target;
        setComment(value)
      }
      const handleClickRegresar = () => {
        history.push(`/crearnotaentrega`);
        history.go(0);
      }
 return (
    <Grid width={'100%'} m="auto">
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2} sx={{py:1}}>
        <Grid container item xs={12} sm={12} >
                <Grid item sm={6}>
                    <CMLabel sx={{ margin: 2 }} fontSize='28px' color="black"><b>Orden de {rowsdata.tipo==='Articulo' ? 'Compra' : 'Servicio'} </b></CMLabel>
                </Grid>
                <Grid item sm={6} container justifyContent="flex-end" alignItems="center" >
                  <Grid container xs={2} justifyContent="flex-end" alignItems="center" >
                        <Grid item justifyContent="flex-end" alignItems="center" >
                          <CMIconButton disableRipple={true} size="large" sx={{color: "primary.main", display: "block"}} onClick={handleClickRegresar}>
                            <ArrowBackIcon/>
                            <Typography sx={{lineHeight: 0}}>Regresar</Typography>
                          </CMIconButton>
                        </Grid>
                  </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Proveedor</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Nombre :</b> {rowsdata.nombre_proveedor}</CMLabel>
                    </Grid>
                </Grid>
                <Grid item sm={1}>
                   
                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Solicitante</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Nombre :</b> {rowsdata.nombre_solicitante}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Destino :</b> {rowsdata.destino}</CMLabel>
                    </Grid>
                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Detalle Orden de {rowsdata.tipo==='Articulo' ? 'Compra' : 'Servicio'}</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>OC Id :</b> {rowsdata.id_documento_borrador===null || rowsdata.id_documento_borrador===''  ? '000' +rowsdata.id_oc_cabecera : rowsdata.id_documento_borrador}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Numero Documento Orden :</b> {rowsdata.numero_documento_orden}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Estado :</b> {rowsdata.estado}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>OC Estado :</b> {rowsdata.ultimo_nombre_oc_estado}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Motivo :</b> {rowsdata.motivo_solicitud}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Observación :</b> {rowsdata.observaciones}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Condición de Pago :</b> {rowsdata.condic_pago}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Fecha Contable :</b> {moment(rowsdata.fecha_contable).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Fecha Entrega :</b> {moment(rowsdata.fecha_entrega).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Fecha Registro :</b> {moment(rowsdata.fecha_registro).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>OC Actualización :</b> {moment(rowsdata.ultima_fecha_modificacion).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                </Grid>
                <Grid item sm={1}>
                   
                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Importe</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Subtotal :</b> {rowsdata.total_antes_descuento ? formatCurrency(rowsdata.total_antes_descuento,rowsdata.moneda) : formatCurrency(0,rowsdata.moneda)}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Impuesto :</b> { rowsdata.impuesto ? formatCurrency(rowsdata.impuesto,rowsdata.moneda) : formatCurrency(0,rowsdata.moneda)}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Total :</b> {rowsdata.total_pago_vencido ? formatCurrency(rowsdata.total_pago_vencido,rowsdata.moneda) : formatCurrency(0,rowsdata.moneda)}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                      <br></br>
                    {rowsgrid.length ===0 && <CMLabel fontSize='20px' color='black'>
                    <b>Observación:</b><hr></hr>
                      <b>No hay lineas pendientes</b>
                      </CMLabel>}
                    </Grid>
                </Grid>
               
        </Grid>
        <Grid container item xs={12} sm={12}>
  <Grid style={{ height: 400, width: '100%', overflowX: 'auto' }} sx={{ py: 0 }}>
  {rowsdata.tipo==='Articulo' ?
    <DataGrid
      rows={rowsgrid}
      columns={columns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      sx={{
        height: "100%",
        "& .MuiDataGrid-window": {
          overflowX: 'scroll',
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        },
      }}
      pagination
      rowHeight={50}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
      experimentalFeatures={{ newEditingApi: true }}
    />
     :  <DataGrid
     rows={rowsgrid}
     columns={columns2}
     pageSize={15}
     rowsPerPageOptions={[15]}
     sx={{
       height: "100%",
       "& .MuiDataGrid-window": {
         overflowX: 'scroll',
         "&::-webkit-scrollbar": {
           width: "0.4em",
         },
         "&::-webkit-scrollbar-track": {
           background: "#f1f1f1",
         },
         "&::-webkit-scrollbar-thumb": {
           backgroundColor: "#888",
         },
         "&::-webkit-scrollbar-thumb:hover": {
           background: "#555",
         },
       },
     }}
     pagination
     rowHeight={50}
     processRowUpdate={processRowUpdate}
     onProcessRowUpdateError={handleProcessRowUpdateError}
     experimentalFeatures={{ newEditingApi: true }}
     loading={cargando}

   />}
     {!!snackbar && (
                    <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
  </Grid>
  <Grid item xs={12} sm={12} container>
    <Grid item xs={12} sm={8} container alignItems="center">
        <Grid item xs={6} sm={1.5}>
					<CMLabel fontSize='16px'color='black'>Comentario: </CMLabel>
				</Grid>
				<Grid item xs={6} sm={6}>
					<CMTextField
						name="comment"
						label='Comentario'
						value={comment}
						onChange={handleComentarioChange}
						fullWidth
					/>
				</Grid>
    </Grid>
    <Grid item xs={12} sm={4} container justifyContent="flex-end" alignItems="center">
      <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
      {rowsgrid.length ===0  ? <CMButton
            label="Registrar"
            variant="contained"
            sx={{ marginTop: '10px' }}
            onClick={hanbleEntregada}
             disabled={true}
            /> : <CMButton
            label="Registrar"
            variant="contained"
            sx={{ marginTop: '10px' }}
            onClick={hanbleEntregada}
             disabled={isDisabled}
            /> 
            }
      </Grid>
    
    </Grid>
    
  </Grid>
</Grid>
    </Grid>
    </Grid>
 )
}

export default CrearNotaEntregaVer
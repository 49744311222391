import React from "react";
import { Drawer } from '@mui/material';
import { useHistory, useLocation } from "react-router";
import drawer from '../../constants/DrawerItems';
import CMListNav from './CMListNav';

const drawerWidth = 240;

const CMDrawer = (props) => {
  const location = useLocation();

  return location.pathname==="/login" ? null : (
    <div>
      <Drawer
        container={props.container}
        variant="temporary"
        open={props.open}
        onClose={props.onClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', xl: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <CMListNav/>
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', xl: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {/* <CMListNav/> Generaba el no direccionamiento*/}  
      </Drawer>
    </div>
  );
};


export default CMDrawer;
import React, {createContext, useState} from "react";

export const UserContext = createContext({
    
});

export const UserProvider= (props)=>{
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) || {});
    
    const setUserAndLocal = (user) => {
      localStorage.setItem("user", JSON.stringify(user));
      setUser(user);
    }
  
    return(
      <UserContext.Provider value={{user,setUser:setUserAndLocal}}>
        {
          props.children
        }
      </UserContext.Provider>
    )
  
  }
import React from "react";
import { TextField } from "@mui/material";
import './css/CMTextField.css'
const CMTextField = (props) => {
    const { style,color, error, ...otherProps } = props;

    return (
        <TextField
        variant="filled"
        {...otherProps} // Pasamos los demás props al componente TextField
        {...(error && { error: true, helperText: error })}
        className="cmTextField"
        sx={{fontSize:12}}
        color={color}
      />
    );
};


export default CMTextField;
import React, { useState, useEffect, useCallback } from "react";
import moment from 'moment';


import { Grid, Typography, MenuItem, Select, Autocomplete, TextField, InputLabel } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

import {
  DataGrid,
  GridEditCellProps
} from '@mui/x-data-grid';
import CMAutocomplete from '../../components/molecules/CMAutocomplete';

import { Icon } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { useParams, useHistory } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//Components
import CMLabel from '../../components/atoms/CMLabel';
import CMDatePicker from '../../components/molecules/CMDatePicker';
import CMButton from '../../components/atoms/CMButton';
import CMIconButton from '../../components/atoms/CMIconButton';
import DeleteIcon from '@mui/icons-material/Delete';

//Constants
import { MomentLocaleEs } from '../../constants/MomentLocaleEs';
//Services
import * as OrdenesService from '../../services/OrdenesService';
import * as ProveedorService from '../../services/ProveedorService';


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CMTextField from "../../components/atoms/CMTextField";
import { v4 as uuidv4 } from 'uuid';

import './CrearOrdenes.css'
const MySwal = withReactContent(Swal)


const CopiarCrearOrdenesServicio = () => {
  const [rowsdata, setRowsdata] = useState([]);
  const [rowsgrid, setRowsgrid] = useState([]);
  const [comment, setComment] = useState('');
  //Nuevo
  const [proveedor, setProveedor] = useState([]);
  const [ruc, setRuc] = useState('');
  const [idprovedor, setIdprovedor] = useState([]);
  const [nombreProveedor, setNombreProvedor] = useState([]);
  const [codigoProveedorsap, setCodigoProveedorsap] = useState([]);
  const [contacto, setContacto] = useState([]);
  const [selectPersonaContacto, setSelectPersonaContacto] = useState(null);
  const [fechacontable, setFechacontable] = useState(moment().locale('es', MomentLocaleEs));
  const [fechaentrega, setFechaentrega] = useState(moment().add('day',1).locale('es', MomentLocaleEs));
  const [fecharegistro, setFecharegistro] = useState([]);
  const [motivotraslado, setMotivotraslado] = useState('');
  const [selectProveedor, setSelectProveedor] = useState(null);
  const [tipocambio, setTipocambio] = useState('S/');
  const [idcontacto,setIdcontacto] = useState([]);
  const [condiciondepago, setCondicionDepago] = useState([]);
  const [destino, setDestino] = useState('');
  const [observaciones, setObservaciones] = useState('');
  const [selectedConditionPago, setSelectedConditionPago] = useState(null); // Estado para almacenar la condición seleccionada
  const [selectedDestino, setSelectedDestino] = useState(null); // Estado para almacenar la condición seleccionada
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cecos, setCecos] = useState([]);
  const [almacenes, setAlmacenes] = useState([]);
  const [cuentaContable, setCuentaContable] = useState([]);
  const [editedCecos, setEditedCecos] = useState([]);
  const [editedAlmacenes,setEditedAlmacenes] = useState([]);
  const [editedCuentaContable, setEditedCuentaContable] = useState([]); // Inicializar con un arreglo vacío
	const [cargando, setCargando] = React.useState(false);

  const [optionDestino, setOptionDestino] =useState([]);

  const [records, setRecords] = useState([]);
  const [products, setProducts] = useState(null);
  const [valueProduct, setValueProduct] = useState('');
  const [selectionProduct, setSelectionProduct] = useState(null);
  const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [loadingGrid, setLoadingGrid] =  useState(false);
  const loading = open && options.length === 0;
  const [notify, setNotify] = useState({isOpen: false, message: '', type: ''});
  const [nextId, setNextId] = useState(1); // Variable para generar claves únicas
  const user = JSON.parse(window.localStorage.getItem("user"));
  const [registrarbtn, setRegistrarbtn] = useState(true);
  const { ordenid } = useParams();

  useEffect(() => {
    OrdenesService.getOCID(setRowsdata,ordenid,user.token);
}, []);


  useEffect(() => {
    OrdenesService.getOCID(setRowsdata, ordenid, user.token);
    OrdenesService.getListProveedores(setProveedor, user.token);
    OrdenesService.getListPersonaContacto(setContacto, user.token);
   OrdenesService.getListDestino(setDestino, user.token);
    OrdenesService.getListProducts(setProducts, user.token);
    OrdenesService.getListCecos(setCecos, user.token);
    OrdenesService.getListAlmacen(setAlmacenes, user.token);
    OrdenesService.getListCuentaContable(setCuentaContable, user.token);
  }, []);

  useEffect(() => {
    if(rowsdata){
      setRuc(rowsdata.ruc_proveedor);
      setFechacontable(rowsdata.fecha_contable);
      setFechaentrega(rowsdata.fecha_entrega);
      setFecharegistro(rowsdata.fecha_registro);
      setCondicionDepago(rowsdata.condic_pago);
      setIdprovedor(rowsdata.id_proveedor);
      setNombreProvedor(rowsdata.nombre_proveedor);
      setCodigoProveedorsap(rowsdata.id_proveedor_sap);
      setSelectProveedor({codigo_proveedor_sap:rowsdata.id_proveedor_sap,numero_documento:rowsdata.ruc_proveedor,razon_social:rowsdata.nombre_proveedor,id_proveedor_sap:rowsdata.id_proveedor_sap})
      setSelectPersonaContacto({codigo_empleado_venta:rowsdata.codigo_empleado_venta,nombre_empleado_venta:rowsdata.contacto_proveedor});
      setSelectedDestino({nombre_direccion_destino:rowsdata.destino})
      setIdcontacto(rowsdata.codigo_empleado_venta);
      setMotivotraslado(rowsdata.motivo_solicitud);
      setObservaciones(rowsdata.observaciones);
    }
  }, [rowsdata])

  useEffect(() => {
      if (rowsdata.oc_detalles) {
          const rowsWithId = rowsdata.oc_detalles.map(row => ({
              ...row,
              id: idCounter++
          }));
          setRecords(rowsWithId);
      }
  }, [rowsdata]);

  useEffect(() => {
    let active = true;

    if (!loading)
      return undefined;

    (async () => {
      if (active)
        setOptions([...products]);
    })();

    return () => { active = false; };
  }, [loading]);

  useEffect(() => {
    if (!open)
      setOptions([]);
  }, [open])


  const message = (text, type) => {
    setNotify({
      isOpen: true,
      message: text,
      type: type
    })
  }

  // const handleAddProduct = () => {
  //   if (selectedProduct && !isProductAlreadyAdded(selectedProduct)) {
  //     const newProduct = {
  //       ...selectedProduct,
  //       id: nextId, // Asignar una clave única al producto
  //       precio_unitario: 0, // Valor inicial para el campo editable "Precio Unitario"
  //       cantidad_pedida: 0, // Valor inicial para el campo editable "Cantidad pedida"
  //       total_articulo:0, // Valor inicial para el campo editable "Total
  //       fecha_entrega: null, // Valor inicial para el campo editable "Fecha Entrega"
  //       codigo_ceco: null,
  //       codigo_departamento: null,
  //       codigo_seccion:null,
  //       codigo_almacen_sap:null
  //     };
  //     setSelectedProducts([...selectedProducts, newProduct]);
  //     setSelectedProduct(null);
  //     setNextId(nextId + 1); // Incrementar el valor de la próxima clave única
  //   }
  // };
 
  const isProductAlreadyAdded = (product) => {
    return selectedProducts.some((p) => p.codigo_producto_sap === product.codigo_producto_sap);
  };

  //Fin nuevo

  const useFakeMutation = () => {
    return React.useCallback(
      (user) =>
        new Promise((resolve, reject) =>
          setTimeout(() => {
            if (user.name?.trim() === '') {
              reject(new Error("Error al guardar el usuario: el nombre no puede estar vacÃ­o"));
            } else {
              resolve({ ...user, name: user.name?.toUpperCase() });
            }
          }, 200),
        ),
      [],
    );
  };
  const mutateRow = useFakeMutation();
  const [snackbar, setSnackbar] = useState(null);
  const [dataresponse, setDataresponse] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);


  let idCounter = 0; // Inicializamos el contador

  // useEffect(() => {
  //   if (rowsdata.oc_detalles) {
  //     const rowsWithId = rowsdata.oc_detalles.map(row => ({
  //       ...row,
  //       id: idCounter++
  //     }));
  //     setRowsgrid(rowsWithId);
  //   }
  // }, [rowsdata]);


  const formatCurrency = (value) => {
    return value.toLocaleString('es-PE', {
      style: 'currency',
      currency: 'PEN',
    });
  };



  const formatNumberWithDot = (number) => {
    return number.toString().replace(',', '.');
  };

  const parseNumberWithDot = (value) => {
    return value.replace('.', ',');
  };

  
  const handleCellValueChange = async (params, newValue,codigo_ceco) => {
    const { id } = params;
    const editedCecoIndex = editedCecos.findIndex((ceco) => ceco.id === id);
  
    if (editedCecoIndex !== -1) {
      // Si el ceco ya ha sido editado previamente, actualiza su valor
      const updatedCecos = [...editedCecos];
      updatedCecos[editedCecoIndex] = { id, nombre_ceco: newValue, codigo_ceco};
      setEditedCecos(updatedCecos);
    } else {
      // Si es un ceco nuevo, añádelo al estado de editedCecos
      setEditedCecos((prevCecos) => [...prevCecos, { id, nombre_ceco: newValue, codigo_ceco }]);
    }
  
    try {
      // Obtener los valores de codigo_departamente, codigo_seccion y codigo_ceco del API getCecosId
      const response = await OrdenesService.getCecosId(codigo_ceco, user.token);
      if (response) {
        const { codigo_departamente, codigo_seccion } = response;
  
        // Actualizar los campos relacionados en la fila actual
        const updatedRecords = records.map((record) => {
          if (record.id === id) {
            return { ...record, nombre_ceco: newValue, codigo_departamente, codigo_seccion, codigo_ceco };
          }
          return record;
        });
        setRecords(updatedRecords);
      } else {
        console.error('La respuesta del API getCecosId no es válida:', response);
      }
    } catch (error) {
      // Manejar el error aquí
      console.error('Error al obtener los valores de codigo_departamente y codigo_seccion:', error);
    }
  };
  const handleCellValueChangeCuentaContable = (params, newValue,nombre_cuenta_contable, codigo_cuenta_contable) => {
    const { id } = params;
    const editedCuentaIndex = editedCuentaContable.findIndex((cuenta) => cuenta.id === id);
  
    if (editedCuentaIndex !== -1) {
      // Si la cuenta contable ya ha sido editada previamente, actualiza su valor
      const updatedCuentas = [...editedCuentaContable];
      updatedCuentas[editedCuentaIndex] = { id, nombre_cuenta: newValue,nombre_cuenta_contable, codigo_cuenta_contable };
      setEditedCuentaContable(updatedCuentas);
    } else {
      // Si es una cuenta contable nueva, añádela al estado de editedCuentaContable
      setEditedCuentaContable((prevCuentas) => [...prevCuentas, { id, nombre_cuenta: newValue, nombre_cuenta_contable,codigo_cuenta_contable }]);
    }
  
    // Actualizar los campos relacionados en la fila actual
    const updatedRecords = records.map((record) => {
      if (record.id === id) {
        return { ...record, nombre_cuenta: newValue,nombre_cuenta_contable, codigo_cuenta_contable };
      }
      return record;
    });
    setRecords(updatedRecords);
  };
  const handleProductChange = (value) => {
    if(options.includes(value)){
      setSelectionProduct(value);
    }
    else{
			setSelectionProduct(null);
		}
  };
  const agregarServicio = () => {
    // Crear el nuevo objeto de la fila a agregar
    const nuevaFila = {
      id: uuidv4(),
      descripcion:'',
      precio_unitario: '',
      fecha_entrega: '',
      nombre_ceco: '',
      codigo_departamente:'',
      codigo_seccion:'',
      nombre_cuenta:'',
      nombre_cuenta_contable:'',
      codigo_cuenta_contable:''
    };
  
    // Agregar la nueva fila al array de records
    const nuevosRecords = [...records, nuevaFila];
  
    // Actualizar el estado de records con la nueva fila agregada
    setRecords(nuevosRecords);
  };

  const columns = [
    {
      field: "accion", headerName: "Acción", width: 80, sortable: false, filterable: false, justifyContent: "center",
      renderCell: (cellValues) => {
        return (
          <CMIconButton onClick={() => {setRecords(records.filter(record => record.id !== cellValues.id))}}>
            <DeleteIcon/>
          </CMIconButton>
        )
      }
    },
    
    {
      field: 'descripcion',
      headerName: 'Descripcion',
      width: 360,
      type: 'string',
      editable: true,
      cellClassName: 'gray-column', 
    },
    {
      field: 'total_servicio',
      headerName: 'Precio',
      width: 120,
      type: 'number',
      editable: true,
      cellClassName: 'gray-column', // Clase CSS personalizada para aplicar el color gris
      valueFormatter: (params) => params.value ? formatNumberWithDot(params.value) : '',
    },
    {
      field: 'fecha_entrega',
      headerName: 'Fecha Entrega',
      width: 120,
      type: 'date',
      cellClassName: 'gray-column', // Clase CSS personalizada para aplicar el color gris
      editable: true,
         valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000'
    },
    {
      field: 'codigo_ceco_concat',
      headerName: 'Nombre Ceco',
      width: 300,
      editable: true,
      cellClassName: 'gray-column', // Clase CSS personalizada para aplicar el color gris
      renderCell: (params) => {
        const ceco = cecos.find((ceco) => ceco.id === params.value);
        const selectedValue = editedCecos.find((ceco) => ceco.id === params.id)?.nombre_ceco;
        const value = selectedValue || (ceco ? ceco.nombre_ceco : '');
        return value;
      },
      renderEditCell: (params) => {
        const editedCeco = editedCecos.find((ceco) => ceco.id === params.id);
        const value = editedCeco ? editedCeco.nombre_ceco : params.value;
        const ceco = cecos.find((ceco) => ceco.id === params.value); // Obtener el ceco correspondiente
        const codigo_ceco = ceco ? ceco.codigo_ceco : ''; // Obtener el codigo_ceco del ceco correspondiente
      
        return (
          <Autocomplete
            options={cecos.map((ceco) => `${ceco.nombre_ceco} - ${ceco.codigo_ceco}`)}
            value={value || ''}
            fullWidth
            onChange={(event, newValue) => {
              const selectedCodigoCeco = newValue ? newValue.split(' - ')[1] : ''; // Obtener el codigo_ceco del valor seleccionado
              handleCellValueChange(params, newValue, selectedCodigoCeco); // Pasar selectedCodigoCeco como tercer parámetro
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    // Realizar la acción de búsqueda al presionar Enter
                    handleCellValueChange(params, event.target.value, codigo_ceco);
                  }
                }}
              />
            )}
            onClick={(event) => {
              // Realizar la acción de búsqueda al hacer clic
              handleCellValueChange(params, event.target.textContent, codigo_ceco);
            }}
          />
        );
      },
    },
    {
      field: 'codigo_departamente',
      headerName: 'Codigo Departamento',
      width: 160,
      valueFormatter: (params) => params.value
    },
    {
      field: 'codigo_seccion',
      headerName: 'Codigo sección',
      width: 160,
      valueFormatter: (params) => params.value
    },
    {
      field: 'codigo_cuenta_contable_concat',
      headerName: 'Cuenta contable',
      width: 200,
      editable: true,
      cellClassName: 'gray-column', // Clase CSS personalizada para aplicar el color gris
      renderCell: (params) => {
        const cuentaconta = cuentaContable.find((cuenta) => cuenta.id === params.value);
        const editedCuenta = editedCuentaContable.find((cuenta) => cuenta.id === params.id);
        const selectedValue = editedCuenta?.nombre_cuenta_contable;
        const value = selectedValue || (cuentaconta ? `${cuentaconta.nombre_cuenta_contable} -- ${cuentaconta.codigo_cuenta_contable}` : '');
        return value;
      },
      renderEditCell: (params) => {
        const editedCuentaContable = cuentaContable.find((cuenta) => cuenta.id === params.id);
  const value = editedCuentaContable ? editedCuentaContable.nombre_cuenta_contable : params.value;
  const cuenta = cuentaContable.find((cuenta) => cuenta.id === params.value); // Obtener la cuenta contable correspondiente
    
        return (
          <Autocomplete
            options={cuentaContable.map((cuenta) => `${cuenta.nombre_cuenta_contable} -- ${cuenta.codigo_cuenta_contable}`)}
            value={value || ''}
            fullWidth
            onChange={(event, newValue) => {
              const nombre_cuenta_contable = newValue ? newValue.split(' -- ')[0] : ''; // Obtener el código de cuenta contable del valor seleccionado
              const codigo_cuenta_contable = newValue ? newValue.split(' -- ')[1] : ''; // Obtener el código de cuenta contable del valor seleccionado
              handleCellValueChangeCuentaContable(params, newValue, nombre_cuenta_contable,codigo_cuenta_contable);
            }}
            renderInput={(params) => <TextField {...params} autoFocus />}
          />
        );
      },
    }
  ];

  const processRowUpdate = useCallback(
    async (newRow) => {
      if (!newRow || typeof newRow !== 'object') {
        setSnackbar({ children: 'Error: newRow no es válido', severity: 'error' });
        return;
      }
  
      const {
        id,
        descripcion,
        precio_unitario,
        fecha_entrega,
        nombre_ceco,
        codigo_departamente,
        codigo_seccion,
        nombre_cuenta,
        codigo_cuenta_contable,nombre_cuenta_contable
      } = newRow;
  
  
      const updatedRecords = records.map((record) => {
        if (record.id === id) {
          return {
            ...record,
            descripcion,
            precio_unitario,
            fecha_entrega,
            nombre_ceco,
            codigo_departamente,
            codigo_seccion,
            nombre_cuenta,
            codigo_cuenta_contable,nombre_cuenta_contable
          };
        }
        return record;
      });
  
      setRecords(updatedRecords);
  
      setSnackbar({ children: 'Guardado exitosamente', severity: 'success' });
  
      const updatedRow = { ...newRow };
      const response = await mutateRow(updatedRow);
      setDataresponse(response);
      setRegistrarbtn(false);
      return response;
    },
    [records, mutateRow]
  );

  useEffect(() => {
    if (dataresponse) {
      const {id,descripcion,precio_unitario,fecha_entrega,nombre_ceco,codigo_departamente,codigo_seccion,nombre_cuenta,codigo_cuenta_contable,nombre_cuenta_contable} = dataresponse;

       const oldDevices = [...records];
       const rowDeviceIndex = oldDevices.findIndex((dev) => dev.id === id);
       oldDevices[rowDeviceIndex] = {
        ...oldDevices[rowDeviceIndex],
        id,descripcion,precio_unitario,fecha_entrega,nombre_ceco,codigo_departamente,codigo_seccion,nombre_cuenta,codigo_cuenta_contable,nombre_cuenta_contable
  };
     setRecords(oldDevices);
    }
  }, [dataresponse])

  const calculaTotales = () => {
    let total_pago_vencido = 0;
  
    const totales = records.map((item) => {
      const total_antes_descuento = item.precio_unitario;
      total_pago_vencido += total_antes_descuento; // Acumular la suma
  
      return total_pago_vencido;
    });
  
    return {
      totales,
      total_pago_vencido
    };
  };
  const hanbleRegistrar = () => {
    if (fechaentrega <= fechacontable) {
      setSnackbar({ children: 'La fecha de entrega debe ser mayor o igual que la fecha contable', severity: 'error' });
      return;
    }
    if(!selectProveedor){
      setSnackbar({ children: 'Falta seleccionar el proveedor', severity: 'error' });
      return;
    }
    if(!selectPersonaContacto){
      setSnackbar({ children: 'Debe seleccionar un proveedor de contacto', severity: 'error' });
      return;
    }
    if(!motivotraslado){
      setSnackbar({ children: 'Debe indicar el motivo del traslado', severity: 'error' });
      return;
    }
    if(!observaciones){
      setSnackbar({ children: 'Debe indicar el resumen de la orden', severity: 'error' });
      return;
    }
    if(!selectedDestino){
      setSnackbar({ children: 'Debe seleccionar el destino', severity: 'error' });
      return;
    }
    if(records.length === 0){
      setSnackbar({ children: 'No hay servicio registrados', severity: 'error' });
      return;
    }
    for (const record of records) {
      // Verificar cada campo del objeto
      if (
        !record.descripcion ||
        !record.precio_unitario ||
        !record.fecha_entrega ||
        !record.codigo_ceco ||
        !record.codigo_departamente ||
        !record.codigo_seccion ||
        !record.nombre_cuenta_contable ||
        !record.codigo_cuenta_contable 
      ) {
        // Al menos uno de los campos está vacío
        setSnackbar({ children: 'Todos los campos son obligatorios en los Servicios', severity: 'error' });
        return;
      }
      if(record.fecha_entrega   < fechacontable){
        setSnackbar({ children: 'La fecha de entrega en los productos debe ser mayor o igual a la fecha contable', severity: 'error' });
        return;
      }
    }

    Swal.fire({
      title: 'REGISTRO DE SOLICITUD ORDEN DE COMPRA',
      text: "¿ESTA CONFORME CON LO REGISTRADO?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        setCargando(true);
         const datosOrden = {
          id_oc_cabecera: 0,
          id_solicitante: user.id_usuario,
          nombre_solicitante: user.usuario,
          id_proveedor: idprovedor,
          nombre_proveedor: nombreProveedor,
          correo_proveedor: "jgal@mariaalmenara.pe",
          ruc_proveedor: ruc,
          id_proveedor_sap: codigoProveedorsap,
          id_contacto_proveedor: idcontacto,
          codigo_empleado_venta: selectPersonaContacto.codigo_empleado_venta,
          contacto_proveedor: selectPersonaContacto.nombre_empleado_venta,
          fecha_contable: moment(fechacontable).locale('es', MomentLocaleEs),
          fecha_entrega: moment(fechaentrega).locale('es', MomentLocaleEs),
          fecha_documento: moment(fechacontable).locale('es', MomentLocaleEs),
          fecha_registro: moment().locale('es', MomentLocaleEs),
          condic_pago: condiciondepago,
          destino: selectedDestino.nombre_direccion_destino,
          impuesto: Number((calculaTotales().total_pago_vencido-calculaTotales().total_pago_vencido/1.18).toFixed(4)),
          total_antes_descuento: Number((calculaTotales().total_pago_vencido/1.18).toFixed(4)),
          total_pago_vencido: Number((calculaTotales().total_pago_vencido).toFixed(4)),
          observaciones: observaciones,
          tipo: "Servicio",
          motivo_solicitud: motivotraslado,
          moneda: tipocambio,
          tipo_cambio: 1,
          comentario:comment,
          oc_detalles:records.map(datos => datos &&{
            id_oc_detalle: 0,
            id_oc_cabecera: 0,
            fecha_entrega: datos.fecha_entrega,
            descripcion: datos.descripcion,
            numero_cuenta_mayor: datos.codigo_cuenta_contable,
            nombre_cuenta_mayor: datos.nombre_cuenta_contable,
            total_servicio: datos.precio_unitario,
            codigo_cuenta_contable: datos.codigo_cuenta_contable,
            codigo_articulo:null,
            codigo_unidad_medida_articulo: null,
            unidad_medida_articulo: null,
            cantidad_pedida: null,
            precio_unitario: null,
            porcentaje_descuento: 0,
            total_articulo: null,
            codigo_ceco: datos.codigo_ceco,
            codigo_departamente: datos.codigo_departamente,
            codigo_seccion: datos.codigo_seccion,
            codigo_almacen_sap: null,
            created_at: moment().locale('es', MomentLocaleEs),
            updated_at: moment().locale('es', MomentLocaleEs)
          })
         }
         setRegistrarbtn(true);
         OrdenesService.insertarSolPe(datosOrden,setCargando,setSnackbar,user.token).then((res) => { 
          setRecords([]);
          setSelectProveedor(null);
          setRuc('');
          setSelectPersonaContacto(null);
          setCondicionDepago('')
          setMotivotraslado('');
          setObservaciones('');
          setSelectedDestino(null);
          setComment('');
         });
      }
    })
  };

  //Nuevo
  const handleComentarioChange = e => {
    const { name, value } = e.target;
    setComment(value)
  }
  const handleObservaciones = e => {
    const { name, value } = e.target;
    setObservaciones(value)
  }
  const handleMotivoTraslado = e => {
    const { name, value } = e.target;
    setMotivotraslado(value)
  }
  const handleContable = (newValue) => {
    setFechacontable(newValue);
  }
  const handleEntrega = (newValue) => {
    setFechaentrega(newValue);
  }
  const handleRegistro = (newValue) => {
    setFecharegistro(newValue);
  }
  const handleChangeTipodeCambio = (event) => {
    setTipocambio(event.target.value);
  };
  const handleAutocompleteChange = (event, value) => {
    setSelectProveedor(value);
    setRuc(value.numero_documento);
    setIdprovedor(value.id_usuario);
    setNombreProvedor(value.razon_social);
    setCodigoProveedorsap(value.id_proveedor_sap);
    setCondicionDepago(value.nombre_condicion_credito)
  };
  const filterOptions = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option.razon_social.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.numero_documento.includes(inputValue)
    );
  };
  const handleAutocompletePersonaContacto = (event, value) => {
    setSelectPersonaContacto(value);
    setIdcontacto(value.codigo_empleado_venta);
  };
  const filterOptionsPersonaContacto = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option.nombre_empleado_venta.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleSelectConditionPago = (event, value) => {
    setSelectedConditionPago(value);
  };
  const filterOptionsDestino = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option.nombre_direccion_destino.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const handleAutocompleteDestino = (event, value) => {
    console.log(value);
    setSelectedDestino(value);
  };
  
  return (
    <Grid width={'100%'} m="auto">
      <Grid container justifyContent="flex-start" alignItems="center" spacing={2} sx={{ py: 1 }}>
        <Grid container item xs={12} sm={12} >
          <Grid item sm={6} >
            <CMLabel sx={{ margin: 2 }} fontSize='28px' color="black"><b>CREAR SOLICITUD DE ORDEN DE SERVICIO</b></CMLabel>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} alignItems="center">
          <Grid item sm={12}>
            <Grid item xs={12} sm={12}>
              <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Datos del proveedor</b></CMLabel>
            </Grid>
            <Grid container item xs={12} sm={12} alignItems="center">
              <Grid xs={12} sm={1.5}>
                <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black">Proveedor</CMLabel>
              </Grid>
              <Grid xs={12} sm={2}>
                <Autocomplete
                  value={selectProveedor}
                  onChange={handleAutocompleteChange}
                  options={proveedor}
                  getOptionLabel={(option) => option.razon_social}
                  filterOptions={filterOptions}
                  renderInput={(params) => <TextField {...params} label="Buscar" />}
                />
              </Grid>
              <Grid xs={12} sm={0.5}>
              </Grid>
              <Grid xs={12} sm={1.5}>
                <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black">RUC</CMLabel>
              </Grid>
              <Grid xs={12} sm={2}>
                <CMTextField
                  name="ruc"
                  label='ruc'
                  value={ruc}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid xs={12} sm={0.5}>
              </Grid>
              <Grid xs={12} sm={1.5}>
                <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black">Persona Contacto</CMLabel>
              </Grid>
              <Grid xs={12} sm={2}>
                <Autocomplete
                  value={selectPersonaContacto}
                  onChange={handleAutocompletePersonaContacto}
                  options={contacto}
                  getOptionLabel={(option) => option.nombre_empleado_venta}
                  filterOptions={filterOptionsPersonaContacto}
                  renderInput={(params) => <TextField {...params} label="Buscar persona contacto" />}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} sx={{paddingTop:2}} alignItems="center">
            <Grid item sx={12} sm={1.5}>
              <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black">Condición de pago</CMLabel>
            </Grid>
            <Grid item sx={12} sm={2}>
              <CMTextField
                  name="Condición de pago"
                  label='Condición de pago'
                  value={condiciondepago}
                  disabled
                  fullWidth
                />
            </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid item sm={12}>
              <CMLabel sx={{ margin: 2 }} fontSize='22px' color="black"><b>Datos de la orden</b></CMLabel>
            </Grid>
            <Grid container item sm={12} alignItems="center">
              <Grid item sx={12} sm={1.5}>
                <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black">Fecha contable</CMLabel>
              </Grid>
              <Grid item sx={12} sm={2}>
                <CMDatePicker
                  label="Fecha contable"
                  value={fechacontable}
                  inputFormat="DD/MM/YYYY"
                  onChange={handleContable}
                  minDate={fechacontable}
                />
              </Grid>
              <Grid item sx={12} sm={0.5}>
                
              </Grid>
              <Grid item sx={12} sm={1.5}>
                <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black">Fecha entrega</CMLabel>
              </Grid>
              <Grid item sx={12} sm={2}>
                <CMDatePicker
                  label="Fecha entrega"
                  value={fechaentrega}
                  inputFormat="DD/MM/YYYY"
                  onChange={handleEntrega}
                  minDate={fechaentrega}
                />
              </Grid>
              <Grid item sx={12} sm={0.5}>

              </Grid>
              <Grid item sx={12} sm={1.5}>
              <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black">Tipo de compra</CMLabel>
            </Grid>
            <Grid item sx={12} sm={2}>
              <CMTextField
                name="Tipo de compra"
                label='Tipo de compra'
                value="Compra Local"
                disabled
                fullWidth
              />
            </Grid>
            
            </Grid>
          </Grid>
          <Grid container item sx={{ paddingTop: 3, paddingBottom: 3 }} alignItems="center">
            <Grid item sx={12} sm={1.5}>
              <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black">Tipo de moneda</CMLabel>
            </Grid>
            <Grid item sx={12} sm={2}>
              <Select
                labelId="currency-label"
                id="currency"
                value={tipocambio}
                onChange={handleChangeTipodeCambio}
                fullWidth
              >
                <MenuItem value="S/">S/ (Moneda Local)</MenuItem>
                <MenuItem value="US$">US$ (Moneda Extranjera)</MenuItem>
              </Select>
            </Grid>
            <Grid item sx={12} sm={0.5}>

            </Grid>
            <Grid item sx={4} sm={1.5}>
              <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black">Motivo</CMLabel>
            </Grid>
            <Grid item sx={0.2}>
              <Tooltip title="Motivo control del sistema">
                <InfoIcon sx={{ color: 'primary.main'}}/>
              </Tooltip>
            </Grid>
            <Grid item sx={8} sm={1.8}>
                <CMTextField
                      name="Motivo"
                      label='Motivo'
                      value={motivotraslado}
                      onChange={handleMotivoTraslado}
                      fullWidth
                      inputProps={{
                        maxLength: 300
                      }}
                    />
            </Grid>
            <Grid item sx={12} sm={0.5}>

            </Grid>

            <Grid item sx={12} sm={1.5}>
              <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black">Resumen</CMLabel>
            </Grid>
            <Grid item sx={12} sm={0.2}>
              <Tooltip title="Resumen de la orden SAP, en formato Resumen-Tienda">
                <InfoIcon sx={{ color: 'primary.main'}}/>
              </Tooltip>
            </Grid>
            <Grid item sx={12} sm={1.8}>
              <CMTextField
                name="Resumen"
                label='Resumen'
                value={observaciones}
                onChange={handleObservaciones}
                fullWidth
                inputProps={{
                  maxLength: 500
                }}
              />
            </Grid>
          </Grid>
          <Grid container item sx={{ paddingTop: 0, paddingBottom: 3 }} alignItems="center">
            <Grid item sx={12} sm={1.5}>
              <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black">Destino</CMLabel>
            </Grid>
            <Grid item sx={12} sm={2}>
               <Autocomplete
                  value={selectedDestino}
                  onChange={handleAutocompleteDestino}
                  options={destino}
                  getOptionLabel={(option) => option.nombre_direccion_destino}
                  filterOptions={filterOptionsDestino}
                  renderInput={(params) => <TextField {...params} label="Buscar destino" />}
                />
            </Grid>
            <Grid item sx={12} sm={0.5}>
            </Grid>
            
          </Grid>
          <Grid container item sx={{ paddingTop: 0, paddingBottom: 3 }} alignItems="center">
            <Grid item sm={12}>
              <CMLabel sx={{ margin: 2 }} fontSize='22px' color="black"><b>Datos del Servicio</b></CMLabel>
            </Grid>
            <Grid container sm={12} alignItems="center">
                <Grid item sx={12} sm={2} >
                    <CMButton
                    label="Insertar Servicio"
                    variant="contained"
                    onClick={(e) => agregarServicio()}
                  />
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
          <Grid style={{ height: 400, width: '100%', overflowX: 'auto' }} sx={{ py: 0 }}>
            <DataGrid
              rows={records ? records : []}
              columns={columns}
              sx={{
                height: "100%",
                "& .MuiDataGrid-window": {
                  overflowX: 'scroll',
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                },
              }}
              pagination
              rowHeight={50}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              experimentalFeatures={{ newEditingApi: true }}
              loading={cargando}
            />
            {!!snackbar && (
              <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
              >
                <Alert {...snackbar} onClose={handleCloseSnackbar} />
              </Snackbar>
            )}
          </Grid>
          <Grid item xs={12} sm={12} container>
            <Grid item xs={12} sm={6} container alignItems="center">
              <Grid item xs={6} sm={3}>
                {/* <CMLabel fontSize='16px' color='black'>Comentario: </CMLabel> */}
              </Grid>
              <Grid item xs={6} sm={6}>
                {/* <CMTextField
                  name="comment"
                  label='Comentario'
                  value={comment}
                  onChange={handleComentarioChange}
                  fullWidth
                /> */}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} container justifyContent="flex-end">
              <CMButton
                label="Registrar"
                variant="contained"
                sx={{ marginTop: '10px' }}
                onClick={hanbleRegistrar}
                disabled={registrarbtn}
              />
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CopiarCrearOrdenesServicio
import React, { useState,useEffect,useCallback} from "react";
import moment from 'moment';


import { Grid ,Typography} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
  } from '@mui/x-data-grid';
import { Icon } from '@mui/material';
import * as XLSX from 'xlsx';
import { useParams, useHistory } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//Components
import CMLabel from '../../components/atoms/CMLabel';
import CMDatePicker from '../../components/molecules/CMDatePicker';
import CMButton from '../../components/atoms/CMButton';

//Constants
import {MomentLocaleEs} from '../../constants/MomentLocaleEs';
//Services
import * as OrdenesService from '../../services/OrdenesService';
import * as ProveedorService from '../../services/ProveedorService';
import * as FinanzaService from '../../services/FinanzaService';


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CMTextField from "../../components/atoms/CMTextField";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import CMIconButton from '../../components/atoms/CMIconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const MySwal = withReactContent(Swal)


const PendienteAprobacionPdfVer = () => {
  const history = useHistory();
    const [rowsdata, setRowsdata] = useState([]);
    const [rowsgrid, setRowsgrid] = useState([]);
    const [comment,setComment] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');

    const handleSelectChange = (event) => {
      setSelectedOption(event.target.value);
      if(event.target.value ==='APROBAR'){
        setSelectedOption2('Documentos Aprobados')
      }else if(event.target.value ==='RECHAZAR'){
        setSelectedOption2('')
      }
    };
    const handleSelectChange2 = (event) => {
      setSelectedOption2(event.target.value);
    };
    const user = JSON.parse(window.localStorage.getItem("user"));
    const useFakeMutation = () => {
      return React.useCallback(
        (user) =>
          new Promise((resolve, reject) =>
            setTimeout(() => {
              if (user.name?.trim() === '') {
                reject(new Error("Error al guardar el usuario: el nombre no puede estar vacÃ­o"));
              } else {
                resolve({ ...user, name: user.name?.toUpperCase() });
              }
            }, 200),
          ),
        [],
      );
    };
    const mutateRow = useFakeMutation();
    const [snackbar, setSnackbar] = useState(null);
    const [dataresponse,setDataresponse] = useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const handleProcessRowUpdateError = React.useCallback((error) => {
      setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const { ordenid } = useParams();
    useEffect(() => {
        OrdenesService.getNEID2(setRowsdata,ordenid,user.token);
    }, []);
    let idCounter = 0; // Inicializamos el contador

    useEffect(() => {
      console.log(rowsdata);
    }, [rowsdata])
    

    useEffect(() => {
        if (rowsdata.oc_detalles) {
            const rowsWithId = rowsdata.oc_detalles.map(row => ({
                ...row,
                id: idCounter++
            }));
            setRowsgrid(rowsWithId);
        }
    }, [rowsdata]);
    

    const formatCurrency = (value, moneda) => {
      let currency = 'PEN';
    
      if (moneda === 'S/') {
          currency = 'PEN';
        }else  if (moneda === 'US$') {
        currency = 'USD';
      }
    
      return value.toLocaleString('es-PE', {
        style: 'currency',
        currency: currency,
      });
    };

 
   
   
      const columns = [
        { field: 'codigo_articulo', headerName: 'Codigo Árticulo', width: 120, align: 'center',headerAlign: 'center' },
        { field: 'nombre_articulo', headerName: 'Árticulo', width: 480,headerAlign: 'center' },
        { field: 'unidad_medida', headerName: 'Unidad Medida', width: 180, align: 'center',headerAlign: 'center' },
        { field: 'cantidad_pedida', headerName: 'Cantidad Pedida', width: 150, align: 'center',headerAlign: 'center' },
        { field: 'cantidad_entregada', headerName: 'Cantidad Entregada', type:'number', width: 150, align: 'center',headerAlign: 'center',editable: true},
        { field: 'cantidad_recibida', headerName: 'Cantidad Recibida', width: 150, align: 'center',headerAlign: 'center' },
        { 
            field: 'fecha_entrega', 
            headerName: 'Fecha Acuerdo', 
            type:'date',
            width: 140, 
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY') 
          },
          { 
            field: 'fecha_recepcion', 
            headerName: 'Fecha Entrega', 
            type: 'date',
            width: 140, 
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000',
            editable: true
          },
          { 
            field: 'fecha_termino', 
            headerName: 'Fecha Recibida', 
            type: 'date',
            width: 140, 
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000'
          },
          { field: 'precio_unitario', headerName: 'Precio Unitario', width: 150, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda) },
          { field: 'total_articulo', headerName: 'Total Árticulo', width: 150, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda) },

      ];
      const columns2 = [
        { field: 'descripcion', headerName: 'Descripción', width: 650, align: 'center',headerAlign: 'center'},
        { field: 'fecha_entrega', headerName: 'Fecha Entrega', width: 180, align: 'center',headerAlign: 'center',
        valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000'
      },
        { field: 'nombre_cuenta_mayor', headerName: 'Nombre Cuenta Mayor', width: 300, align: 'center',headerAlign: 'center'},
        { field: 'numero_cuenta_mayor', headerName: 'Numero Cuenta Mayor', width: 180, align: 'center',headerAlign: 'center'},
        { field: 'total_servicio', headerName: 'Total Servicio', width: 180, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda) },

      ]
      const processRowUpdate  = useCallback(
        async (newRow) => {
          // Make the HTTP request to save in the backend
          const response = await mutateRow(newRow);
          setDataresponse(response);
          
          setSnackbar({ children: 'Guardo exitosamente', severity: 'success' });
          return response;
  
        },
        [mutateRow],
        
      ); 
      useEffect(() => {
        if(dataresponse) {
            const {id_ne_detalle,codigo_articulo,unidad_medida,cantidad_pedida,cantidad_entregada,cantidad_recibida,fecha_entrega,fecha_recepcion,fecha_termino,precio_unitario,total_articulo} = dataresponse;
            if(!cantidad_recibida){
              alert('Debe ingresar la cantidad recibida');        
            }
            if(!fecha_recepcion){
              alert('Debe una fecha de entrega'); 
            }
            
            const oldDevices = [...rowsgrid];
            const rowDeviceIndex = oldDevices.findIndex((dev) => dev.id_ne_detalle === id_ne_detalle);
            oldDevices[rowDeviceIndex] = {
              ...oldDevices[rowDeviceIndex],
              id_ne_detalle,codigo_articulo,unidad_medida,cantidad_pedida,cantidad_entregada,cantidad_recibida,fecha_entrega,fecha_recepcion,fecha_termino,precio_unitario,total_articulo
            };
            setRowsgrid(oldDevices);
        }
      }, [dataresponse])

      const handleLiberarDocumentos = () => {
        if(!selectedOption){
          alert("Debe seleccionar una opción si es aprobado o rechazado");
          return;
        }
        if(!selectedOption2){
          alert("Debe seleccionar una opción de asunto");
          return;
        }
        const liberar = {
          id_ne_cabecera:rowsdata.id_ne_cabecera,
          numero_documento:rowsdata.numero_documento,
          id_documento:rowsdata.id_documento,
          tipo:rowsdata.tipo,
          status: selectedOption,
          asunto: selectedOption2,
          comentario: comment,
          id_contabilidad:rowsdata.id_aprobador_finanzas,
          nombre_proveedor:rowsdata.nombre_proveedor,
          correo_proveedor:rowsdata.correo_proveedor,
          correo_solicitante:rowsdata.correo_solicitante,
          correo_contabilidad:user.correo
        }
      
        Swal.fire({
          title: 'Registrar seguro de liberar documentos',
          text: "PDF, CDR Y XML",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'
          }).then((result) => {
          if (result.isConfirmed) {
            if(selectedOption === 'RECHAZAR'){
              FinanzaService.deletePDF(liberar,setSnackbar, user.token);
              OrdenesService.getNEID(setRowsdata,ordenid,user.token);
            }else if(selectedOption === 'APROBAR'){
              FinanzaService.aprobarPDF(liberar,setSnackbar, user.token);
              OrdenesService.getNEID(setRowsdata,ordenid,user.token);
            }else{
              alert("No selecciono una opción");
            }
          
          }
        })
      };
      const AprobacionDocumentos = () => {
        Swal.fire({
          title: 'Seguro de aprobar documentos',
          text: "PDF, CDR Y XML",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'
          }).then((result) => {
          if (result.isConfirmed) {
            FinanzaService.aprobarPDF(rowsdata,setSnackbar, user.token);
            OrdenesService.getNEID(setRowsdata,ordenid,user.token);
          }
        })
      };
      const handleComentarioChange = e => {
        const {name, value} = e.target;
        setComment(value)
      }
      
  const handleClickRegresar = () => {
    history.push(`/pendienteaprobacionpdf`);
    history.go(0);
  }
 return (
    <Grid width={'100%'} m="auto">
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2} sx={{py:1}}>
        <Grid container item xs={12} sm={12} >
                <Grid item sm={6}>
                    <CMLabel sx={{ margin: 2 }} fontSize='28px' color="black"><b>Numero de Orden de {rowsdata.tipo==='Articulo' ? 'Compra' : 'Servicio'} #{rowsdata.numero_documento_orden}</b></CMLabel>
                </Grid>
                <Grid item sm={6} container justifyContent="flex-end" alignItems="center" >
                  <Grid container xs={2} justifyContent="flex-end" alignItems="center" >
                        <Grid item justifyContent="flex-end" alignItems="center" >
                          <CMIconButton disableRipple={true} size="large" sx={{color: "primary.main", display: "block"}} onClick={handleClickRegresar}>
                            <ArrowBackIcon/>
                            <Typography sx={{lineHeight: 0}}>Regresar</Typography>
                          </CMIconButton>
                        </Grid>
                  </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Proveedor</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Nombre :</b> {rowsdata.nombre_proveedor}</CMLabel>
                    </Grid>
                </Grid>
                <Grid item sm={1}>
                   
                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Solicitante</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Nombre :</b> {rowsdata.nombre_solicitante}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Destino :</b> {rowsdata.destino}</CMLabel>
                    </Grid>
                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Detalle Orden de {rowsdata.tipo==='Articulo' ? 'Compra' : 'Servicio'}</b></CMLabel>
                    <hr></hr>
                    
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Estado :</b> {rowsdata.estado}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>OC Estado :</b> {rowsdata.ultimo_nombre_oc_estado}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Observación :</b> {rowsdata.observaciones}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Condición de Pago :</b> {rowsdata.condic_pago}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Fecha Contable :</b> {moment(rowsdata.fecha_contable).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Fecha Entrega :</b> {moment(rowsdata.fecha_entrega).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Fecha Registro :</b> {moment(rowsdata.fecha_registro).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>OC Actualización :</b> {moment(rowsdata.ultima_fecha_modificacion).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    
                </Grid>
                <Grid item sm={1}>
                   
                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Importe</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={6} container justifyContent="space-between" alignItems="center" >
                      <Grid item sm={12} container justifyContent="space-between">
                          <Grid>
                          <CMLabel fontSize='16px' color='black'><b>Subtotal :</b></CMLabel>
                          </Grid>
                          <Grid>
                          <CMLabel fontSize='16px' color='black'> {rowsdata.total_antes_descuento ? formatCurrency(rowsdata.total_antes_descuento,rowsdata.moneda) : ''}</CMLabel>
                          </Grid>
                      </Grid>
                      <Grid item sm={12}  container justifyContent="space-between">
                          <Grid>
                          <CMLabel fontSize='16px' color='black'><b>Impuesto :</b></CMLabel>
                          </Grid>
                          <Grid>
                          <CMLabel fontSize='16px' color='black'>  { rowsdata.impuesto ? formatCurrency(rowsdata.impuesto,rowsdata.moneda) : formatCurrency(0,rowsdata.moneda)}</CMLabel>
                          </Grid>
                      </Grid>
                      <Grid item sm={12}  container justifyContent="space-between">
                        <Grid>
                        <CMLabel fontSize='16px' color='black'><b>Total :</b></CMLabel>
                        </Grid>
                        <Grid>
                        <CMLabel fontSize='16px' color='black'> {rowsdata.total_pago_vencido ? formatCurrency(rowsdata.total_pago_vencido,rowsdata.moneda) : ''}</CMLabel>
                        </Grid>
                      </Grid>
                    </Grid>
                    <br></br>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Documentos Digitales</b></CMLabel>
                    {rowsdata.factura_pdf &&
                    <Grid item sm={12}>
                    <a href={rowsdata.factura_pdf} target="_blank" rel="noopener noreferrer" download>
                    <Icon>
                      <CloudDownloadIcon/>
                    </Icon>
                    PDF
                    </a>
                    </Grid>
                    }
                    {rowsdata.factura_cdr &&
                    <Grid item sm={12}>
                    <a href={rowsdata.factura_cdr} target="_blank" rel="noopener noreferrer" download>
                    <Icon>
                      <CloudDownloadIcon/>
                    </Icon>
                    CDR
                    </a>
                    </Grid>
                    }
                    {rowsdata.factura_xml &&
                    <Grid item sm={12}>
                    <a href={rowsdata.factura_xml} target="_blank" rel="noopener noreferrer" download>
                    <Icon>
                      <CloudDownloadIcon/>
                    </Icon>
                    XML
                    </a>
                    </Grid>
                    }
                     {rowsdata.factura_guia &&
                    <Grid item sm={12}>
                    <a href={rowsdata.factura_guia} target="_blank" rel="noopener noreferrer" download>
                    <Icon>
                      <CloudDownloadIcon/>
                    </Icon>
                    GUIA
                    </a>
                    </Grid>
                    }
                    <br></br>
                    {rowsdata.factura_pdf &&
                    <Grid item sm={12}>
                    <InputLabel id="select-label">Seleccionar Aprobado o Rechazado</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        value={selectedOption}
                        onChange={handleSelectChange}
                        label="Seleccionar Aprobado o Rechazado"
                      >
                        <MenuItem value="APROBAR">APROBAR</MenuItem>
                        <MenuItem value="RECHAZAR">RECHAZAR</MenuItem>
                      </Select>
                    </Grid>   
                    }
                    <br></br>
                  {rowsdata.factura_pdf && (
                        <Grid item sm={12}>
                          <InputLabel id="select-label-asunto">Seleccionar Asunto</InputLabel>
                          <Select
                            labelId="select-label-asunto"
                            id="select-asunto"
                            value={selectedOption2}
                            onChange={handleSelectChange2}
                            label="Seleccionar Asunto"
                          >
                         
                                <MenuItem value="Diferencia de precio en Factura y OC/OS">Diferencia de precio en Factura y OC/OS</MenuItem>
                                <MenuItem value="Diferencia de cantidad en Factura y NE">Diferencia de cantidad en Factura y NE</MenuItem>
                                <MenuItem value="Diferencia de precio y cantidad en OC/OS y NE">Diferencia de precio y cantidad en OC/OS y NE</MenuItem>
                                <MenuItem value="Documentos incompletos">Documentos incompletos</MenuItem>                                
                                <MenuItem value="NE y Factura con OC/OS distinta">NE y Factura con OC/OS distinta</MenuItem>
                                <MenuItem value="Factura duplicada">Factura duplicada</MenuItem>
                                <MenuItem value="Factura anulada">Factura anulada</MenuItem>
                                <MenuItem value="Factura con datos errados">Factura con datos errados </MenuItem>

                            {selectedOption === "APROBAR" && (
                              <MenuItem value="Documentos Aprobados">Documentos Aprobados</MenuItem>
                            )}
                          </Select>
                        </Grid>
                      )}
                      <br></br>
                     {rowsdata.factura_pdf &&
                    <Grid item sm={8}>
                   <CMTextField
                      name="Comentario"
                      label="comentario"
                      value={comment}
                      onChange={handleComentarioChange}
                      fullWidth
                    />
                    </Grid>   
                    }
                    {rowsdata.factura_pdf &&
                    <Grid item sm={12}>
                    <CMButton
                        label="Registrar Documentos"
                        variant="contained"
                        sx={{ marginTop: '10px' }}
                        onClick={handleLiberarDocumentos}
                    />
                    </Grid>   
                    }
                     {/* {rowsdata.factura_pdf &&
                    <Grid item sm={12}>
                    <CMButton
                        label="Aprobar Documentos"
                        variant="contained"
                        sx={{ marginTop: '10px' }}
                        onClick={AprobacionDocumentos}
                    />
                    </Grid>   
                    } */}
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
  <Grid >
  {/* {rowsdata.tipo==='Articulo' ?
    <DataGrid
      rows={rowsgrid}
      columns={columns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      sx={{
        height: "100%",
        "& .MuiDataGrid-window": {
          overflowX: 'scroll',
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        },
      }}
      pagination
      rowHeight={50}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
      experimentalFeatures={{ newEditingApi: true }}
    />
     :  <DataGrid
     rows={rowsgrid}
     columns={columns2}
     pageSize={15}
     rowsPerPageOptions={[15]}
     sx={{
       height: "100%",
       "& .MuiDataGrid-window": {
         overflowX: 'scroll',
         "&::-webkit-scrollbar": {
           width: "0.4em",
         },
         "&::-webkit-scrollbar-track": {
           background: "#f1f1f1",
         },
         "&::-webkit-scrollbar-thumb": {
           backgroundColor: "#888",
         },
         "&::-webkit-scrollbar-thumb:hover": {
           background: "#555",
         },
       },
     }}
     pagination
     rowHeight={50}
     processRowUpdate={processRowUpdate}
     onProcessRowUpdateError={handleProcessRowUpdateError}
     experimentalFeatures={{ newEditingApi: true }}
   />} */}
     {!!snackbar && (
                    <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
  </Grid>
  <Grid item xs={12} sm={12} container>
    <Grid item xs={12} sm={6} container alignItems="center">
        {/* <Grid item xs={6} sm={3}>
					<CMLabel fontSize='16px'color='black'>Comentario: </CMLabel>
				</Grid>
				<Grid item xs={6} sm={6}>
					<CMTextField
						name="comment"
						label='Comentario'
						value={comment}
            disabled={true}
						onChange={handleComentarioChange}
						fullWidth
					/>
				</Grid> */}
    </Grid>
    <Grid item xs={12} sm={6} container justifyContent="flex-end">
    {/* <CMButton
        label="Registrar"
        variant="contained"
        sx={{ marginTop: '10px' }}
        onClick={hanbleEntregada}
        disabled // Add the disabled prop to disable the button
    /> */}
    </Grid>
    
  </Grid>
</Grid>
    </Grid>
    </Grid>
 )
}

export default PendienteAprobacionPdfVer
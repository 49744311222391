import axios from "axios";
import {urlAzure,url} from "../config";

export async function getOrdenesPendienteAprobacion(datalist, setValue, token,setLoading) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listAprobadorFinanzasPendiente`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setLoading(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  export async function getOrdenesPendienteAprobacionExcel(datalist, setValue, token,setBtndisabled) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listAprobadorFinanzasPendienteExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setBtndisabled(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }


  export async function CrearOCEstado(datos,opcion,setLoadingGrid,token) {
    var data = JSON.stringify(datos);
    try {
      if(opcion==='aprobar'){
      var config = {
        method: 'post',
        url: `${urlAzure}api/OC/aprobarFinanzas`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: data
      };
    }else if(opcion==='observar'){
      var config = {
        method: 'post',
        url: `${urlAzure}api/OC/observarFinanzas`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: data
      };
    }else if(opcion==='rechazar'){
      var config = {
        method: 'post',
        url: `${urlAzure}api/OC/rechazarFinanzas`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: data
      };
    }
      const datos=await axios(config);
      console.log("Se insertó correctamente");
      console.log(datos.data);
    } catch (error) {
      console.error("Ocurrió un error al Aprobar:", error);
      // Aquí puedes manejar el error como desees, por ejemplo, mostrar un mensaje al usuario o realizar alguna otra acción.
    } finally {
      // Aquí puedes realizar alguna acción adicional después de la solicitud, si es necesario.
      setLoadingGrid(false);
    }
  }
  

  export async function CrearOCEstadoBorrador(datos,opcion,setLoadingGrid,token) {
    var data = JSON.stringify(datos);
    try {
      if(opcion==='aprobar'){
      var config = {
        method: 'post',
        url: `${url}api/OC/CrearBorradorDelOC`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: data
      };
    }else if(opcion==='observar'){
      var config = {
        method: 'post',
        url: `${url}api/OC/CrearBorradorDelOC`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: data
      };
    }else if(opcion==='rechazar'){
      var config = {
        method: 'post',
        url: `${url}api/OC/CrearBorradorDelOC`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: data
      };
    }
      const datos=await axios(config);
      console.log("Se insertó correctamente");
      console.log(datos.data);
    } catch (error) {
      console.error("Ocurrió un error al Aprobar:", error);
      // Aquí puedes manejar el error como desees, por ejemplo, mostrar un mensaje al usuario o realizar alguna otra acción.
    } finally {
      // Aquí puedes realizar alguna acción adicional después de la solicitud, si es necesario.
      setLoadingGrid(false);
    }
  }

  export async function getListFinanzasHistorial(datalist, setValue, token,setLoading) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listAprobadorFinanzas`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setLoading(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  export async function getListFinanzasHistorialExcel(datalist, setValue, token,setBtndisabled) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listAprobadorFinanzasExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setBtndisabled(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getListFinanzasLiberarDocumentosNE(datalist, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/listEliminarPdf`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getListFacturasRechazadas(dataList,setValue, token) {
    var data = dataList;
    console.log(dataList);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/ListFacturasRechazadas`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getListFacturasRechazadasExport(dataList,setValue, token) {
    var data = dataList;
    console.log(dataList);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/ListFacturasRechazadasExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getListFacturasAprobadas(dataList,setValue, token) {
    var data = dataList;
    console.log(dataList);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/ListFacturasAprobadas`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getListFacturasAprobadasExport(dataList,setValue, token) {
    var data = dataList;
    console.log(dataList);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/ListFacturasAprobadasExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  export async function getListFinanzasLiberarDocumentosNEExcel(datalist, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/listEliminarPdfExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function deletePDF(data,setSnackbar, token) {
    try {
      var datos = data;
      const config = {
        method: 'post',
        url: `${urlAzure}api/NE/eliminarFactura`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: datos,
      };
  
      const response = await axios(config);
      setSnackbar('Se libero documento pendiente de volver adjuntar');
      return response.data;

    } catch (error) {
      // Manejar el error aquí
      setSnackbar('error');
      return null;
    }
  }

  export async function aprobarPDF(data,setSnackbar, token) {
    try {
      var datos = data;
      const config = {
        method: 'post',
        url: `${urlAzure}api/NE/aprobaciondefacturacontabilidad`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: datos,
      };
  
      const response = await axios(config);
      setSnackbar(response.data);
      return response.data;

    } catch (error) {
      // Manejar el error aquí
      setSnackbar(error);
      return null;
    }
  }


  export async function getListFacturasRechazadasArea(dataList,setValue, token) {
    var data = dataList;
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/ListFacturasRechazadasArea`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getListFacturasRechazadasAreaExport(dataList,setValue, token) {
    var data = dataList;
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/ListFacturasRechazadasAreaExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getListFacturasAprobadasArea(dataList,setValue, token) {
    var data = dataList;
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/ListFacturasAprobadasArea`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }

  export async function getListFacturasAprobadasAreaExport(dataList,setValue, token) {
    var data = dataList;
    var config = {
      method: 'post',
      url: `${urlAzure}api/NE/ListFacturasAprobadasAreaExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
import axios from "axios";
import {urlAzure,url} from "../config";
export async function getOCListSolicitante(datalist, setValue, token,setLoading) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/OC/listSolicitanteHistorial`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    // Agregar el campo "id" a los datos de respuesta
    const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    // Sort the data by id_oc_cabecera
    const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
    setValue(sortedData);
    setLoading(false);
  } catch (error) {
    // Manejar el error aquí
    
    console.error(error);
  }
}

export async function getOCListSolicitanteExcel(datalist, setValue, token,setBtndisabled) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/OC/listSolicitanteHistorialExport`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    // Agregar el campo "id" a los datos de respuesta
    const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    // Sort the data by id_oc_cabecera
    const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
    setValue(sortedData);
    setBtndisabled(false);
  } catch (error) {
    // Manejar el error aquí
    console.error(error);
  }
}


export async function getListConformidad(datalist, setValue, token) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/OC/listSolicitanteConformidad`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    // Agregar el campo "id" a los datos de respuesta
    const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    // Sort the data by id_oc_cabecera
    const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
    setValue(sortedData);
  } catch (error) {
    // Manejar el error aquí
    console.error(error);
  }
}

export async function getListConformidadExcel(datalist, setValue, token) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/OC/listSolicitanteConformidadExport`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    // Agregar el campo "id" a los datos de respuesta
    const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    // Sort the data by id_oc_cabecera
    const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
    setValue(sortedData);
  } catch (error) {
    // Manejar el error aquí
    console.error(error);
  }
}

export async function getListAprobadas(datalist, setValue, token) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/OC/listSolicitanteAprobadas`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    // Agregar el campo "id" a los datos de respuesta
    const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    // Sort the data by id_oc_cabecera
    const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
    setValue(sortedData);
  } catch (error) {
    // Manejar el error aquí
    console.error(error);
  }
}

export async function getListAprobadasExcel(datalist, setValue, token) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/OC/listSolicitanteAprobadasExport`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    // Agregar el campo "id" a los datos de respuesta
    const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    // Sort the data by id_oc_cabecera
    const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
    setValue(sortedData);
  } catch (error) {
    // Manejar el error aquí
    console.error(error);
  }
}

export async function getListObservadas(datalist, setValue, token) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/OC/listSolicitanteObservadas`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    // Agregar el campo "id" a los datos de respuesta
    const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    // Sort the data by id_oc_cabecera
    const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
    setValue(sortedData);
  } catch (error) {
    // Manejar el error aquí
    console.error(error);
  }
}

export async function getListObservadasExcel(datalist, setValue, token) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/OC/listSolicitanteObservadasExport`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    // Agregar el campo "id" a los datos de respuesta
    const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    // Sort the data by id_oc_cabecera
    const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
    setValue(sortedData);
  } catch (error) {
    // Manejar el error aquí
    console.error(error);
  }
}



export async function getListRechazadas(datalist, setValue, token) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/OC/listSolicitanteRechazadas`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    // Agregar el campo "id" a los datos de respuesta
    const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    // Sort the data by id_oc_cabecera
    const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
    setValue(sortedData);
  } catch (error) {
    // Manejar el error aquí
    console.error(error);
  }
}

export async function getListRechazadasExcel(datalist, setValue, token) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/OC/listSolicitanteRechazadasExport`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    // Agregar el campo "id" a los datos de respuesta
    const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    // Sort the data by id_oc_cabecera
    const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
    setValue(sortedData);
  } catch (error) {
    // Manejar el error aquí
    console.error(error);
  }
}


export async function crearOC(rowsdata,setSnackbar,setEnviar,setBtn,setLoading, token) {
  var data = JSON.stringify(rowsdata);
  var config = {
    method: 'post',
    url: `${url}api/OC/createOrden`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    console.log(response);
    setSnackbar({ children: response.data==='' ? 'Se registro correctamente' : response.data, severity: response.data==='' ?  'success' : 'error' });
    setEnviar(response.data==='' ? 'Se registro correctamente' : response.data);
    if(response.data===''){
      setBtn(true);
      setLoading(false);
    }else{
      setBtn(false)
      setLoading(false);
    }
  } catch (error) {
    // Manejar el error aquí
    setBtn(false)
    setLoading(false);
    setSnackbar({ children: error.message, severity: 'error' });
    return;
  }
}


export async function getPDFOC(id, token) {
  try {
    var datos = '';
    const config = {
      method: 'get',
      url: `${urlAzure}api/OC/enviarOrdenPdf/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: datos,
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    // Manejar el error aquí
    console.error('Error al generar el pdf de OC:', error);
    return null;
  }
}

export async function getPDFOCPARCIALES(id, token) {
  try {
    var datos = '';
    const config = {
      method: 'get',
      url: `${urlAzure}api/OC/EnviarOrdenPdfParciales/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: datos,
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    // Manejar el error aquí
    console.error('Error al generar el pdf de OC:', error);
    return null;
  }
}


export async function getPDFNE(id, token) {
  try {
    var datos = '';
    const config = {
      method: 'get',
      url: `${urlAzure}api/NE/enviarNotaEntregaPdf/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: datos,
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    // Manejar el error aquí
    console.error('Error al generar el pdf de NE:', error);
    return null;
  }
}
export async function getPDFNEPARCIALES(id, token) {
  try {
    var datos = '';
    const config = {
      method: 'get',
      url: `${urlAzure}api/NE/enviarNotaEntregaPdfParciales/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: datos,
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    // Manejar el error aquí
    console.error('Error al generar el pdf de NE:', error);
    return null;
  }
}

export async function getListCrearNotaEntrega(datalist, setValue, token,setLoading) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/OC/listSolicitantePendienteGenerarNE`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    // Agregar el campo "id" a los datos de respuesta
    const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    // Sort the data by id_oc_cabecera
    const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
    setValue(sortedData);
    setLoading(false);
  } catch (error) {
    // Manejar el error aquí
    console.error(error);
  }
}

export async function getListCrearNotaEntregaExcel(datalist, setValue, token,setBtndisabled) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/OC/listSolicitantePendienteGenerarNEExport`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    // Agregar el campo "id" a los datos de respuesta
    const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    // Sort the data by id_oc_cabecera
    const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
    setValue(sortedData);
    setBtndisabled(false);
  } catch (error) {
    // Manejar el error aquí
    console.error(error);
  }
}


export async function CrearNotaEntrega(rowsdata, setCargando, setIsDisabled, setSnackbar, setEnviar, id, token) {
  try {
    var data = JSON.stringify(rowsdata);
    var config = {
      method: 'post',
      url: `${url}api/NE/create`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };

    const response = await axios(config);

    if (response.data === '') {
      setSnackbar({ children: 'Se registró correctamente', severity: 'success' });
      setEnviar('Se registro correctamente');

      var datae = '';
      var confige = {
        method: 'get',
        url: `${urlAzure}api/OC/list/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: datae
      };

      const response2 = await axios(confige);
      const documentos = response2.data;

      for (const notaEntrega of documentos.notas_entrega) {
        console.log(notaEntrega);
        if(notaEntrega.envio_correo!=="2" || notaEntrega.envio_correo!==2){
          await getPDFNEPARCIALES(notaEntrega.id_ne_cabecera, token);
        }
      }

      setCargando(false);
      setIsDisabled(false);
    } else {
      setSnackbar({ children: response.data, severity: 'error' });
      setEnviar(response.data);
      setCargando(false);
      setIsDisabled(false);
    }
  } catch (error) {
    // Manejar el error aquí
    setCargando(false);
    // setIsDisabled(false);
    return error;
  }
}



export async function getListHistorialNotaEntrega(datalist, setValue, token,setLoading) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/NE/listHistorialSolicitante`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    // Agregar el campo "id" a los datos de respuesta
    const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    // Sort the data by id_oc_cabecera
    const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
    setValue(sortedData);
    setLoading(false);
  } catch (error) {
    // Manejar el error aquí
    console.error(error);
  }
}

export async function getListHistorialNotaEntregaExcel(datalist, setValue, token,setBtndisabled) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/NE/listHistorialSolicitanteExport`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    // Agregar el campo "id" a los datos de respuesta
    const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
    // Sort the data by id_oc_cabecera
    const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
    setValue(sortedData);
    setBtndisabled(false)
  } catch (error) {
    // Manejar el error aquí
    console.error(error);
  }
}



export async function createValidarConformidad(datalist,setSnackbar,setLoading,setBtndisabled, token) {
  var data = JSON.stringify(datalist);
  var config = {
    method: 'post',
    url: `${urlAzure}api/OC/validarConformidad`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios(config);
    setLoading(false);
    setBtndisabled(true);
    setSnackbar({ children: response.data ? 'Se registro correctamente' : 'Error en registrar', severity: response.data ? 'success' : 'error' });

  } catch (error) {
    setLoading(false);
    setBtndisabled(false);
    setSnackbar({ children: error, severity: 'error' });

  }
}


export async function eliminarOrdenCompra(datalist, token) {
  var data = JSON.stringify(datalist);
  try {
    const config = {
      method: 'post', // Usar el método HTTP DELETE
      url: `${url}api/OC/eliminarOrdenCompra`, // URL para eliminar la Orden de Compra
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data
    };

    const response = await axios(config);
    return response.data; // Podrías devolver un mensaje de éxito o información adicional
  } catch (error) {
    // Manejar el error aquí
    console.error('Error al eliminar la Orden de Compra:', error);
    return null;
  }
}


export async function eliminarNotaEntrega(id, token) {
  try {
    const config = {
      method: 'delete', // Usar el método HTTP DELETE
      url: `${url}api/NE/eliminarNotaEntrega/${id}`, // URL para eliminar la nota de entrega
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios(config);
    return response.data; // Podrías devolver un mensaje de éxito o información adicional
  } catch (error) {
    // Manejar el error aquí
    console.error('Error al eliminar la nota de entrega:', error);
    return null;
  }
}





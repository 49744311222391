import axios from "axios";
import {urlAzure} from "../config";

export async function getOrdenesPendienteAprobacion(datalist, setValue, token,setLoading) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listAprobadorGerenciaPendiente`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setLoading(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  export async function getOrdenesPendienteAprobacionExcel(datalist, setValue, token,setBtndisabled) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listAprobadorGerenciaPendienteExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
      setBtndisabled(false);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }


  export async function CrearOCEstado(datos,opcion,setLoadingGrid,token) {
    var data = JSON.stringify(datos);
    try {
      if(opcion==='aprobar'){
      var config = {
        method: 'post',
        url: `${urlAzure}api/OC/aprobarGerencia`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: data
      };
    }else if(opcion==='observar'){
      var config = {
        method: 'post',
        url: `${urlAzure}api/OC/observarGerencia`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: data
      };
    }else if(opcion==='rechazar'){
      var config = {
        method: 'post',
        url: `${urlAzure}api/OC/rechazarGerencia`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: data
      };
    }
      const datos=await axios(config);
      console.log("Se insertó correctamente");
      console.log(datos.data);
    } catch (error) {
      console.error("Ocurrió un error al Aprobar:", error);
      // Aquí puedes manejar el error como desees, por ejemplo, mostrar un mensaje al usuario o realizar alguna otra acción.
    } finally {
      // Aquí puedes realizar alguna acción adicional después de la solicitud, si es necesario.
      setLoadingGrid(false);
    }
  }
  
  export async function getListGerenciaHistorial(datalist, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listAprobadorGerencia`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
  
  export async function getListGerenciaHistorialExcel(datalist, setValue, token) {
    var data = JSON.stringify(datalist);
    var config = {
      method: 'post',
      url: `${urlAzure}api/OC/listAprobadorGerenciaExport`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios(config);
      // Agregar el campo "id" a los datos de respuesta
      const dataWithIds = response.data.map((item, index) => ({ ...item, id: index + 1 }));
      // Sort the data by id_oc_cabecera
      const sortedData = dataWithIds.sort((a, b) => a.id_oc_cabecera - b.id_oc_cabecera);
      setValue(sortedData);
    } catch (error) {
      // Manejar el error aquí
      console.error(error);
    }
  }
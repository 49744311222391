import React from 'react'
import moment from 'moment';


import { Grid ,Typography} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
  } from '@mui/x-data-grid';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as XLSX from 'xlsx';
//Components
import CMLabel from '../../components/atoms/CMLabel';
import CMDatePicker from '../../components/molecules/CMDatePicker';
import CMButton from '../../components/atoms/CMButton';
import CMIconButton from '../../components/atoms/CMIconButton';

//Constants
import {MomentLocaleEs} from '../../constants/MomentLocaleEs';
//Services
import * as FinanzaService from '../../services/FinanzaService';

const AprobacionPdf = () => {
    const [rowsdata, setRowsdata] = useState([]);
    const [rowsexcel, setRowsexcel] = useState([]);
    const [fechaInicio, setFechaInicio] = useState(moment().locale('es', MomentLocaleEs).subtract(45, 'days'));
    const [fechaFin, setFechaFin] = useState(moment().locale('es', MomentLocaleEs));
    const user = JSON.parse(window.localStorage.getItem("user"));
    const history = useHistory();
    useEffect(() => {
        const dataList={
          "from_date": fechaInicio,
          "to_date": fechaFin
        }
        FinanzaService.getListFacturasAprobadas(dataList,setRowsdata,user.token);
        FinanzaService.getListFacturasAprobadasExport(dataList,setRowsexcel,user.token);

    }, []);
    const updateOrdenes= () =>{
        const dataList={
          "from_date": fechaInicio,
          "to_date": fechaFin
        }
        FinanzaService.getListFacturasAprobadas(dataList,setRowsdata,user.token);
        FinanzaService.getListFacturasAprobadasExport(dataList,setRowsexcel,user.token);
    }

    // const formatCurrency = (value, moneda) => {
    //   let currency = 'PEN';
    
    //   if (moneda === 'S/') {
    //       currency = 'PEN';
    //     }else  if (moneda === 'US$') {
    //     currency = 'USD';
    //   }
    
    //   return value.toLocaleString('es-PE', {
    //     style: 'currency',
    //     currency: currency,
    //   });
    // };
   
      const exportToExcel = () => {
        const headers = [
          'ID NE',
          'NE SAP',
          'OC SAP',
          'Guia Remisión',
          'RUC',
          'Nombre Proveedor',
          'Asunto',
          'Comentario',
          'Nombre Contabilidad',
          'Nombre Solicitante',
          'Correo Solicitante',
          'Tipo',
          'Status',
          'Moneda',
          'Sub Total',
          'IGV',
          'Total',
          'Fecha creación'
        ];
    
        const data = [
          headers,
          ...rowsexcel.map(item => [
              item.id_ne_cabecera,
              item.numero_documento,
              item.numero_documento_orden,
              item.tipo === 'Servicio' ? '-' : `${item.tipo_documento} - ${item.serie_documento} - ${item.correlativo_documento}`,
              item.ruc,
              item.razon_social,
              item.asunto,
              item.comentario,
              item.nombresCompletosContabilidad,
              item.nombresCompletosSolicitante,
              item.correo_solicitante,
              item.tipo,
              item.status,
              item.moneda,
              item.total_antes_descuento,
              item.impuesto,
              item.total_pago_vencido,
              formatDate(item.created_at)
          ])
        ];
    
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Documentos Aprobados');
    
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, 'documentos_aprobados.xlsx');
      };
      const formatDate = (dateString, moneda) => {
        const date = new Date(dateString);
      
        let symbol = '';
      
        if (moneda === 'S/') {
          symbol = 'S/';
        } else if (moneda === 'US$') {
          symbol = 'US$';
        }
      
        return symbol + date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
      };
    
      const saveAsExcelFile = (buffer, fileName) => {
        const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(data, fileName);
        } else {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
        }
      };
    const columns = [
    
      { field: 'id_ne_cabecera', headerName: 'ID NE', width: 90 },
      { field: 'numero_documento', headerName: 'NE SAP', width: 140 },
      {
        field: 'concatenatedColumn',
        headerName: 'Guía de Remisión',
        width: 230,
        valueGetter: (params) => {
          if (params.row.tipo === 'Servicio') {
            return '-';
          } else {
            return `${params.row.tipo_documento} - ${params.row.serie_documento} - ${params.row.correlativo_documento}`;
          }
        }
      },
      { field: 'ruc', headerName: 'RUC', width: 160 },
      { field: 'nombre_proveedor', headerName: 'Nombre Proveedor', width: 270 },
      { field: 'asunto', headerName: 'Asunto', width: 490 },
      { field: 'comentario', headerName: 'Comentario', width: 500 },
      { field: 'nombresCompletosContabilidad', headerName: 'Nombre Contabilidad', width: 200 },
      { field: 'nombresCompletosSolicitante', headerName: 'Nombre Solicitante', width: 200 },
      { field: 'correo_solicitante', headerName: 'Correo Solicitante', width: 200 },
      { field: 'tipo', headerName: 'Tipo', width: 140},
      { field: 'status', headerName: 'Status', width: 140},
      { field: 'moneda', headerName: 'Moneda', width: 140},
      { field: 'total_antes_descuento', headerName: 'Sub Total', width: 140},
      { field: 'impuesto', headerName: 'IGV', width: 140},
      { field: 'total_pago_vencido', headerName: 'Total', width: 140},
      { field: 'created_at', headerName: 'Fecha creación', width: 180,valueGetter: (params) => {
        const fecha = params.value;
        return moment(fecha).format('DD-MM-YYYY');},
      }

      ];
 return (
    <Grid width={'100%'} m="auto">
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2} sx={{py:1}}>
        <Grid container item xs={12} sm={12} >
                <Grid item sm={6}>
                    <CMLabel sx={{ margin: 2 }} fontSize='24px' color="black"><b>Historial de Facturas Aprobadas</b></CMLabel>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} justifyContent="center">
                <Grid item sm={12}>
                    <hr></hr> 
                    <Grid container item xs={12} sm={12} alignItems="center">
                        <Grid item xs={12} sm={2}>
                            <CMLabel fontSize='14px' color='black'>Fecha inicio creación: </CMLabel>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <CMDatePicker 
                                label="Desde"
                                value={fechaInicio}
                                onChange={(newDate) => setFechaInicio(newDate)}
                                readOnly={false}
                                fullWidth
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <CMLabel fontSize='14px' color='black'  sx={{marginLeft:'10px'}}>Fecha fin creación: </CMLabel>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <CMDatePicker 
                                label="Hasta"
                                value={fechaFin}
                                onChange={(newDate) => setFechaFin(newDate)}
                                readOnly={false}
                                fullWidth
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <CMButton
                                label="Consultar"
                                variant="contained"
                                sx={{marginLeft:'10px'}}
                                onClick={updateOrdenes}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                        {<CMButton
                                label="Exportar"
                                variant="contained"
                                sx={{marginLeft:'10px'}}
                                onClick={exportToExcel}
                            /> }
                        </Grid>
                     </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
  <Grid style={{ height: 500, width: '100%', overflowX: 'auto' }} sx={{ py: 0 }}>
    <DataGrid
      rows={rowsdata}
      columns={columns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      sx={{
        height: "100%",
        "& .MuiDataGrid-window": {
          overflowX: 'scroll',
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        },
      }}
      pagination
      rowHeight={50}
    />
  </Grid>
</Grid>
    </Grid>
    </Grid>
 )
}

export default AprobacionPdf
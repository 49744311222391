import React from 'react'
import moment from 'moment';


import { Grid ,Typography} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
  } from '@mui/x-data-grid';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as XLSX from 'xlsx';
//Components
import CMLabel from '../../components/atoms/CMLabel';
import CMDatePicker from '../../components/molecules/CMDatePicker';
import CMButton from '../../components/atoms/CMButton';
import CMIconButton from '../../components/atoms/CMIconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


//Constants
import {MomentLocaleEs} from '../../constants/MomentLocaleEs';
//Services
import * as FinanzaService from '../../services/FinanzaService';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CMTextField from '../../components/atoms/CMTextField';

const MySwal = withReactContent(Swal)





const PendienteAprobacionPdf = () => {
    const [rowsdata, setRowsdata] = useState([]);
    const [snackbar, setSnackbar] = useState(null);

 
    // const [dataresponse,setDataresponse] = useState(null);
    // const handleCloseSnackbar = () => setSnackbar(null);
    // const handleProcessRowUpdateError = React.useCallback((error) => {
    //   setSnackbar({ children: error.message, severity: 'error' });
    // }, []);
 
    const [rowsexcel, setRowsexcel] = useState([]);
    const [fechahoy, setFechahoy] = useState(moment().locale('es', MomentLocaleEs));

    const [fechaInicio, setFechaInicio] = useState(moment().locale('es', MomentLocaleEs).subtract(45, 'days'));
    const [fechaFin, setFechaFin] = useState(moment().locale('es', MomentLocaleEs));
    const user = JSON.parse(window.localStorage.getItem("user"));
    
    const history = useHistory();
    useEffect(() => {
        const dataList={
            "from_date": fechaInicio,
            "to_date": fechaFin
        }
        FinanzaService.getListFinanzasLiberarDocumentosNE(dataList,setRowsdata,user.token);
        FinanzaService.getListFinanzasLiberarDocumentosNEExcel(dataList,setRowsexcel,user.token);

    }, []);
    const updateOrdenes= () =>{
        const dataList={
            "from_date": fechaInicio,
            "to_date": fechaFin
        }
        FinanzaService.getListFinanzasLiberarDocumentosNE(dataList,setRowsdata,user.token);
        FinanzaService.getListFinanzasLiberarDocumentosNEExcel(dataList,setRowsexcel,user.token);
    }
    useEffect(() => {
      if(snackbar==='Documentos Aprobados'){
        Swal.fire({
          title: "Se aprobó correctamente",
          text: "",
          icon: "success"
        });
        updateOrdenes();
        setSnackbar('');
      }
    }, [snackbar])

    useEffect(() => {
      if(snackbar==='Se libero documento pendiente de volver adjuntar'){
        Swal.fire({
          title: "Se rechazo documentos",
          text: "",
          icon: "success"
        });
        updateOrdenes();
        setSnackbar('');
      }
    }, [snackbar])
    
    const formatCurrency = (value, moneda) => {
      let currency = 'PEN';
    
      if (moneda === 'S/') {
          currency = 'PEN';
        }else  if (moneda === 'US$') {
        currency = 'USD';
      }
    
      return value.toLocaleString('es-PE', {
        style: 'currency',
        currency: currency,
      });
    };
   
      const exportToExcel = () => {
        const headers = [
          'ID NE',
          'NE SAP',
          'Guia Remisión',
          'Nombre Proveedor',
          'Nombre Solicitante',
          'Tipo',
          'Observaciones',
          'Motivo Solicitud',
          'Moneda',
          'Impuesto',
          'Sub Total',
          'Importe Total',
          'Estado',
          'Nombre Estado',
          'Destino',
          'Condición Pago',
          'Fecha Creación',
          'Fecha Documento',
          'Fecha Contable',
          'Fecha Entrega',
          'Fecha Subida Documentos',
          'NE PDF',
          'Factura PDF',
          'Factura XML',
          'Descargar CDR',
          'Guia / Sustento'
        ];
    
        const data = [
          headers,
          ...rowsexcel.map(item => [
              item.id_ne_cabecera,
              item.numero_documento,
              item.tipo_documento === null ? '' : `${item.tipo_documento} - ${item.serie_documento} - ${item.correlativo_documento}`,
              item.nombre_proveedor,
              item.nombre_solicitante,
              item.tipo,
              item.observaciones,
              item.motivo_solicitud,
              item.moneda,
              item.impuesto,
              item.total_antes_descuento,
              item.total_pago_vencido,
              item.estado,
              item.ultimo_nombre_oc_estado,
              item.destino,
              item.condic_pago,
              formatDate(item.created_at),
              formatDate(item.fecha_documento),
              formatDate(item.fecha_contable),
              formatDate(item.fecha_entrega),
              formatDate(item.fecha_documentos_subidos),
              item.nota_entrega_pdf,
              item.factura_pdf,
              item.factura_xml,
              item.factura_cdr,
              item.facturaGuia
            ])
        ];
    
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Pendientes-Aprobacion');
    
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, 'Pendientes_Aprobacion.xlsx');
      };
      const formatDate = (dateString, moneda) => {
        const date = new Date(dateString);
      
        let symbol = '';
      
        if (moneda === 'S/') {
          symbol = 'S/';
        } else if (moneda === 'US$') {
          symbol = 'US$';
        }
      
        return symbol + date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
      };
    
      const saveAsExcelFile = (buffer, fileName) => {
        const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(data, fileName);
        } else {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
        }
      };


      const handleAprobadosDocumentos = (rowsdata) => {
        const liberar = {
          id_ne_cabecera:rowsdata.id_ne_cabecera,
          numero_documento:rowsdata.numero_documento,
          id_documento:rowsdata.id_documento,
          tipo:rowsdata.tipo,
          status: 'APROBADO',
          asunto: 'Documentos Aprobados',
          comentario: 'Documentos Aprobados',
          id_contabilidad:rowsdata.id_aprobador_finanzas,
          nombre_proveedor:rowsdata.nombre_proveedor,
          correo_proveedor:rowsdata.correo_proveedor,
          correo_solicitante:rowsdata.correo_solicitante,
          correo_contabilidad:rowsdata.correo_finanzas,
          tipo_documento: rowsdata.tipo_documento,
          serie_documento: rowsdata.serie_documento,
          correlativo_documento: rowsdata.correlativo_documento
        }
      
        Swal.fire({
          title: 'Esta seguro de aprobar el documento NE SAP° '+rowsdata.numero_documento,
          text: "Fecha de aprobación "+fechahoy.format('DD/MM/YYYY'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'
          }).then((result) => {
          if (result.isConfirmed) {
              FinanzaService.aprobarPDF(liberar,setSnackbar, user.token);
          }
        })
      };


      const handleDesaprobarDocumentos = (rowsdata) => {
        const liberar = {
          id_ne_cabecera:rowsdata.id_ne_cabecera,
          numero_documento:rowsdata.numero_documento,
          id_documento:rowsdata.id_documento,
          tipo:rowsdata.tipo,
          status: 'RECHAZAR',
          asunto: 'Documentos Rechazados',
          comentario: 'Documentos Rechazados',
          id_contabilidad:rowsdata.id_aprobador_finanzas,
          nombre_proveedor:rowsdata.nombre_proveedor,
          correo_proveedor:rowsdata.correo_proveedor,
          correo_solicitante:rowsdata.correo_solicitante,
          correo_contabilidad:rowsdata.correo_finanzas,
          tipo_documento: rowsdata.tipo_documento,
          serie_documento: rowsdata.serie_documento,
          correlativo_documento: rowsdata.correlativo_documento
        }
      
        Swal.fire({
          title: 'Esta seguro de aprobar el documento NE SAP° '+rowsdata.numero_documento,
          text: "Fecha de rechazo "+fechahoy.format('DD/MM/YYYY'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'
          }).then((result) => {
          if (result.isConfirmed) {
              FinanzaService.aprobarPDF(liberar,setSnackbar, user.token);
          }
        })
      };

      const [open, setOpen] = useState(false);
      const [selectedOption, setSelectedOption] = useState('');
      const [comment, setComment] = useState('');
      const [datos,setDatos] = useState([]);
      const handleOpen = (e,row) => {
        e.preventDefault()
        setOpen(true);
        setDatos(row);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    
      const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
      };
    
      const handleCommentChange = (event) => {
        setComment(event.target.value);
      };
    
      const handleSubmit = () => {
        // Aquí puedes manejar la lógica de envío del formulario
        // Puedes acceder a selectedOption y comment aquí
        const liberar = {
          id_ne_cabecera:datos.id_ne_cabecera,
          numero_documento:datos.numero_documento,
          id_documento:datos.id_documento,
          tipo:datos.tipo,
          status: 'RECHAZAR',
          asunto: selectedOption,
          comentario: comment,
          id_contabilidad:datos.id_aprobador_finanzas,
          nombre_proveedor:datos.nombre_proveedor,
          correo_proveedor:datos.correo_proveedor,
          correo_solicitante:datos.correo_solicitante,
          correo_contabilidad:datos.correo_finanzas,
          tipo_documento: datos.tipo_documento,
          serie_documento: datos.serie_documento,
          correlativo_documento: datos.correlativo_documento
        }
        FinanzaService.deletePDF(liberar,setSnackbar, user.token);

        handleClose();
      };
      
    const columns = [
      // {
      //   field: "ver", headerName: "Ver", width:50, justifyContent: "center",
      //   renderCell: (cellValues) => {
      //     return (
      //       <CMIconButton onClick={() => {history.push(`/pendienteaprobacionpdf/${cellValues.row.id_ne_cabecera}`); history.go(0);}}>
      //         <VisibilityIcon/>
      //       </CMIconButton>
      //     )
      //   }
      // },
      {
        field: "Aprobar", headerName: "Aprobar", width:90, justifyContent: "center",
        renderCell: (cellValues) => {
          return (
            <CMIconButton onClick={() => handleAprobadosDocumentos(cellValues.row)}>
              <CheckCircleIcon  />
            </CMIconButton>
          )
        }
      },
      {
        field: "Rechazar", headerName: "Rechazar", width:90, justifyContent: "center",
        renderCell: (cellValues) => {
          return (
            <CMIconButton onClick={(e)=>handleOpen(e,cellValues.row)}>
              <CancelIcon  />
            
            </CMIconButton>
          )
        }
      },
      { field: 'id_ne_cabecera', headerName: 'ID NE', width: 90 },
      // { field: 'id_oc_cabecera', headerName: 'ID OC', width: 90 },
      { field: 'numero_documento', headerName: 'NE SAP', width: 160 },
      {
        field: 'concatenatedColumn',
        headerName: 'Guía de Remisión',
        width: 300,
        valueGetter: (params) => {
          if (params.row.tipo_documento === null) {
            return '';
          } else {
            return `${params.row.tipo_documento} - ${params.row.serie_documento} - ${params.row.correlativo_documento}`;
          }
        }
      },
        { field: 'nombre_proveedor', headerName: 'Nombre Proveedor', width: 360 },
        { field: 'nombre_solicitante', headerName: 'Nombre Solicitante', type: 'text', width: 150, editable: false },
        { field: 'tipo', headerName: 'Tipo', type: 'text', width: 150 },
        { field: 'observaciones', headerName: 'Observaciones', type: 'text', width: 700, editable: false },
        { field: 'motivo_solicitud', headerName: 'Motivo Solicitud', type: 'text', width: 400, editable: false },
        { field: 'moneda', headerName: 'Moneda', width: 100 },
        { field: 'impuesto', headerName: 'Impuesto', type: 'number', width: 160, editable: false,  valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.impuesto,params.row.moneda) },
        { field: 'total_antes_descuento', headerName: 'Sub Total', type: 'number', width: 160, editable: false,  valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.total_antes_descuento,params.row.moneda)  },
        { field: 'total_pago_vencido', headerName: 'importe total', type: 'number', width: 160, editable: false,  valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.total_pago_vencido,params.row.moneda) },
        { field: 'estado', headerName: 'Estado', type: 'text', width: 120, editable: false },
        { field: 'ultimo_nombre_oc_estado', headerName: 'Nombre Estado', type: 'text', width: 290, editable: false },
        { field: 'destino', headerName: 'Destino', type: 'text', width: 350, editable: false },
        { field: 'condic_pago', headerName: 'Condición Pago', type: 'text', width: 150, editable: false },
        { field: 'fecha_registro', headerName: 'Fecha Creación', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          }, },
        { field: 'fecha_documento', headerName: 'Fecha Documento', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          }, },
        { field: 'fecha_contable', headerName: 'Fecha Contable', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          },},
        { field: 'fecha_entrega', headerName: 'Fecha Entrega', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          },},
          { field: 'fecha_documentos_subidos', headerName: 'Fecha Subida de Documentos', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          },},
        // {
        //     field: 'orden_pdf',
        //     headerName: 'Descargar OC',
        //     width: 150,
        //     renderCell: (params) => {
        //       const pdfUrl = params.value;
        //       return (
        //         <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
        //          <Icon>
        //             <PictureAsPdf />
        //         </Icon>
        //         </a>
        //       );
        //     },
        //   },
        {
          field: 'nota_entrega_pdf',
          headerName: 'NE PDF',
          width: 150,
          renderCell: (params) => {
            const pdfUrl = params.value;
            return (
              <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
               <Icon>
                  <PictureAsPdf />
              </Icon>
              </a>
            );
          },
        },
          {
            field: 'factura_pdf',
            headerName: 'Descargar PDF',
            width: 150,
            renderCell: (params) => {
              const pdfUrl = params.value;
              return (
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                 <Icon>
                    <PictureAsPdf />
                </Icon>
                </a>
              );
            },
          },
          {
            field: 'factura_xml',
            headerName: 'Descargar XML',
            width: 150,
            renderCell: (params) => {
              const pdfUrl = params.value;
              return (
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                 <Icon>
                    <PictureAsPdf />
                </Icon>
                </a>
              );
            },
          },
          {
            field: 'factura_cdr',
            headerName: 'Descargar CDR',
            width: 150,
            renderCell: (params) => {
              const pdfUrl = params.value;
              return (
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                 <Icon>
                    <PictureAsPdf />
                </Icon>
                </a>
              );
            },
          },
            {
           field: 'facturaGuia',
             headerName: 'Guia / Sustento',
            width: 150,
             renderCell: (params) => {
               const pdfUrl = params.value;
               return (
                 <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                  <Icon>
                     <PictureAsPdf />
                 </Icon>
                 </a>
               );
             },
           },
           {
            field: 'credito_pdf',
              headerName: 'N Crédito PDF',
             width: 150,
              renderCell: (params) => {
                const pdfUrl = params.value;
                return (
                  <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                   <Icon>
                      <PictureAsPdf />
                  </Icon>
                  </a>
                );
              },
            },
            {
              field: 'credito_xml',
                headerName: 'N Crédito XML',
               width: 150,
                renderCell: (params) => {
                  const pdfUrl = params.value;
                  return (
                    <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                     <Icon>
                        <PictureAsPdf />
                    </Icon>
                    </a>
                  );
                },
              },
              {
                field: 'debito_pdf',
                  headerName: 'N Débito PDF',
                 width: 150,
                  renderCell: (params) => {
                    const pdfUrl = params.value;
                    return (
                      <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                       <Icon>
                          <PictureAsPdf />
                      </Icon>
                      </a>
                    );
                  },
                },
                {
                  field: 'debito_xml',
                    headerName: 'N Débito XML',
                   width: 150,
                    renderCell: (params) => {
                      const pdfUrl = params.value;
                      return (
                        <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                         <Icon>
                            <PictureAsPdf />
                        </Icon>
                        </a>
                      );
                    },
                  },
      ];
 return (
    <Grid width={'100%'} m="auto">
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2} sx={{py:1}}>
        <Grid container item xs={12} sm={12} >
                <Grid item sm={6}>
                    <CMLabel sx={{ margin: 2 }} fontSize='24px' color="black"><b>Pendiente de Aprobación Facturas</b></CMLabel>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} justifyContent="center">
                <Grid item sm={12}>
                    <hr></hr> 
                    <Grid container item xs={12} sm={12} alignItems="center">
                        <Grid item xs={12} sm={2}>
                            <CMLabel fontSize='14px' color='black'>Fecha inicio creación: </CMLabel>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <CMDatePicker 
                                label="Desde"
                                value={fechaInicio}
                                onChange={(newDate) => setFechaInicio(newDate)}
                                readOnly={false}
                                fullWidth
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <CMLabel fontSize='14px' color='black'  sx={{marginLeft:'10px'}}>Fecha fin creación: </CMLabel>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <CMDatePicker 
                                label="Hasta"
                                value={fechaFin}
                                onChange={(newDate) => setFechaFin(newDate)}
                                readOnly={false}
                                fullWidth
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <CMButton
                                label="Consultar"
                                variant="contained"
                                sx={{marginLeft:'10px'}}
                                onClick={updateOrdenes}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                        <CMButton
                                label="Exportar"
                                variant="contained"
                                sx={{marginLeft:'10px'}}
                                onClick={exportToExcel}
                            />
                        </Grid>
                     </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
  <Grid style={{ height: 500, width: '100%', overflowX: 'auto' }} sx={{ py: 0 }}>
    <DataGrid
      rows={rowsdata}
      columns={columns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      sx={{
        height: "100%",
        "& .MuiDataGrid-window": {
          overflowX: 'scroll',
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        },
      }}
      pagination
      rowHeight={50}
    />

<Dialog open={open} onClose={handleClose}>
        <DialogTitle>Rechazar</DialogTitle>
        <DialogContent>
          <Select
            label="Seleccionar Razón"
            value={selectedOption}
            onChange={handleSelectChange}
            fullWidth
          >
                                           <MenuItem value="Diferencia de precio en Factura y OC/OS">Diferencia de precio en Factura y OC/OS</MenuItem>
                                <MenuItem value="Diferencia de cantidad en Factura y NE">Diferencia de cantidad en Factura y NE</MenuItem>
                                <MenuItem value="Diferencia de precio y cantidad en OC/OS y NE">Diferencia de precio y cantidad en OC/OS y NE</MenuItem>
                                <MenuItem value="Documentos incompletos">Documentos incompletos</MenuItem>                                
                                <MenuItem value="NE y Factura con OC/OS distinta">NE y Factura con OC/OS distinta</MenuItem>
                                <MenuItem value="Factura duplicada">Factura duplicada</MenuItem>
                                <MenuItem value="Factura anulada">Factura anulada</MenuItem>
                                <MenuItem value="Factura con datos errados">Factura con datos errados </MenuItem>
                                <MenuItem value="Documentos no asociados a Factura">Documentos no asociados a Factura </MenuItem>
          </Select>

          <CMTextField
            label="Comentario"
            multiline
            rows={4}
            value={comment}
            onChange={handleCommentChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
  </Grid>
</Grid>
    </Grid>
    </Grid>
 )
}

export default PendienteAprobacionPdf